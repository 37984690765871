import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import courseReducer from "./reducers/courseReducer";
import tutorReducer from "./reducers/tutorReducer";
import categoyReducer from "./reducers/categoyReducer";
import questionReducer from "./reducers/questionReducer";
import transactionReducer from "./reducers/transactionReducer";
import ratingReducer from "./reducers/ratingReducer";
import discountReducer from "./reducers/discountReducer";
import announcementReducer from "./reducers/announcementReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    rating: ratingReducer,
    discount: discountReducer,
    course: courseReducer,
    category: categoyReducer,
    instructor: tutorReducer,
    question: questionReducer,
    transaction: transactionReducer,
    announcement: announcementReducer,
  },
});
