import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { allQuestion } from "../../helpers/question/question";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import logo from "../../assets/images/logo/02.png";
import { format } from "timeago.js";
import Loader from "../Loader/BtnLoader";

const AllQuestions = ({ course }) => {
  const dispatch = useDispatch();
  const { userId } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const questionsState = useSelector((state) => state.question.questions);
  const user = useSelector((state) => state.auth.userDetails);

  const getQuestions = async () => {
    if (course && userId) {
      const data = {
        userId,
        courseId: course._id,
      };
      await allQuestion(data, dispatch, setLoading);
    }
  };

  useEffect(() => {
    if (userId !== "") {
      getQuestions();
    }
  }, [userId, course]);

  useEffect(() => {
    if (questionsState !== null) {
      setQuestions(questionsState);
    }
  }, [questionsState]);

  return (
    <>
      {loading ? (
        <Loader size={40} color1={"black"} color2={"black"} />
      ) : questions && questions.length > 0 ? (
        questions.map((item, index) => (
          <li key={index}>
            <div className="student-review d-flex flex-wrap">
              <div className="student-thumb">
                <img
                  src={
                    item?.users[0] === userId ? user && user?.avatar?.doc : logo
                  }
                  alt="annaunce"
                  width={50}
                  className="rounded-circle"
                />
              </div>
              <div className="text-dark mx-2">
                <h6 className="text-dark">
                  {user && user?.username}{" "}
                  <span>{format(item?.createdAt)}</span>
                </h6>
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: draftToHtml(JSON.parse(item?.message)),
                    }}
                  />
                </div>
              </div>
            </div>
          </li>
        ))
      ) : (
        <div>
          <p className="text-secondary text-center ">No Questions Found</p>
        </div>
      )}
    </>
  );
};

export default AllQuestions;
