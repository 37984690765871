/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getBlogPosts } from "../../helpers/medium/medium";

const desc =
  "We help you get the best out of the IT career path by providing a crative space for skill acquisition.";
const courseTitle = "Courses";
const quickTitle = "Quick Links";
const tweetTitle = "Recent Posts";

const addressList = [
  {
    iconName: "icofont-envelope",
    text: "deonicode@gmail.com",
  },
];

const socialList = [
  {
    iconName: "icofont-facebook",
    siteLink: "https://www.facebook.com/deonicode",
    className: "facebook",
  },
  {
    iconName: "icofont-linkedin",
    siteLink: "https://www.linkedin.com/company/deonicode",
    className: "linkedin",
  },
  {
    iconName: "icofont-instagram",
    siteLink: "https://www.instagram.com/deonicode",
    className: "instagram",
  },
];

const courseList = [
  {
    text: "All Courses",
    link: "#",
  },
  {
    text: "Mobile App Development",
    link: "Mobile Development",
  },
  {
    text: "Front-End Development",
    link: "Frontend Development",
  },
  {
    text: "Backend Development",
    link: "Backend Development",
  },
  {
    text: "Blockchain Development",
    link: "Blockchain Development",
  },
  {
    text: "Machine Learning",
    link: "machine-learning",
  },
  {
    text: "Data Analysis",
    link: "data-analysis",
  },
  {
    text: "DevOps Engineering",
    link: "DevOps",
  },
];

const quickList = [
  {
    text: "About Us",
    link: "/about",
  },
  {
    text: "Contact Us",
    link: "/contact",
  },
  {
    text: "Instructor Terms",
    link: "/instructor-terms",
  },
  {
    text: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    text: "Terms of Use",
    link: "/terms-of-use",
  },
  {
    text: "Blog",
    link: "/blog",
  },
];

const tweetList = [
  {
    desc: (
      <p>
        Aminur islam <a href="#">@CodexCoder Edukon #HTML_Template</a> Grab your
        item, 50% Big Sale Offer !!
      </p>
    ),
  },
  {
    desc: (
      <p>
        Somrat islam <a href="#">@CodexCoder Edukon #HTML_Template</a> Grab your
        item, 50% Big Sale Offer !!
      </p>
    ),
  },
];

const FooterTwo = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllBlogPosts = async () => {
    getBlogPosts(setLoading, setPosts);
  };

  useEffect(() => {
    getAllBlogPosts();
  }, []);

  return (
    <footer className="style-2">
      <div className="footer-top padding-tb">
        <div className="container">
          <div className="row g-4 row-cols-xl-4 row-cols-sm-2 row-cols-1 justify-content-center">
            <div className="col">
              <div className="footer-item our-address">
                <div className="footer-inner">
                  <div className="footer-content">
                    <div className="title">
                      <img
                        src="/assets/images/logo/01.png"
                        alt="education"
                        width={200}
                      />
                    </div>
                    <div className="content">
                      <p>{desc}</p>
                      <ul className="lab-ul office-address">
                        {addressList.map((val, i) => (
                          <li key={i}>
                            <i className={val.iconName}></i>
                            {val.text}
                          </li>
                        ))}
                      </ul>
                      <ul className="lab-ul social-icons">
                        {socialList.map((val, i) => (
                          <li key={i}>
                            <a href={val.siteLink} className={val.className}>
                              <i className={val.iconName}></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="footer-item">
                <div className="footer-inner">
                  <div className="footer-content">
                    <div className="title">
                      <h4>{courseTitle}</h4>
                    </div>
                    <div className="content">
                      <ul className="lab-ul">
                        {courseList.map((val, i) => (
                          <li key={i}>
                            <a href={`course/${val.text}`}>{val.text}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="footer-item">
                <div className="footer-inner">
                  <div className="footer-content">
                    <div className="title">
                      <h4>{quickTitle}</h4>
                    </div>
                    <div className="content">
                      <ul className="lab-ul">
                        {quickList.map((val, i) => (
                          <li key={i}>
                            <a href={val.link}>{val.text}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="footer-item twitter-post">
                <div className="footer-inner">
                  <div className="footer-content">
                    <div className="title">
                      <h4>{tweetTitle}</h4>
                    </div>
                    <div className="content">
                      <ul className="lab-ul">
                        {posts.length > 0
                          ? posts.slice(0, 2).map((item, i) => (
                              <span key={i}>
                                {item.map((val, j) => (
                                  <div className="d-flex m-1" key={j}>
                                    {/* <img
                                      src={val.thumbnail}
                                      alt="Thumbnail"
                                      style={{ width: "80px" }}
                                    /> */}
                                    <li className="mx-0">
                                      <a href={`${val.link} `} target="_blank">
                                        {val.title}
                                      </a>
                                    </li>
                                  </div>
                                ))}
                              </span>
                            ))
                          : "No Post Found"}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="section-wrapper">
            <p>
              &copy; {new Date().getFullYear()} <Link to="/">Deonicode</Link>{" "}
              All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTwo;
