


const title = <h2><span>About</span> Deon Achuo</h2>;
const desc = "Deon Achuo is a Fullstack software engineer, DevOps Engineer and Data analyst with over 3+ experience in the IT space.";

const detailsList = [
    {
        leftText: 'Role',
        clone: ':',
        rightText: 'Senior Full-stack Engineer',
    },
    {
        leftText: 'Experience',
        clone: ':',
        rightText: '5+ Years',
    },
    {
        leftText: 'Specialist in',
        clone: ':',
        rightText: 'Software Development',
    },
    {
        leftText: 'Phone',
        clone: ':',
        rightText: '+237 650 647 638',
    },
    {
        leftText: 'Mail',
        clone: ':',
        rightText: 'deonachuo@gmail.com',
    }
]

const countList = [
    {
        iconName: 'icofont-users-alt-4',
        count: '12600',
        desc: 'Students Enrolled',
    },
    {
        iconName: 'icofont-graduate-alt',
        count: '5',
        desc: 'Certified Engineer',
    },
    {
        iconName: 'icofont-notification',
        count: '350',
        desc: 'Professional Courses',
    },
]


const AboutThree = () => {
    return (
        <section className="about-section style-4 padding-tb">
            <div className="container">
                <div className="about-items">
                    <div className="about-item">
                        <div className="image">
                            <img src="assets/images/about/deon.jpeg" alt="about image" className="img-responsive" />
                        </div>
                    </div>
                    <div className="about-item">
                        <div className="about-content">
                            {title}
                            <p>{desc}</p>
                            <ul className="details lab-ul">
                                {detailsList.map((val, i) => (
                                    <li key={i}><p>{val.leftText} <span>{val.clone}</span></p>{val.rightText}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="about-item">
                        <div className="counter-part">
                            {countList.map((val, i) => (
                                <div className="count-item" key={i}>
                                    <div className="count-inner">
                                        <div className="count-icon">
                                            <i className={val.iconName}></i>
                                        </div>
                                        <div className="count-content">
                                            <h2><span className="count">{val.count}</span><span>+</span></h2>
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default AboutThree;