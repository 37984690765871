import { sendMails, subscription } from "../../redux/reducers/mails";

export const contactFormMail = (data, dispatch, setLoading) => {
  const response = dispatch(sendMails(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error posting question", err);
    });
  return response;
};

export const subscriptionMail = (data, dispatch, setLoading) => {
  const response = dispatch(subscription(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error posting question", err);
    });
  return response;
};
