import axios from "axios";

const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/instructor`;
const userToken = localStorage.getItem("deonicode-user");

const register = async (data) => {
  const response = await axios.post(`${API_URL}/register`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

const tutors = async () => {
  const response = await axios.get(`${API_URL}/instructors`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return response.data;
};

const tutor = async (data) => {
  const response = await axios.get(`${API_URL}/instructor/${data.id}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return response.data;
};

const authServices = {
  register,
  tutors,
  tutor,
};

export default authServices;
