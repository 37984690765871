import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import announcementService from "../services/announcementServices";

const initialState = {
  announcements: [],
};

export const announcements = createAsyncThunk(
  "announcement/announcement",
  async (data, thunkAPI) => {
    try {
      return await announcementService.announcements(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(announcements.fulfilled, (state, action) => {
      state.announcements = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = announcementSlice.actions;

export default announcementSlice.reducer;
