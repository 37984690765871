import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryServices from "../services/categoryServices";

const initialState = {
  categories: [],
};

export const categories = createAsyncThunk(
  "category/categories",
  async (thunkAPI) => {
    try {
      return await categoryServices.categories();
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(categories.fulfilled, (state, action) => {
      state.categories = action.payload.categories;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = categorySlice.actions;

export default categorySlice.reducer;
