import { categories } from "../../redux/reducers/categoyReducer";

export const allCategories = (dispatch, setLoading) => {
  const response = dispatch(categories(), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload.message,
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error getting categories", err);
    });
  return response;
};
