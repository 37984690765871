import { useState } from "react";
import { toast } from "react-toastify";
import { subscriptionMail } from "../../helpers/mails/mails";
import { useDispatch } from "react-redux";
import Loader from "../Loader/BtnLoader";

const title = "Subscribe to Newsletter";
const desc =
  "Subscribe free and get the notification and latest offers of our courses.";
const subTitle = "Enter Your Name & Email Press to Submit Button";
const btnText = "Get Started Now";

const NewsLetter = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        username,
        email,
      };
      const response = await subscriptionMail(data, dispatch, setLoading);
      if (response.status === "success") {
        toast.success(response.message);
        setUsername("");
        setEmail("");
        return;
      } else {
        return toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="newsletters-section padding-tb">
      <div className="container">
        <div className="newsletter-area">
          <div className="news-mass">
            <i className="icofont-email"></i>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-12">
              <div className="section-header">
                <h2 className="title">{title}</h2>
                <p>{desc}</p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="section-wrapper">
                <h5>{subTitle}</h5>
                <form className="newsletter-form" onSubmit={handleFormSubmit}>
                  <input
                    type="text"
                    name="name"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter your name"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email address"
                    required
                  />

                  {loading ? (
                    <button className="lab-btn">
                      <Loader size={30} color1={"white"} color2={"white"} />
                    </button>
                  ) : (
                    <button type="submit" className="lab-btn">
                      <span>{btnText}</span>
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
