const PriceSelect = ({ select, setEndPrice }) => {
  return (
    <select defaultValue={select} onChange={(e) => setEndPrice(e.target.value)}>
      <option value="">Max Price</option>
      <option value="50">$50</option>
      <option value="100">$100</option>
      <option value="150">$150</option>
      <option value="200">$200</option>
      <option value="250">$250</option>
      <option value="300">$300</option>
      <option value="350">$350</option>
      <option value="400">$400</option>
      <option value="500">$500</option>
      <option value="600">$600</option>
      <option value="700">$700</option>
      <option value="800">$800</option>
      <option value="900">$900</option>
    </select>
  );
};

export default PriceSelect;
