import { Link } from "react-router-dom";

const subTitle = "Popular Category";
const title = "Learn In Demand Skills";

const categoryList = [
  {
    imgUrl: "assets/images/category/icon/07.jpg",
    imgAlt: "deonicode",
    title: "Frontend Development",
    desc: "0 Course(s)",
    link: "frontend-development",
  },
  {
    imgUrl: "assets/images/category/icon/08.jpg",
    imgAlt: "deonicode",
    title: "Backend Development",
    desc: "0 Course(s)",
    link: "backend-development",
  },
  {
    imgUrl: "assets/images/category/icon/09.jpg",
    imgAlt: "deonicode",
    title: "Mobile App Development",
    desc: "0 Course(s)",
    link: "mobile-app-development",
  },
  {
    imgUrl: "assets/images/category/icon/10.jpg",
    imgAlt: "deonicode",
    title: "Blockchain Development",
    desc: "0 Course(s)",
    link: "blockchain-development",
  },
  {
    imgUrl: "assets/images/category/icon/11.jpg",
    imgAlt: "deonicode",
    title: "Machine learning",
    desc: "0 Course(s)",
    link: "machine-learning",
  },
  {
    imgUrl: "assets/images/category/icon/12.jpg",
    imgAlt: "deonicode",
    title: "DevOps Engineering",
    desc: "0 Course(s)",
    link: "devops-engineering",
  },
  {
    imgUrl: "assets/images/category/icon/13.jpg",
    imgAlt: "deonicode",
    title: "Data Analysis",
    desc: "0 Course(s)",
    link: "data-analysis",
  },
  {
    imgUrl: "assets/images/category/icon/14.jpg",
    imgAlt: "deonicode",
    title: "Coding Interview Prep",
    desc: "58 Course",
    link: "coding-interview-prep",
  },
];

const CategoryTwo = () => {
  return (
    <div className="category-section padding-tb section-bg style-2">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
            {categoryList.map((val, i) => (
              <div className="col" key={i}>
                <div className="category-item text-center">
                  <div className="category-inner">
                    <div className="category-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <div className="category-content">
                      <Link to={`/course/${val.link}`}>
                        <h6>{val.title}</h6>
                      </Link>
                      {/* <span>{val.desc}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryTwo;
