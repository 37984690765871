import React from "react";

import HydrogenLoader from "react-loaders-kit/lib/hydrogen/HydrogenLoader"; // Recommended to reduce bundle size

const Loader = ({ size, color1, color2 }) => {
  const loaderProps = {
    loading: true,
    size: size,
    duration: 0.5,
    colors: [color1, color2],
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HydrogenLoader {...loaderProps} />
    </div>
  );
};

export default Loader;
