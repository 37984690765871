import { useEffect, useState } from "react";
import Rating from "./rating";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { allRatings } from "../../helpers/ratings/rating";
import { allUsers } from "../../helpers/authentication/auth";
import moment from "moment";
import Loader from "../Loader/BtnLoader";

const Comment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const ratings = useSelector((state) => state.rating.ratings);
  const users = useSelector((state) => state.auth.users);

  const getAllRatings = async () => {
    const data = {
      id,
    };
    await allRatings(data, dispatch, setLoading);
  };

  const getAllUsers = async () => {
    await allUsers(dispatch, setLoading);
  };

  useEffect(() => {
    getAllUsers();
    getAllRatings();
  }, [id]);
  return (
    <div className="comments">
      <h4 className="title-border">
        {ratings ? ratings?.length + ` Comment` : 0 + " Comment"}
      </h4>
      <ul className="comment-list">
        {loading ? (
          <Loader size={40} color1={"black"} color2={"black"} />
        ) : ratings && ratings.length > 0 ? (
          ratings?.map((val, i) => {
            const user = users.filter((item) => item._id === val.userId)[0];
            return (
              <li className="comment" key={i}>
                <div className="com-thumb">
                  <img src={`${user?.avatar?.doc}`} alt={`${val.imgAlt}`} />
                </div>
                <div className="com-content">
                  <div className="com-title">
                    <div className="com-title-meta">
                      <h6>{user?.username}</h6>
                      <span>
                        {moment(val.createdAt).format("DD-MM-YYYY HH:mm")}{" "}
                      </span>
                    </div>
                    <Rating value={val.rating} />
                  </div>
                  <p>{val.comment}</p>
                </div>
              </li>
            );
          })
        ) : (
          "No Comments Made"
        )}
      </ul>
    </div>
  );
};

export default Comment;
