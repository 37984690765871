import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Dropdown from "react-bootstrap/Dropdown";
import Rating from "../component/sidebar/rating";
import "../styles/MyAccount.css";
import FooterTwo from "../component/layout/footer-2";
import placeholderImage from "../assets/images/placeholderImage.png";

import { AuthContext } from "../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../helpers/authentication/auth";
import { getTutors } from "../helpers/instructor/instructor";
import { allPurchasedCourses } from "../helpers/courses/courses";
import { toast } from "react-toastify";
import Loader from "../component/Loader/BtnLoader";
import Dropdown from "react-bootstrap/Dropdown";

function MyAccount() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // search query state
  // const [categoryQuery, setCategoryQuery] = useState(""); // search query state
  const { userId } = useContext(AuthContext);
  const courses = useSelector((state) => state.course.purchasedCourses);
  const instructors = useSelector((state) => state.instructor.instructors);
  const categories = useSelector((state) => state.category.categories);

  const getPurchasedCourses = async () => {
    const data = {
      id: userId,
    };
    await allPurchasedCourses(data, dispatch, setLoading);
  };

  const getAllTutors = async () => {
    await getTutors(dispatch, setLoading);
  };

  useEffect(() => {
    if (userId !== "") {
      getPurchasedCourses();
    }
  }, [userId]);

  useEffect(() => {
    getAllTutors();
  }, []);

  useEffect(() => {
    setItems(courses);
  }, [courses]);

  // course name filter
  const filterItem = (e) => {
    e.preventDefault();
    const updateItems = courses.filter((curElem) => {
      return curElem.title === searchQuery;
    });
    setItems(updateItems);
  };

  // course category filter
  const filterItemByCategory = (category) => {
    const updateItems = courses.filter((curElem) => {
      return curElem.category[0] === category;
    });
    setItems(updateItems);
  };

  document.title = "Deonicode | Account";
  return (
    <div>
      <Header />
      <div className="filter container">
        <div>
          {/* <Dropdown onChange={(e) => filterItemByCategory(e.target.value)}>
            <Dropdown.Toggle variant="blue" id="dropdown-basic">
              Categories
            </Dropdown.Toggle>

            <Dropdown.Menu
              onChange={(e) => filterItemByCategory(e.target.value)}
            >
              <Dropdown.Item href="#/action-1">All</Dropdown.Item>
              {categories && categories.length > 0 ? (
                categories.map((item, i) => {
                  return (
                    <Dropdown.Item
                      href="#"
                      key={i}
                      value={item.title}
                      onClick={() => filterItemByCategory(item.title)}
                    >
                      {item.title}
                    </Dropdown.Item>
                  );
                })
              ) : (
                <Dropdown.Item href="#">Loading Categories...</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown> */}

          <div className="form-group">
            <select
              name="category"
              id="catgory"
              className="form-control"
              onChange={(e) => filterItemByCategory(e.target.value)}
            >
              <option>Select Category</option>
              {categories && categories.length > 0 ? (
                categories.map((item) => (
                  <option value={item.title} key={item._id}>
                    {item.title}
                  </option>
                ))
              ) : (
                <option>Loading categories...</option>
              )}
            </select>
          </div>
        </div>
        <div>
          <form onSubmit={filterItem}>
            <input
              placeholder="Search course..."
              className="form-control"
              type="text"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </form>
        </div>
      </div>

      <div
        className="section-wrapper container"
        style={{
          marginTop: 50,
        }}
      >
        <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 course-filter">
          {loading ? (
            <div>
              <Loader size={40} color1={"black"} color2={"black"} />
            </div>
          ) : items && items.length > 0 ? (
            items.map((elem) => {
              const instructor =
                instructors &&
                instructors.filter((curElem) => {
                  return curElem._id === elem.instructorId;
                })[0];
              return (
                <div className="col" key={elem._id}>
                  <div className="course-item style-4">
                    <div className="course-inner">
                      <div className="course-thumb">
                        <img
                          src={elem.coverImage?.doc}
                          alt={elem.coverImage?.doc}
                        />
                        <div className="course-category">
                          <div className="course-cate">
                            <a href="#">{elem.category}</a>
                          </div>
                          <div className="course-reiew">
                            <Rating value={elem.rating} color={"black"} />
                          </div>
                        </div>
                      </div>
                      <div className="course-content">
                        <Link to={`/course-view/${elem._id}`}>
                          <h5>{elem.title}</h5>
                        </Link>
                        <div className="course-footer">
                          <div className="course-author">
                            <img
                              src={instructor?.avatar?.doc || placeholderImage}
                              alt={instructor?.username}
                              width={30}
                              className="rounded-circle"
                            />
                            <span
                              to={`/team-single/${instructor?._id}`}
                              className="ca-name"
                            >
                              {instructor?.username ? instructor?.username  : "Deon Achuo"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <p>You have no course yet in your account</p>
            </div>
          )}
        </div>
      </div>
      <FooterTwo />
    </div>
  );
}

const Header = () => {
  const [loading, setLoading] = useState(false);
  const { setAuthenticated } = useContext(AuthContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await logoutUser(dispatch, setLoading);
    if (response.status === "success") {
      setAuthenticated(false);
      return navigate("/");
    } else {
      return toast.error("error loging out");
    }
  };

  return (
    <div className="header">
      <div className="container">
        <div className="header-content p-0">
          <div className="logo">
            <Link to="/">
              <img
                src="/assets/images/logo/01.png"
                alt="logo"
                style={{ padding: 0, margin: 0 }}
              />
            </Link>
          </div>
          <div className="menues">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="btn fw-bold"
                style={{
                  backgroundColor: "#E97F21",
                  color: "#fff",
                  border: "none",
                  borderRadius: 0,
                }}
              >
                <i className="icofont-justify-all"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item className="p-0">
                  <Link to="/dashboard" className="w-100 px-2 py-1">
                    Dashboard
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="p-0">
                  <Link to="/profile" className="w-100 px-2 py-1">
                    Profile
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="p-0" onClick={handleLogout}>
                  <Link to="#" className="w-100 px-2 py-1 text-danger">
                    Log out
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
