import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAnnouncements } from "../../helpers/announcements/announcements";
import { getCourseAuthor } from "../../helpers/courses/courses";
import { toast } from "react-toastify";
import placeholderImage from "../../assets/images/placeholderImage.png";
import { format } from "timeago.js";
import draftToHtml from "draftjs-to-html";
import Loader from "../Loader/BtnLoader";

const Announcements = ({ course }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [author, setAuthor] = useState(null);
  const announcements = useSelector(
    (state) => state.announcement.announcements
  );

  const getAllAnnouncements = async () => {
    const data = {
      id,
    };
    const response = await getAnnouncements(data, dispatch, setLoading);
  };

  useEffect(() => {
    if (id !== "") {
      getAllAnnouncements();
    }
  }, [id]);

  const getAuthor = async () => {
    if (course) {
      const data = {
        id: course.instructorId,
      };
      const response = await getCourseAuthor(data, dispatch, setLoading);
      if (response.status === "error") {
        toast.error(response.message);
        return;
      } else {
        setAuthor(response.instructor);
        return;
      }
    }
  };

  useEffect(() => {
    getAuthor();
  }, [course]);
  return (
    <>
      {loading ? (
        <Loader size={40} color1={"black"} color2={"black"} />
      ) : announcements && announcements.length > 0 ? (
        announcements.map((item, index) => (
          <div className="administer-post d-flex flex-wrap" key={index}>
            <div className="ap-top mb-2">
              <div className="ap-items d-flex flex-wrap">
                <div className="ap-thumb">
                  <img
                    src={author?.avatar?.doc || placeholderImage}
                    alt={`${author?.username}`}
                    style={{ maxHeight: 160 }}
                  />
                </div>
                <div className="ap-content">
                  <h5 className="text-dark">{author?.username}</h5>
                  <p className="text-dark">
                    Posted an Announcement{" "}
                    <span>{format(item?.createdAt)}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="text-dark">
              <div
                className="course-content text-dark"
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(JSON.parse(item?.content)),
                }}
              />
            </div>
          </div>
        ))
      ) : (
        "No Announcement Found"
      )}
    </>
  );
};

export default Announcements;
