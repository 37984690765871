import axios from "axios";

const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/mails`;

const contactForm = async (data) => {
  const response = await axios.post(`${API_URL}/contact-form`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const subscribe = async (data) => {
  const response = await axios.post(`${API_URL}/subscribe`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const mailServices = { contactForm, subscribe };

export default mailServices;
