import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ratingServices from "../services/ratingServices";

const initialState = {
  ratings: [],
};

export const ratings = createAsyncThunk(
  "rating/ratings",
  async (data, thunkAPI) => {
    try {
      return await ratingServices.ratings(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createRating = createAsyncThunk(
  "rating/ratings",
  async (data, thunkAPI) => {
    try {
      return await ratingServices.newRating(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ratingSlice = createSlice({
  name: "ratings",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(ratings.fulfilled, (state, action) => {
      state.ratings = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = ratingSlice.actions;

export default ratingSlice.reducer;
