const subTitle = "Our sweet Offers";
const title = "What you will get";

const featureList = [
  {
    iconName: "icofont-credit-card",
    title: "Affordable",
    desc: "Our courses are very valuable at a give away cost. Invest little now and earn morein future.",
  },
  {
    iconName: "icofont-light-bulb",
    title: "Improve Skill",
    desc: "With Our project based approach, your skills set will 10x and you will also have quality projects that will put you on a sweet spot for jobs.",
  },
  {
    iconName: "icofont-graduate",
    title: "Self Paste",
    desc: "Learn at your convenience, anytime anywhere.",
  },
  {
    iconName: "icofont-paper-plane",
    title: "Mentorship",
    desc: "We will mentor you through your career path, so that you get the best out of it.",
  },
  {
    iconName: "icofont-site-map",
    title: "Coding Interview Prep",
    desc: "We will provide with interview prep tips and materials so that you can ace your coding interviews.",
  },
  {
    iconName: "icofont-users-alt-3",
    title: "Bootcamps",
    desc: "We will have seasonal bootcamps, to give an edge in the IT industry.",
  },
];

const FeatureTwo = () => {
  return (
    <section className="feature-section style-2 padding-tb">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle yellow-color">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 justify-content-center">
            {featureList.map((val, i) => (
              <div className="col" key={i}>
                <div className="feature-item">
                  <div className="feature-inner">
                    <div className="feature-icon">
                      <i className={val.iconName}></i>
                    </div>
                    <div className="feature-content">
                      <h5>{val.title}</h5>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureTwo;
