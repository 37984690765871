import { Fragment } from "react";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";
import FooterTwo from "../component/layout/footer-2";

const InstructorTermsOfUse = () => {
  return (
    <Fragment>
      <HeaderFour />
      <PageHeader title={"Instructor Terms"} curPage={"Instructor Terms"} />
      <div className="container padding-tb text-start px-2">
        <p>
          <i>These Instructor Terms were last updated September 6, 2023.</i>
        </p>
        <p>
          These Instructor Terms (the "Terms") are a condition of your
          application to teach on the Deonicode platform. These Terms, which
          describe the features of the Deonicode platform that are pertinent to
          teachers, are included into our Terms of Use, the general rules that
          apply to your use of our Services, by reference. The definitions of
          capitalized terms that aren't included in these Terms can be found in
          the Terms of Use.
        </p>
        <p>
          You are entering into a direct contract with Deonicode, Inc. as an
          educator.
        </p>
        <h5>1. Instructor Responsibilities</h5>
        <p>
          The material you upload as an instructor, such as lectures, tests,
          coding activities, practice exams, assignments, resources, and
          solutions, as well as the material for the course main page,
          laboratories, assessments, and announcements ("Submitted Content"), is
          entirely your responsibility.
        </p>
        <p>
          You vouch for and guarantee that You agree to provide and keep
          up-to-date account information;
        </p>
        <ul>
          <li>
            You own or have the required licenses, rights, consents,
            permissions, and authority to permit Deonicode to use your submitted
            content in accordance with these terms and the terms of use;
          </li>
          <li>
            Your submitted content won't violate or misappropriate the
            intellectual property rights of any third party;
          </li>
          <li>
            And you possess the necessary training, experience, credentials, and
            expertise (including education, training, knowledge, and skill set).
          </li>
        </ul>
        <p>You promise to refrain from:</p>
        <ul>
          <li>
            Post or provide any inappropriate, offensive, racist, hateful,
            sexist, pornographic, false, misleading, or inaccurate information;
          </li>
          <li>
            Post or transmit any unauthorized or unsolicited marketing
            materials, junk mail, spam, or any other type of solicitation
            (commercial or otherwise) through the Services or to any user;
          </li>
          <li>
            Use the Services for business other than tutoring, teaching, and
            instructional services to students.
          </li>
          <li>
            Interfere with or otherwise prohibit other instructors from offering
            their services or content;
          </li>
          <li>
            Pretend to be someone else or obtain unauthorized access to another
            person's account;
          </li>
          <li>Exploit the resources and assistance offered by Deonicode.</li>
        </ul>
        <h5>2. Deonicode’s License</h5>
        <p>
          You give Deonicode permission to offer, market, and otherwise utilize
          your submitted content as stated in the Terms of use. This includes
          the right to provide captions or make other accessibility-related
          changes to Submitted Content. You also provide Deonicode permission to
          sublicense these rights to your submitted content to third parties,
          including students directly and through intermediaries including
          distributors, resellers, affiliate websites, deal websites, and
          platforms that accept payment for advertising.
        </p>
        <p>
          You have the right to withdraw all or any part of your Submitted
          Content from the Services at any time, barring any prior arrangement
          (including those set forth in our Promotions Policy). Unless otherwise
          specified, Deonicode's right to sublicense the rights in this section
          with respect to new users expires 60 days following the removal of the
          Submitted Content. However, (1) any lifetime access grants made to
          students prior to the removal of the Submitted Content will remain in
          effect, and (2) Deonicode's right to utilize the Submitted Content for
          marketing purposes shall survive termination.
        </p>
        <p>
          We may record and use all or any part of your Submitted Content for
          quality control and for delivering, marketing, promoting,
          demonstrating, or operating the Services. You grant Deonicode
          permission to use your name, likeness, voice, and image in connection
          with offering, delivering, marketing, promoting, demonstrating, and
          selling the Services, your Submitted Content, or Deonicode's content,
          and you waive any rights of privacy, publicity, or other rights of a
          similar nature, to the extent permissible under applicable law.
        </p>
        <h5>3. Integrity and Security</h5>
        <strong>3.1. Guidelines for Integrity and Security</strong>
        <p>
          To ensure that you are adhering to any revisions, you should routinely
          review these policies. You acknowledge that Deonicode must first
          approve your usage of the Services, and we reserve the right to do so
          at our sole discretion.
        </p>
        <p>
          We retain the right to do so whenever necessary, without previous
          warning, and for any cause, including but not limited to the
          following:
        </p>
        <ul>
          <li>
            A trainer or piece of material does not adhere to our policies or
            legal requirements (such as the Terms of Use);
          </li>
          <li>
            The piece of content does not meet our standards for quality or
            negatively affects the learning experience of students;
          </li>
          <li>
            An instructor uses the Services in a way that constitutes unfair
            competition, such as promoting their off-site business in a way that
            violates Deonicode's policies;
          </li>
          <li>
            An instructor engages in behaviour that might reflect unfavourably
            on Deonicode or bring Deonicode into the public eye, disrepute,
            scandal, or ridicule;
          </li>
          <li>
            An instructor uses the services of a marketer or other business
            partner who violates Deonicode's policies.
          </li>
        </ul>
        <strong>3.2. Interaction with other users of the platform</strong>
        <p>
          The only data you'll get about students comes from the Services
          because instructors don't have a direct contractual relationship with
          their pupils. You acknowledge that you will not collect extra personal
          information from students or retain their personal information
          elsewhere other than the Deonicode platform and that you will only use
          the data we provide for the sole purpose of delivering our services to
          those users of the platform. By using students' personal information,
          you agree to defend Deonicode against any lawsuits that could result.
        </p>
        <strong>3.3. Actions Against Piracy</strong>
        <p>
          To assist safeguard your content from unauthorized use, we collaborate
          with anti-piracy providers. You appoint Deonicode and our anti-piracy
          vendors as your agents for the purpose of enforcing copyrights for
          each of your works through notice and takedown procedures (under
          applicable copyright laws like the Digital Millennium Copyright Act)
          and for other efforts to enforce those rights by appointing them as
          your agents in order to enable this protection. You authorize
          Deonicode and our anti-piracy partners to file notices on your behalf
          in order to protect your copyright interests.
        </p>
        <p>
          You acknowledge that the aforementioned rights are reserved by
          Deonicode and our anti-piracy providers until and until you revoke
          them by sending an email to deonicode@gmail.com from the email address
          connected to your user account with a subject line "Revoke Anti-Piracy
          Protection Rights". Any withdrawal of rights will take effect 48 hours
          after we receive it.
        </p>
        <h5>4. Pricing</h5>
        <strong>4.1 Pricing Plans</strong>
        <p>
          When developing submitted content that can be purchased on Deonicode,
          you will be asked to choose a standard price ("Standard Price") from a
          range of possible price levels. Alternatively, you can decide to make
          your Submitted Content available for no charge. In accordance with the
          rules of our Promotions Policy ("Promotional Programmes"), you will
          also have the chance to take part in some promotional programmes as a
          premium instructor.
        </p>
        <p>
          If you choose not to take part in any marketing programmes, we will
          publish your submitted content for the Standard Price or the most
          comparable local or mobile app price (as explained below). We may set
          a different list price or discounted price for your uploaded content
          if you choose to take part in a marketing programme, as detailed in
          the marketing policy.
        </p>
        <p>
          When a student pays in a foreign currency, the relevant Base Price or
          Advertising Programme price will be converted according to a
          system-wide exchange rate set by Deonicode and updated on a regular
          basis into a table of corresponding price tiers by currency ("Price
          Tier Matrix"). These conversion rates might not be the same as the
          relevant market rate in force at the time a transaction is performed
          since the Price Tier Matrix is fixed. The Price Tier Matrix is subject
          to revision at any time. You can get more details on Deonicode's price
          tiers and the Price Tier Matrix below.
        </p>
        <p>
          The pricing matrix of the mobile platform provider will govern when a
          student makes a purchase using a mobile application, and we will
          select the price tier that is most similar to the relevant Base Price
          or Promotional Programme price. Conversions for mobile app prices
          could differ from conversions in the Price Tier Matrix since mobile
          platforms apply their own currency conversion rates.
        </p>
        <p>
          You agree that we may freely distribute your Submitted Content to our
          personnel, certain partners, and when necessary to re-establish access
          to accounts that had previously purchased your Submitted Content. You
          are aware that in these situations, you won't be compensated.
        </p>
        <strong>4.2 Transaction Taxes</strong>
        <p>
          According to applicable law, if a student purchases a good or service
          in a nation where Deonicode is required to pay VAT, sales or use
          taxes, or other transaction taxes of a like nature ("Transaction
          Taxes"), we will gather and pay those Transaction Taxes to the
          appropriate tax authorities for those sales. If we decide that such
          taxes may be owed, we may decide to raise the sale price. The mobile
          platform (such as Apple's App Store or Google Play) is responsible for
          collecting any applicable Transaction Taxes for purchases made through
          mobile applications.
        </p>
        <strong>4.3 Marketing Activities</strong>
        <p>
          As described in our Marketing Strategy, Deonicode offers a number of
          alternative marketing initiatives (Promotional Plans) in which you can
          choose to take part. These tools can help you boost the amount of
          money you can make on Deonicode by determining the best pricing for
          your submitted content and then selling it as subscription
          collections.
        </p>
        <p>
          Participation in these programmes is free of charge, and you can alter
          your status at any time; however, changes you make won't take effect
          for campaigns that are already in progress, and some programmes might
          have extra conditions for termination. collections.
        </p>
        <h5>5. Cash flow</h5>
        <strong>5.1 Share of Sales</strong>
        <p>
          The money actually paid by the student to Deonicode when they buy your
          submitted content is what we refer to as the gross amount of the sale
          ("Gross Amount"). To determine the net amount of the sale (the "Net
          Amount"), we take any Transaction Taxes, mobile platform fees applied
          to mobile provider checkout sales, a 3% service and processing fee
          (apart from in Japan, where we subtract a 4% fee), and any sums paid
          to third parties in connection with the Promotional Programmes from
          this.
        </p>
        <p>
          Your revenue share will be 37% of the Net Amount less any applicable
          deductions, such as student refunds, if you have not chosen to
          participate in any of Deonicode's optional promotional programmes.
          Sales made through instructor-generated coupon codes or course
          referral links are an exception, as they are outlined below. If we
          alter this payment rate, we'll provide you 30 days' notice by means
          that are clear and conspicuous, such email or by posting a notice on
          our Services.
        </p>
        <p>
          The appropriate revenue share may alter if you choose to participate
          in any of the promotional programmes and will be as stated in the
          promotions policy.
        </p>
        <p>
          Despite the country in which the sale was made, Deonicode pays all
          instructors in U.S. dollars (USD). Your foreign exchange fees, wiring
          fees, and any other processing fees you may incur are not Deonicode's
          responsibility. The sales price (in local currency) and your converted
          revenue amount (in USD) are both displayed in your revenue report.
        </p>
        <strong>5.2 Instructor Discounts and Links for Course Referrals</strong>
        <p>
          You can create discount coupons and referral links on the Deonicode
          platform to give some of your submitted content—as permitted by the
          Services—to students at a reduced cost, at Deonicode's current cost,
          or even for free. There are restrictions on these discount codes and
          referral links, and you are not permitted to sell them on other
          websites or use them in any other way in exchange for money. Our Trust
          & Safety rules provide more details and limitations on these promo
          codes and referral links.
        </p>
        <p>
          Your income share will be 97% of the Net Amount less any appropriate
          deductions, such as student refunds, when a student uses your promo
          code or referral link during the checkout process.
        </p>
        <strong>5.3 Getting Payouts</strong>
        <p>
          If you want us to pay you promptly, you must have a functioning U.S.
          bank account (for U.S. citizens only), PayPal, Mobile Money(Orange
          Money or MTN Mobile Money for ) , or one of those three services, and
          you must keep us informed of the right email address linked to your
          account. You agree that we have the right to withhold the necessary
          taxes from your payments and that you will supply us with any
          identifying information or tax documents (such as a W-9 or W-8)
          required for payment of amounts due. If you do not provide us with
          accurate contact information or tax records, we reserve the right to
          withhold payments from you or apply additional penalties. You are
          aware of this and acknowledge that any taxes owed on your income are
          ultimately your responsibility.
        </p>
        <p>
          Payment shall be provided within 45 days after the end of the month in
          which (a) we receive the charge for a course or (b) the relevant
          course consumption happened, depending on the applicable revenue share
          model.
        </p>
        <p>
          You must decide if you are qualified to receive compensation from a US
          business as an instructor. In the event of proven fraud, infringements
          on intellectual property rights, or other legal infractions, we have
          the right to withhold payment.
        </p>
        <p>
          We may process the funds owed to you in accordance with our legal
          obligations, including by submitting those funds to the appropriate
          government authority as required by law, if we are unable to settle
          funds into your payment account after the time frame specified by your
          state, country, or other government authority in its unclaimed
          property laws.
        </p>
        <strong>5.4 Refunds</strong>
        <p>
          In accordance with the Terms of Use, you recognise and agree that
          students have the right to a reimbursement. Transactions for which
          reimbursement has been authorized in accordance with the Terms of Use
          are not subject to Instructor fee sharing. In the event that a student
          requests a refund after we have made the appropriate instructor
          payment, we reserve the right to: (1) deduct the refund amount from
          the following payment made to the instructor; or (2) if no additional
          payments are necessary to cover the refunded amounts or the payments
          are insufficient, require the instructor to return any amounts paid to
          students for the instructor's Submitted Content.
        </p>
        <h5>6. Trademarks</h5>
        <p>
          You may use our trademarks in places where we have given you
          permission while you are a published instructor and according to the
          rules below.
        </p>
        <p>
          Use our trademarks only in connection with the promotion and sale of
          your Submitted Content that is made available on Deonicode or your
          participation on Deonicode. Only use the images of our trademarks that
          we make available to you in accordance with any guidelines we may
          publish.
        </p>
        <p>
          Only promote and sell your submitted content that is published on
          Deonicode or your participation on Deonicode while using our
          trademarks as a background.
        </p>
        <p>If we ask you to stop using, please do so promptly.</p>
        <h5>7. Removal of your login information</h5>
        <p>
          Here are the instructions for deleting your instructor account. Before
          closing your account, we'll make any unpaid scheduled payments to you
          using commercially reasonable efforts. You acknowledge that when your
          account is deactivated, students who have previously signed up for
          your Submitted Content could still be able to access your name and
          that Submitted Content. You can get in touch with us through our Help
          Centre if you need assistance or run into problems deactivating your
          account.
        </p>
        <h5>8. Other Regulatory Concepts</h5>
        <strong>8.1 Arrangements Amongst Us</strong>
        <p>
          There is no joint venture, partnership, employment, contractor, or
          agency connection between you and us, as agreed upon by both of us.
        </p>
        <strong>8.2 The internationalisation</strong>
        <p>
          Any translations of these Terms in whatever language other than
          English are offered for ease of use, and you acknowledge and agree
          that in the event of a dispute, the English translation shall govern.
        </p>
        <h5>9. Get in Touch With Us</h5>
        <p>
          Getting in touch with our Support Team is the most effective approach
          to do so. Your queries, worries, and comments on what we offer are
          welcome.
        </p>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default InstructorTermsOfUse;
