import { Component, Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";
import Loader from "../component/Loader/BtnLoader";

// redux import
import { useDispatch } from "react-redux";
import { forgotUserPassword } from "../helpers/authentication/auth";
import { toast } from "react-toastify";

const title = "Forget Password";
const socialTitle = "Forget Password With Social Media";
const btnText = "Submit Now";

const socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const ForgetPass = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleForgotPass = async (e) => {
    e.preventDefault();
    if (email) {
      const data = {
        email,
      };
      const response = await forgotUserPassword(data, dispatch, setLoading);
      if (response.status === "success") {
        navigate("/");
        return toast.success(response.message);
      } else {
        return toast.error(response.message);
      }
    } else {
      toast.error("email is required");
      return;
    }
  };

  document.title = "Deonicode | Forgot Password";
  return (
    <Fragment>
      <HeaderFour />

      <PageHeader title={"Forget Password"} curPage={"Forget Password"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="User Email *"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group text-center">
                <button className="d-block lab-btn" onClick={handleForgotPass}>
                  <span>
                    {loading ? (
                      <Loader size={30} color1={"white"} color2={"white"} />
                    ) : (
                      btnText
                    )}
                  </span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Don’t Have any Account? <Link to="/login">Login</Link>
              </span>
              {/* <span className="or"><span>or</span></span> */}
              {/* <h5 className="subtitle">{socialTitle}</h5> */}
              {/* <ul className="lab-ul social-icons justify-content-center">
                                {socialList.map((val, i) => (
                                    <li key={i}>
                                        <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                    </li>
                                ))}
                            </ul> */}
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default ForgetPass;
