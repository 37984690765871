import { useSelector } from "react-redux";

const SelectCatagory = ({ select, setCategory }) => {
  const categories = useSelector((state) => state.category.categories);

  return (
    <select defaultValue={select} onChange={(e) => setCategory(e.target.value)}>
      <option value="">All Categories</option>
      {categories && categories.length > 0
        ? categories.map((item, i) => (
            <option key={i} value={item.title}>
              {item.title}
            </option>
          ))
        : "No Categories Found"}
    </select>
  );
};

export default SelectCatagory;
