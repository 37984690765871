import { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";

// redux import
import { useDispatch } from "react-redux";
import { resetUserPassword } from "../helpers/authentication/auth";
import { toast } from "react-toastify";
import { decodeToken } from "react-jwt";
import Loader from "../component/Loader/BtnLoader";

const title = "New Password";
// const socialTitle = "Forget Password With Social Media";
const btnText = "Submit Now";

// const socialList = [
//   {
//     link: "#",
//     iconName: "icofont-facebook",
//     className: "facebook",
//   },
//   {
//     link: "#",
//     iconName: "icofont-twitter",
//     className: "twitter",
//   },
//   {
//     link: "#",
//     iconName: "icofont-linkedin",
//     className: "linkedin",
//   },
//   {
//     link: "#",
//     iconName: "icofont-instagram",
//     className: "instagram",
//   },
//   {
//     link: "#",
//     iconName: "icofont-pinterest",
//     className: "pinterest",
//   },
// ];

const ResetPass = () => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const { token } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const decodedToken = decodeToken(token);
    setUserId(decodedToken.userId);
  }, [token]);

  const handleForgotPass = async (e) => {
    e.preventDefault();
    if (newPassword) {
      const data = {
        id: userId,
        newPassword,
      };
      const response = await resetUserPassword(data, dispatch, setLoading);
      if (response.status === "success") {
        navigate("/");
        return toast.success(response.message);
      } else {
        return toast.error(response.message);
      }
    } else {
      toast.error("password is required");
      return;
    }
  };

  document.title = "Deonicode | Reset Password";
  return (
    <Fragment>
      <HeaderFour />

      <PageHeader title={"New Password"} curPage={"New Password"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form">
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Enter password to login *"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-group text-center">
                <button className="d-block lab-btn" onClick={handleForgotPass}>
                  <span>
                    {loading ? (
                      <Loader size={30} color1={"white"} color2={"white"} />
                    ) : (
                      btnText
                    )}
                  </span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Don’t Have any Account? <Link to="/login">Login</Link>
              </span>
              {/* <span className="or"><span>or</span></span> */}
              {/* <h5 className="subtitle">{socialTitle}</h5> */}
              {/* <ul className="lab-ul social-icons justify-content-center">
                                {socialList.map((val, i) => (
                                    <li key={i}>
                                        <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                    </li>
                                ))}
                            </ul> */}
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default ResetPass;
