import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import mailServices from "../services/mailServices";

const initialState = {};

export const sendMails = createAsyncThunk(
  "mails/sendMails",
  async (data, thunkAPI) => {
    try {
      return await mailServices.contactForm(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscription = createAsyncThunk(
  "mails/subscription",
  async (data, thunkAPI) => {
    try {
      return await mailServices.subscribe(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const categorySlice = createSlice({
  name: "mails",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const { reset } = categorySlice.actions;

export default categorySlice.reducer;
