import { useState } from "react";
import { useSelector } from "react-redux";

const subTitle = "IT Education";
const title = (
  <h2 className="title">
    <span className="d-lg-block">Build Skills With</span> Experts Any Time{" "}
    <span className="d-lg-block">Anywhere</span>
  </h2>
);
const desc =
  "Project driven Online courses from top industry professionals. join sky rocket your IT career.";

const catagoryList = [
  {
    name: "Frontend",
    link: "Front End Development",
  },
  {
    name: "Backend",
    link: "Backend Development",
  },
  {
    name: "DevOps",
    link: "DevOps",
  },
  {
    name: "Blockchain",
    link: "Blockchain Development",
  },
];

const BannerTwo = () => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <section className="banner-section style-2">
      <div className="container">
        <div className="section-wrapper">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-xxl-5 col-xl-6 col-lg-10 me-auto">
              <div className="banner-content">
                <h6 className="subtitle text-uppercase fw-medium">
                  {subTitle}
                </h6>
                {title}
                <p className="desc">{desc}</p>
                {/* <form action={`/course/${searchQuery}`} method="GET">
                  <div className="banner-icon">
                    <i className="icofont-search"></i>
                  </div>
                  <input
                    type="text"
                    placeholder="Find your course"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button type="submit">Search</button>
                </form> */}
                <div className="banner-catagory d-flex flex-wrap">
                  <p>Most Popular : </p>
                  <ul className="lab-ul d-flex flex-wrap">
                    {catagoryList.map((val, i) => (
                      <li key={i}>
                        <a href={`course/${val.link}`}>{val.name}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6">
              <div className="thumb-part">
                <div className="banner-thumb text-center">
                  <img src="assets/images/banner/02.png" alt="img" />
                </div>
                <div className="abs-thumb d-none d-xxl-block">
                  <img src="assets/images/banner/03.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerTwo;
