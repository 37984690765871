import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import discountService from "../services/discountServices";

const initialState = {
  discount: null,
};

export const discount = createAsyncThunk(
  "discount/discount",
  async (thunkAPI) => {
    try {
      return await discountService.discounts();
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(discount.fulfilled, (state, action) => {
      state.discount = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = discountSlice.actions;

export default discountSlice.reducer;
