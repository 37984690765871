import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import courseServices from "../services/courseServices";

const initialState = {
  courses: [],
  course: null,
  courseAuthor: null,
  chapters: [],
  purchasedCourses: [],
  //
  searchedCourses: [],
  lessons: [],
  //
  progressTracking: [],
};

export const courses = createAsyncThunk("course/courses", async (thunkAPI) => {
  try {
    return await courseServices.courses();
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const course = createAsyncThunk(
  "course/course",
  async (data, thunkAPI) => {
    try {
      return await courseServices.course(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const purchasedCourses = createAsyncThunk(
  "course/purchasedCourses",
  async (data, thunkAPI) => {
    try {
      return await courseServices.purchasedCourses(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSearchedCourses = createAsyncThunk(
  "course/getSearchedCourses",
  async (data, thunkAPI) => {
    try {
      return await courseServices.getSearchedCourses(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const advancedSearchedCourses = createAsyncThunk(
  "course/advancedSearchedCourses",
  async (data, thunkAPI) => {
    try {
      return await courseServices.advancedSearchedCourses(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const courseAuthor = createAsyncThunk(
  "course/courseAuthor",
  async (data, thunkAPI) => {
    try {
      return await courseServices.courseAuthor(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const chapters = createAsyncThunk(
  "course/chapters",
  async (data, thunkAPI) => {
    try {
      return await courseServices.chapters(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const lessons = createAsyncThunk(
  "course/lessons",
  async (data, thunkAPI) => {
    try {
      return await courseServices.lessons(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const courseProgress = createAsyncThunk(
  "course/courseProgress",
  async (data, thunkAPI) => {
    try {
      return await courseServices.courseProgress(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registerProgress = createAsyncThunk(
  "course/registerProgress",
  async (data, thunkAPI) => {
    try {
      return await courseServices.registerProgress(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createCert = createAsyncThunk(
  "course/createCert",
  async (data, thunkAPI) => {
    try {
      return await courseServices.createCert(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCert = createAsyncThunk(
  "course/getCert",
  async (data, thunkAPI) => {
    try {
      return await courseServices.getCert(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(courses.fulfilled, (state, action) => {
        state.courses = action.payload.courses;
      })
      .addCase(course.fulfilled, (state, action) => {
        state.course = action.payload.course;
      })
      .addCase(chapters.fulfilled, (state, action) => {
        state.chapters = action.payload.chapters;
      })
      .addCase(lessons.fulfilled, (state, action) => {
        state.lessons = action.payload.lessons;
      })
      .addCase(courseAuthor.fulfilled, (state, action) => {
        state.courseAuthor = action.payload.user;
      })
      .addCase(purchasedCourses.fulfilled, (state, action) => {
        state.purchasedCourses = action.payload.courses;
      })
      .addCase(getSearchedCourses.fulfilled, (state, action) => {
        state.searchedCourses = action.payload;
      })
      .addCase(advancedSearchedCourses.fulfilled, (state, action) => {
        state.searchedCourses = action.payload;
      })
      .addCase(courseProgress.fulfilled, (state, action) => {
        state.progressTracking = action.payload;
      })
      .addCase(registerProgress.fulfilled, (state, action) => {
        state.progressTracking.push(action.payload);
      });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = courseSlice.actions;

export default courseSlice.reducer;
