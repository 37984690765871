import axios from "axios";

const CAMPAY_URL = `${process.env.REACT_APP_PAYMENT_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/initiate-transaction`;
const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/transaction`;
const userToken = localStorage.getItem("deonicode-user");

const purchaseCourse = async (data) => {
  const response = await axios.post(`${CAMPAY_URL}`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const newTransaction = async (data) => {
  const response = await axios.post(`${API_URL}/create`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const getCurrencyConversion = async () => {
  const response = await axios.get(`${API_URL}/currency-conversion`);
  return response.data;
};

const transactionService = {
  purchaseCourse,
  newTransaction,
  getCurrencyConversion,
};

export default transactionService;