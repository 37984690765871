import axios from "axios";

const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/rating`;

const ratings = async (data) => {
  const response = await axios.get(`${API_URL}/ratings/${data.id}`);
  return response.data;
};

const newRating = async (data) => {
  const response = await axios.post(`${API_URL}/create`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const ratingServices = { newRating, ratings };

export default ratingServices;
