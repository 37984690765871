import {
  purchaseCourse,
  newTransaction,
  getCurrencyConversion,
} from "../../redux/reducers/transactionReducer";

export const makeCoursePurchase = (data, dispatch, setLoading) => {
  const response = dispatch(purchaseCourse(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload.message,
          ussd_code: res.payload.ussd_code
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error making purchase", err);
    });
  return response;
};

export const newCourseTransaction = (data, dispatch, setLoading) => {
  const response = dispatch(newTransaction(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload.message,
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error making purchase", err);
    });
  return response;
};

export const currencyConversion = (dispatch, setLoading) => {
  const response = dispatch(getCurrencyConversion(), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload,
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error making purchase", err);
    });
  return response;
};
