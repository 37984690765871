import {
  createQuestion,
  questions,
} from "../../redux/reducers/questionReducer";

export const postQuestion = (data, dispatch, setLoading) => {
  const response = dispatch(createQuestion(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error posting question", err);
    });
  return response;
};

export const allQuestion = (data, dispatch, setLoading) => {
  const response = dispatch(questions(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error posting question", err);
    });
  return response;
};
