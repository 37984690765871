import {
  createCert,
  getCert,
  courses,
  course,
  chapters,
  lessons,
  courseAuthor,
  courseProgress,
  registerProgress,
  purchasedCourses,
  getSearchedCourses,
  advancedSearchedCourses,
} from "../../redux/reducers/courseReducer";

export const allCourses = (dispatch, setLoading) => {
  const response = dispatch(courses(), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload.message,
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error getting courses", err);
    });
  return response;
};

export const singleCourse = async (data, dispatch, setLoading) => {
  setLoading(true);
  try {
    const response = await dispatch(course(data)).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res };
      } else {
        return {
          status: "success",
          message: res,
        };
      }
    });
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return { status: "error", message: "catch error", error };
  }
};

export const allPurchasedCourses = async (data, dispatch, setLoading) => {
  setLoading(true);
  try {
    const response = await dispatch(purchasedCourses(data)).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res };
      } else {
        return {
          status: "success",
          message: res,
        };
      }
    });
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return { status: "error", message: "catch error", error };
  }
};

export const allSearchedCourses = async (data, dispatch, setLoading) => {
  setLoading(true);
  try {
    const response = await dispatch(getSearchedCourses(data)).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res };
      } else {
        return {
          status: "success",
          message: res,
        };
      }
    });
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return { status: "error", message: "catch error", error };
  }
};

export const advancedSearch = async (data, dispatch, setLoading) => {
  setLoading(true);
  try {
    const response = await dispatch(advancedSearchedCourses(data)).then(
      (res) => {
        if (res.meta.requestStatus === "rejected") {
          return { status: "error", message: "response error", res };
        } else {
          return {
            status: "success",
            message: res,
          };
        }
      }
    );
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return { status: "error", message: "catch error", error };
  }
};

export const allChapters = (data, dispatch, setLoading) => {
  const response = dispatch(chapters(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload.message,
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error getting chapters", err);
    });
  return response;
};

export const allLessons = (data, dispatch, setLoading) => {
  const response = dispatch(lessons(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload.message,
          lessons: res.payload.lessons,
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error getting chapters", err);
    });
  return response;
};

export const getCourseAuthor = async (data, dispatch, setLoading) => {
  setLoading(true);
  try {
    const response = await dispatch(courseAuthor(data)).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res };
      } else {
        return {
          status: "success",
          message: res,
          instructor: res.payload.user,
        };
      }
    });
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return { status: "error", message: "catch error", error };
  }
};

export const certRegistration = async (data, dispatch, setLoading) => {
  setLoading(true);
  try {
    const response = await dispatch(createCert(data)).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res };
      } else {
        return {
          status: "success",
          message: res,
        };
      }
    });
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return { status: "error", message: "catch error", error };
  }
};

export const getSingleCert = async (data, dispatch, setLoading) => {
  setLoading(true);
  try {
    const response = await dispatch(getCert(data)).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res };
      } else {
        return {
          status: "success",
          message: res,
        };
      }
    });
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return { status: "error", message: "catch error", error };
  }
};

export const getCourseProgress = async (data, dispatch, setLoading) => {
  setLoading(true);
  try {
    const response = await dispatch(courseProgress(data)).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res };
      } else {
        return {
          status: "success",
          message: res,
        };
      }
    });
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return { status: "error", message: "catch error", error };
  }
};

export const registerCourseProgress = async (data, dispatch, setLoading) => {
  setLoading(true);
  try {
    const response = await dispatch(registerProgress(data)).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res };
      } else {
        return {
          status: "success",
          message: res,
        };
      }
    });
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return { status: "error", message: "catch error", error };
  }
};
