/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import FooterTwo from "../component/layout/footer-2";
import placeholderImage from "../assets/images/placeholderImage.png";
import { AuthContext } from "../context/AuthContext";

// redux import
import { useDispatch, useSelector } from "react-redux";
import {
  uploadImage,
  logoutUser,
  updateUser,
  getSingleUser,
  withdrawRequest,
  updateUserPassword,
} from "../helpers/authentication/auth";
import { currencyConversion } from "../helpers/transactions/transactions";
import { toast } from "react-toastify";
import Loader from "../component/Loader/BtnLoader";
import Dropdown from "react-bootstrap/Dropdown";

function Profile() {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [passLoading, setPassLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userBio, setUserBio] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userImage, setUserImage] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [refRevenue, setRefRevenue] = useState(null);
  const [conversionRate, setConversionRate] = useState(null);
  // passwords states
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { userId } = useContext(AuthContext);
  const user = useSelector((state) => state.auth.userDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user !== null) {
      setUserImage(user?.avatar?.doc);
      setUserCountry(user?.country);
      setUserName(user?.username);
      setUserEmail(user?.email);
      setUserBio(user?.bio);
      setRefRevenue(user?.referralRevenue / conversionRate);
    }
  }, [user, conversionRate]);

  const formatMoney = (amount) => {
    let dollarUSLocale = Intl.NumberFormat("en-US");
    return dollarUSLocale.format(amount);
  };

  const getUser = async () => {
    const data = {
      id: userId,
    };
    const response = await getSingleUser(data, dispatch, setPageLoading);
    if (response.status === "error") {
      return toast.error(response.message);
    }
  };

  const getCurrencyConversion = async () => {
    const response = await currencyConversion(dispatch, setLoading);
    if (response.status === "error") {
      return toast.error(response.message);
    }
    if (response.status === "success") {
      const result = JSON.parse(response.message.data);
      const conversionRate = result.result;
      setConversionRate(conversionRate);
    }
  };

  const handleImageUpdate = async () => {
    if (profileImage) {
      let formData = new FormData();
      formData.append("profileImage", profileImage);
      const data = {
        id: userId,
        formData,
      };
      const response = await uploadImage(data, dispatch, setImageLoading);
      if (response.status === "success") {
        return toast.success(response.message);
      } else {
        return toast.error(response.message);
      }
    } else {
      toast.error("image is required");
      return;
    }
  };

  const handleUpdate = async () => {
    if (userName && userEmail) {
      const data = {
        id: userId,
        username: userName,
        email: userEmail,
        country: userCountry,
        bio: userBio,
      };
      const response = await updateUser(data, dispatch, setLoading);
      if (response.status === "success") {
        return toast.success(response.message);
      } else {
        return toast.error(response.message);
      }
    } else {
      toast.error("username & email is required");
      return;
    }
  };

  const updatePassword = async () => {
    if (currentPassword && newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        return toast.error("new passwords do not match");
      }
      const data = {
        id: userId,
        currentPassword,
        newPassword,
      };
      const response = await updateUserPassword(data, dispatch, setPassLoading);
      if (response.status === "success") {
        return toast.success(response.message);
      } else {
        return toast.error(response.message);
      }
    } else {
      toast.error("All fields are required");
      return;
    }
  };

  const withdrawalRequest = async () => {
    if (refRevenue < 20) {
      toast.warning("Minimum withdrawal is $20");
      return;
    }
    const data = { amount: refRevenue, userId };
    const response = await withdrawRequest(data, dispatch, setWithdrawLoading);
    if (response.status === "success") {
      return toast.success(response.message);
    } else {
      return toast.error(response.message);
    }
  };

  useEffect(() => {
    if (userId !== "") {
      getUser();
      getCurrencyConversion();
    }
  }, [userId]);

  document.title = "Deonicode | Profile";
  return (
    <div>
      <Header />
      <div className="container my-2">
        <div className="card p-3">
          <h5>Referral Bonus</h5>
          <p>Total Referral Bonus: ${formatMoney(refRevenue)}</p>
          <br />
          <button
            className="btn"
            style={{
              backgroundColor: "#E97F21",
              color: "#fff",
              width: 150,
            }}
            onClick={withdrawalRequest}
          >
            {withdrawLoading ? "Loading..." : "Withdraw Bonus"}
          </button>
        </div>
      </div>
      <br />
      <div className="container">
        {pageLoading ? (
          <Loader size={40} color1={"black"} color2={"black"} />
        ) : (
          <>
            <div className="container">
              <img
                src={userImage || placeholderImage}
                alt={userName}
                width={80}
                className="rounded-circle mb-2"
              />
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={(e) => setProfileImage(e.target.files[0])}
              />
              <br />
              <button
                className="btn"
                style={{
                  backgroundColor: "#E97F21",
                  color: "#fff",
                }}
                onClick={handleImageUpdate}
              >
                {imageLoading ? "Loading..." : "Save Image"}
              </button>
            </div>
            <br />
            <div className="container">
              <input
                type="text"
                value={userName}
                placeholder="Username"
                className="form-control"
                onChange={(e) => setUserName(e.target.value)}
              />
              <br />
              <input
                type="email"
                value={userEmail}
                placeholder="Email"
                className="form-control"
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <br />
              <input
                type="text"
                value={userCountry}
                placeholder="Country"
                className="form-control"
                onChange={(e) => setUserCountry(e.target.value)}
              />
              <br />
              <textarea
                name="bio"
                cols="20"
                rows="5"
                value={userBio}
                placeholder="About user"
                onChange={(e) => setUserBio(e.target.value)}
              ></textarea>
              <br />
              <button
                className="btn"
                style={{
                  backgroundColor: "#E97F21",
                  color: "#fff",
                }}
                onClick={handleUpdate}
              >
                {loading ? (
                  <Loader size={30} color1={"white"} color2={"white"} />
                ) : (
                  "Save Details"
                )}
              </button>
              <br />
              <br />
              <p>Change Password</p>
              <input
                type="password"
                placeholder="Current Password"
                className="form-control"
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <br />
              <input
                type="password"
                placeholder="New Password"
                className="form-control"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <br />
              <input
                type="password"
                placeholder="Confirm Password"
                className="form-control"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <br />
              <button
                className="btn"
                style={{
                  backgroundColor: "#E97F21",
                  color: "#fff",
                }}
                onClick={updatePassword}
              >
                {passLoading ? (
                  <Loader size={30} color1={"white"} color2={"white"} />
                ) : (
                  "Update Password"
                )}
              </button>
            </div>
          </>
        )}
      </div>
      <FooterTwo />
    </div>
  );
}

const Header = () => {
  const [loading, setLoading] = useState(false);
  const { setAuthenticated } = useContext(AuthContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await logoutUser(dispatch, setLoading);
    if (response.status === "success") {
      setAuthenticated(false);
      return navigate("/");
    } else {
      return toast.error("error loging out");
    }
  };

  return (
    <div className="header">
      <div className="container">
        <div className="header-content">
          <div className="logo">
            <Link to="/">
              <img src="assets/images/logo/01.png" alt="logo" />
            </Link>
          </div>
          <div className="menues">
            <div className="menu">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="btn fw-bold"
                  style={{
                    backgroundColor: "#E97F21",
                    color: "#fff",
                    border: "none",
                    borderRadius: 0,
                  }}
                >
                  <i className="icofont-justify-all"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item className="p-0">
                    <Link to="/dashboard" className="w-100 px-2 py-1">
                      Dashboard
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-0">
                    <Link to="/profile" className="w-100 px-2 py-1">
                      Profile
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="p-0" onClick={handleLogout}>
                    <Link to="#" className="w-100 px-2 py-1 text-danger">
                      Log out
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
