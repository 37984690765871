import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tutorServices from "../services/tutorServices";

const initialState = {
  instructors: [],
};

export const register = createAsyncThunk(
  "tutor/register",
  async (data, thunkAPI) => {
    try {
      return await tutorServices.register(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const tutors = createAsyncThunk("tutor/tutors", async (thunkAPI) => {
  try {
    return await tutorServices.tutors();
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const tutor = createAsyncThunk("tutor/tutor", async (data, thunkAPI) => {
  try {
    return await tutorServices.tutor(data);
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const tutorSlice = createSlice({
  name: "tutor",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(tutors.fulfilled, (state, action) => {
      state.instructors = action.payload.users;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = tutorSlice.actions;

export default tutorSlice.reducer;
