export const getBlogPosts = (setLoading, setPosts) => {
  setLoading(true);
  let mediumURL =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@deonicode";

  fetch(mediumURL)
    .then((res) => res.json())
    .then((data) => {
      // create two-dimensional array with 3 elements per inner array
      const avatar = data.feed?.image;
      const profileLink = data.feed?.link;
      const posts = data.items; //This is an array with the content. No feed, no info about author etc..
      // const posts = res.filter((item) => item.categories.length > 0);

      //  this.setState({ avatar: avatar, profileLink: profileLink });
      const itemRows = [];
      posts.forEach((item, i) => {
        item["avatar"] = avatar; // push avatar inside the json
        item["profilelink"] = profileLink; // push profile link inside the JSON
        const row = Math.floor(i / 3);
        if (!itemRows[row]) itemRows[row] = [];
        itemRows[row].push(item);
      });
      setPosts(itemRows);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};
