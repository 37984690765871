import { useContext } from "react";
import { useSelector } from "react-redux";
import Rating from "../sidebar/rating";
import placeholderImage from "../../assets/images/placeholderImage.png";
import logo from "../../assets/images/logo/02.png";
import { AuthContext } from "../../context/AuthContext";

const PageHeaderTwo = ({ course }) => {
  const { discountAmount } = useContext(AuthContext);
  const ratings = useSelector((state) => state.rating.ratings);
  const courseAuthor = useSelector((state) => state.course.courseAuthor);

  return (
    <div className="pageheader-section style-2">
      <div className="container">
        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
          <div className="col-lg-7 col-12">
            <div className="pageheader-thumb">
              <img
                src={course?.coverImage?.doc}
                alt="rajibraj91"
                className="w-100"
              />
              <a
                href={`${course?.introVideoUrl}`}
                className="video-button popup"
                target="_blank"
              >
                <i className="icofont-ui-play"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div className="pageheader-content">
              <div className="course-category">
                <a href="#" className="course-cate">
                  {course?.category}
                </a>
                <a href="#" className="course-offer">
                  {discountAmount ? discountAmount + "% Off" : ""}
                </a>
              </div>
              <h2 className="phs-title">{course?.title}</h2>
              <p className="phs-desc">
                Tested and verified by the Deonicode team as an advanced course
                to help impact individuals
              </p>
              <div className="phs-thumb">
                <img
                  src={
                    courseAuthor
                      ? courseAuthor?.avatar?.doc || placeholderImage
                      : logo
                  }
                  alt="rajibraj91"
                />
                <span>
                  {courseAuthor ? courseAuthor?.username : "Deonicode"}
                </span>
                <div className="course-reiew">
                  <div className="d-flex">
                    <Rating value={course?.rating} />
                    <span className="ratting-count my-1">
                      {ratings ? ratings.length : 0} reviews
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeaderTwo;
