/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

const Discount = () => {
  const [endDate, setEndDate] = useState(null);
  const [showBanner, setShowBanner] = useState(true);
  const { discountAmount, expiryDate } = useContext(AuthContext);
  const calculateDate = () => {
    const currentDate = new Date();
    const examDate = new Date(expiryDate); // Replace with your desired exam date
    const timeDifference = Math.abs(examDate - currentDate);
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    setEndDate(daysRemaining);
  };
  useEffect(() => {
    calculateDate();
  }, [expiryDate]);
  return (
    <>
      {discountAmount && showBanner ? (
        <div className="p-2 bg-dark text-wrap text-center d-flex justify-content-between">
          <p></p>
          <p className="text-light fw-bold mt-2" style={{ float: "left" }}>
            🎉{discountAmount}% OFF ON ALL COURSES 💰 EXPIRES IN{" "}
            {endDate ? endDate : ""} DAYS🎊
          </p>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={() => setShowBanner(!showBanner)}
          ></button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Discount;
