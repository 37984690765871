import { announcements } from "../../redux/reducers/announcementReducer";

export const getAnnouncements = (data, dispatch, setLoading) => {
  const response = dispatch(announcements(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload.message,
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error making purchase", err);
    });
  return response;
};
