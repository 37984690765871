import axios from "axios";

const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/q-and-a`;
const userToken = localStorage.getItem("deonicode-user");

const createQuestion = async (data) => {
  const response = await axios.post(`${API_URL}/create-message`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const questions = async (data) => {
  const response = await axios.get(
    `${API_URL}/questions/${data.userId}/${data.courseId}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
  return response.data;
};

const questionServices = { createQuestion, questions };

export default questionServices;
