/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
// import Footer from "../component/layout/footer";
// import Header from "../component/layout/header";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";
// import placeholderImage from "../assets/images/placeholderImage.png";
//
import { useDispatch, useSelector } from "react-redux";
import { allSearchedCourses, advancedSearch } from "../helpers/courses/courses";
import { allCategories } from "../helpers/category/category";
import { toast } from "react-toastify";
import Rating from "../component/sidebar/rating";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
// Loader
import Loader from "../component/Loader/BtnLoader";

const searchTitle = "Need a new search?";
const searchDesc =
  "If you didn't find what you were looking for, try a new search!";

const languageArray = ["English", "French"];
const priceRange = [
  {
    startPrice: 0,
    endPrice: 50,
  },
  {
    startPrice: 50,
    endPrice: 100,
  },
  {
    startPrice: 100,
    endPrice: 200,
  },
  {
    startPrice: 200,
    endPrice: 500,
  },
  {
    startPrice: 500,
    endPrice: 2000,
  },
];

const SearchPage = () => {
  const dispatch = useDispatch();
  const { params } = useParams();
  const { discountAmount } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  // search parameters
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [courseLevel, setCourseLevel] = useState("");
  const [tags, setTags] = useState("");
  const [language, setLanguage] = useState("");
  const [rating, setRating] = useState("");
  const [startPrice, setStartPrice] = useState(0);
  const [endPrice, setEndPrice] = useState(10000);
  //
  const [searchQuery, setSearchQuery] = useState("");
  const courses = useSelector((state) => state.course.searchedCourses);
  const categories = useSelector((state) => state.category.categories);

  const getAllCategories = async () => {
    await allCategories(dispatch, setLoading);
  };

  const getSearchedCourses = async () => {
    const data = {
      title,
      category,
      courseLevel,
      tags,
      language,
      rating,
      startPrice,
      endPrice,
    };
    const response = await allSearchedCourses(
      JSON.stringify(data),
      dispatch,
      setLoading
    );
    if (response.status === "error") {
      toast.error(response.message);
      return;
    }
  };

  const fuzzySearch = async (params) => {
    await advancedSearch(params, dispatch, setLoading);
  };

  const setPriceFuntion = (data) => {
    setStartPrice(data.startPrice);
    setEndPrice(data.endPrice);
  };

  useEffect(() => {
    fuzzySearch(params);
  }, [params]);

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    if (title || category) {
      getSearchedCourses();
    }
  }, [
    title,
    category,
    courseLevel,
    tags,
    language,
    rating,
    startPrice,
    endPrice,
  ]);

  document.title = "Deonicode | Course search";
  return (
    <Fragment>
      <HeaderFour />
      <PageHeader
        title={`${courses?.length} Results found for: ${params}`}
        curPage={"Search Result"}
      />
      <div className="blog-section padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-12">
              {/* <aside>
                <div className="widget widget-search">
                  <h4 style={{ color: "#f16126" }}>{searchTitle}</h4>
                  <p>{searchDesc}</p>
                  <form
                    className="search-wrapper"
                    action={`/course/${searchQuery}`}
                    method="GET"
                  >
                    <input
                      type="text"
                      name="s"
                      placeholder="Search..."
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button type="submit">
                      <i className="icofont-search-2"></i>
                    </button>
                  </form>
                </div>
              </aside> */}
              {/* <aside>
                <div className="widget widget-search">
                  <h4 style={{ color: "#f16126" }}>Ratings</h4>
                  <ul className="lab-ul">
                    {[5, 4, 3, 2, 1].map((val, i) => (
                      <li key={i}>
                        <div className="course-reiew d-flex">
                          <span className="mx-2">
                            <input
                              type="radio"
                              name="rating"
                              value={val}
                              onClick={(e) => setRating(e.target.value)}
                            />
                          </span>
                          <Rating value={val} color={"black"} />
                          <span className="ratting-count mx-2">{val}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </aside> */}
              <aside>
                <div className="widget widget-search">
                  <h4 style={{ color: "#f16126" }}>Categories</h4>
                  <ul className="lab-ul">
                    {categories &&
                      categories.map((val, i) => (
                        <li key={i}>
                          <div className="course-reiew d-flex">
                            <span className="mx-2">
                              <input
                                type="radio"
                                name="category"
                                value={val.title}
                                onClick={(e) => setCategory(e.target.value)}
                              />
                            </span>
                            <span className="ratting-count mx-2">
                              {val.title}
                            </span>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </aside>
              <aside>
                <div className="widget widget-search">
                  <h4 style={{ color: "#f16126" }}>Price</h4>
                  <ul className="lab-ul">
                    {priceRange &&
                      priceRange.map((val, i) => (
                        <li key={i}>
                          <div className="course-reiew d-flex">
                            <span className="mx-2">
                              <input
                                type="radio"
                                name="price"
                                onClick={(e) => setPriceFuntion(val)}
                              />
                            </span>
                            <span className="ratting-count mx-2">
                              ${val.startPrice} - ${val.endPrice}
                            </span>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </aside>
              <aside>
                <div className="widget widget-search">
                  <h4 style={{ color: "#f16126" }}>Language</h4>
                  <ul className="lab-ul">
                    {languageArray &&
                      languageArray.map((val, i) => (
                        <li key={i}>
                          <div className="course-reiew d-flex">
                            <span className="mx-2">
                              <input
                                type="radio"
                                name="language"
                                value={val}
                                onClick={(e) => setLanguage(e.target.value)}
                              />
                            </span>
                            <span className="ratting-count mx-2">{val}</span>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </aside>
            </div>
            <div className="col-lg-8 col-12">
              <article>
                <div className="section-wrapper">
                  <div className="row row-cols-1 justify-content-center g-4">
                    {loading ? (
                      <Loader size={40} color1={"black"} color2={"black"} />
                    ) : courses && courses.length > 0 ? (
                      courses.map((val, i) => (
                        <div className="col" key={i}>
                          <div className="post-item style-2">
                            <div className="post-inner d-flex">
                              <img
                                src={val?.coverImage?.doc}
                                alt={val?.title}
                                width="35%"
                              />
                              <div className="post-content">
                                <Link to={`/course-single/${val?._id}`}>
                                  <h3>{val?.title}</h3>
                                </Link>
                                <div className="meta-post">
                                  <ul className="lab-ul">
                                    <li>
                                      <i className={"icofont-clip-board"}></i>
                                      {val?.category}
                                    </li>
                                    <li>
                                      <i className={"icofont-ui-user"}></i>
                                      For: {val?.courseLevel}
                                    </li>
                                  </ul>
                                  <ul className="lab-ul">
                                    {val?.tags.map((item, i) => (
                                      <li key={i}>
                                        {/* <i className={"icofont-clip-board"}></i> */}
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <span
                                  //   style={{ backgroundColor: "orange" }}
                                  className="bg-success rounded p-2 px-3 text-light fw-bold"
                                >
                                  ${" "}
                                  {discountAmount
                                    ? val?.price -
                                      (discountAmount * val?.price) / 100
                                    : val?.price}
                                  {/* <i className="icofont-external-link"></i> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h3 className="text-center fw-bold ">No Course Found</h3>
                    )}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default SearchPage;
