import axios from "axios";

const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/announcement`;
const userToken = localStorage.getItem("deonicode-user");

const announcements = async (data) => {
  const response = await axios.get(`${API_URL}/announcements/${data.id}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return response.data;
};

const transactionService = {
  announcements,
};

export default transactionService;
