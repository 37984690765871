import { Fragment, useState, useEffect } from "react";
import PageHeader from "../component/layout/pageheader";
import HeaderFour from "../component/layout/header-4";
// import Pagination from "../component/sidebar/pagination";
import FooterTwo from "../component/layout/footer-2";
import Loader from "../component/Loader/BtnLoader";
import { getBlogPosts } from "../helpers/medium/medium";

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllBlogPosts = async () => {
    getBlogPosts(setLoading, setPosts);
  };

  function ToText(node) {
    let tag = document.createElement("div");
    tag.innerHTML = node;
    node = tag.innerText;
    return node;
  }

  function ShortenText(text, startingPoint, maxLength) {
    return text.length > maxLength
      ? text.slice(startingPoint, maxLength)
      : text;
  }

  useEffect(() => {
    getAllBlogPosts();
  }, []);

  return (
    <Fragment>
      <HeaderFour />
      <PageHeader title={"Our Blog Posts"} curPage={"Blog"} />
      <div className="blog-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
              {loading ? (
                <Loader size={40} color1={"black"} color2={"black"} />
              ) : posts.length > 0 ? (
                posts.map((item, i) => (
                  <div key={i}>
                    {item.map((val, j) => (
                      <div className="col mb-4" key={j}>
                        <div className="post-item">
                          <div className="post-inner">
                            {/* <div className="post-thumb">
                              <a href={`${val.link} `} target="_blank" rel="noreferrer">
                                <img
                                  src={`${val.thumbnail}`}
                                  alt={`Thumbnail`}
                                />
                              </a>
                            </div> */}
                            <div className="post-content">
                              <a
                                href={`${val.link} `}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h4>{ShortenText(val.title, 0, 50)}</h4>
                              </a>
                              <div className="meta-post">
                                <ul className="lab-ul">
                                  <li>
                                    <i className={"icofont-ui-user"}></i>
                                    {val.author}
                                  </li>
                                  <li>
                                    <i className={"icofont-calendar"}></i>
                                    {val.pubDate}
                                  </li>
                                </ul>
                              </div>
                              <p>
                                {ShortenText(ToText(val.content), 0, 120) +
                                  "..."}
                              </p>
                            </div>
                            <div className="post-footer">
                              <div className="pf-left">
                                <a
                                  href={`${val.link} `}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="lab-btn-text"
                                >
                                  Read More
                                  <i className="icofont-external-link"></i>
                                </a>
                              </div>
                              <div className="pf-right">
                                <i className="icofont-comment"></i>
                                {/* <span className="comment-count">{3}</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div>
                  <p className="text-secondary text-center ">No Post Found</p>
                </div>
              )}
            </div>
            {/* <Pagination /> */}
          </div>
        </div>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default BlogPage;
