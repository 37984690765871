import { useState, useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

// auth context
import { AuthContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { allCategories } from "../../helpers/category/category";
import { toast } from "react-toastify";
// discount banner
import Discount from "../banner/discount";

const HeaderFour = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);
  const { authenticated } = useContext(AuthContext);
  const categories = useSelector((state) => state.category.categories);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  const getAllCategories = async () => {
    await allCategories(dispatch, setLoading);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <>
      <header
        className={`header-section style-4 ${
          headerFiexd ? "header-fixed fadeInUp" : ""
        }`}
      >
        <Discount />
        <div className={`header-top d-md-none ${socialToggle ? "open" : ""}`}>
          <div className="container">
            <div className="header-top-area">
              {authenticated ? (
                <>
                  <Link to="/profile" className="fw-bold">
                    <span>Account</span>
                  </Link>
                  <Link to="/dashboard" className="fw-bold">
                    Dashboard
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/login" className="fw-bold">
                    <span>Login</span>
                  </Link>
                  <Link to="/become-a-tutor" className="fw-bold">
                    <span>Become a Tutor</span>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo-search-acte">
                <div className="logo">
                  <Link to="/">
                    <img
                      src="/assets/images/logo/01.png"
                      width="200"
                      alt="logo"
                      style={{ padding: 0, margin: 0 }}
                    />
                  </Link>
                </div>
              </div>
              <div className="menu-area">
                <div className="menu">
                  <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                    <li className="menu-item-has-children">
                      <a
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-bs-offset="0,0"
                      >
                        Categories
                      </a>
                      <ul className="lab-ul dropdown-menu">
                        {categories && categories.length > 0 ? (
                          categories.map((item) => (
                            <li key={item._id}>
                              <NavLink to={`/course/${item.title}`}>
                                {item.title}
                              </NavLink>
                            </li>
                          ))
                        ) : (
                          <li>
                            <NavLink to="#">Loading categories...</NavLink>
                          </li>
                        )}
                      </ul>
                    </li>
                    <li>
                      <NavLink to="/course">Courses</NavLink>
                    </li>
                    <li>
                      <NavLink to="/blog">Blog</NavLink>
                    </li>
                    {/* <li>
                  <NavLink to="/about">About</NavLink>
                  </li> */}

                    <li>
                      <NavLink to="/contact">Contact</NavLink>
                    </li>
                  </ul>
                </div>
                {authenticated ? (
                  <Link
                    to="/dashboard"
                    className="lab-btn me-3 d-none d-md-block"
                  >
                    <span>Dashboard</span>
                  </Link>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="lab-btn me-3 d-none d-md-block"
                    >
                      <span>Login</span>
                    </Link>
                    <Link
                      to="/become-a-tutor"
                      className="lab-btn me-3  d-none d-md-block title"
                    >
                      <span>Become a Tutor</span>
                    </Link>
                  </>
                )}

                {/* <Link to="/login" className="d-none d-md-block">
                Log In
              </Link> */}

                <div
                  className={`header-bar d-lg-none ${
                    menuToggle ? "active" : ""
                  }`}
                  onClick={() => setMenuToggle(!menuToggle)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div
                  className="ellepsis-bar d-md-none"
                  onClick={() => setSocialToggle(!socialToggle)}
                >
                  <i className="icofont-info-square"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderFour;
