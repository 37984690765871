import axios from "axios";

const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/course`;
const LESSON_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/lesson`;
const CHAPTER_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/chapter`;
const INSTRUCTOR_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/instructor`;
const PROGRESS_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/progress-tracking`;
const CERTIFICATE_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/cert`;
const userToken = localStorage.getItem("deonicode-user");

const courses = async () => {
  const response = await axios.get(`${API_URL}/approved-courses`);
  return response.data;
};

const course = async (data) => {
  const response = await axios.get(`${API_URL}/course/${data.id}`);
  return response.data;
};

const purchasedCourses = async (data) => {
  const response = await axios.get(`${API_URL}/purchased-courses/${data.id}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return response.data;
};

const getSearchedCourses = async (data) => {
  const response = await axios.get(`${API_URL}/search/${data}`);
  return response.data;
};

const advancedSearchedCourses = async (data) => {
  console.log(data);
  const response = await axios.get(`${API_URL}/fuzzy-search/${data}`);
  return response.data;
};

const courseAuthor = async (data) => {
  const response = await axios.get(
    `${INSTRUCTOR_URL}/course-author/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
  return response.data;
};

const chapters = async (data) => {
  const response = await axios.get(`${CHAPTER_URL}/chapters/${data.id}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return response.data;
};
const lessons = async (data) => {
  const response = await axios.get(`${LESSON_URL}/lessons/${data.id}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return response.data;
};

const courseProgress = async (data) => {
  const response = await axios.post(`${PROGRESS_URL}/watched`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const registerProgress = async (data) => {
  const response = await axios.post(`${PROGRESS_URL}/register`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const createCert = async (data) => {
  const response = await axios.post(`${CERTIFICATE_URL}/register`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const getCert = async (data) => {
  const response = await axios.post(`${CERTIFICATE_URL}/certificate`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const courseServices = {
  advancedSearchedCourses,
  getSearchedCourses,
  purchasedCourses,
  registerProgress,
  courseProgress,
  courseAuthor,
  createCert,
  getCert,
  courses,
  course,
  chapters,
  lessons,
};

export default courseServices;
