import { Component, Fragment, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";

import { AuthContext } from "../context/AuthContext";

// redux import
import { useDispatch } from "react-redux";
import { loginUser } from "../helpers/authentication/auth";
import { toast } from "react-toastify";
import Loader from "../component/Loader/BtnLoader";

const title = "Login";
const socialTitle = "Login With Social Media";
const btnText = "Submit Now";

const socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthenticated } = useContext(AuthContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email && password) {
      const data = {
        email,
        password,
      };
      const response = await loginUser(data, dispatch, setLoading);
      if (response.status === "success") {
        setAuthenticated(true);
        navigate("/");
        return toast.success(response.message);
      } else {
        return toast.error(response.message);
      }
    } else {
      toast.error("All fields are required");
      return;
    }
  };

  document.title = "Deonicode | Login";
  return (
    <Fragment>
      <HeaderFour />

      <PageHeader title={"Login Page"} curPage={"Login"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email *"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Password *"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                  <div className="checkgroup">
                    <input type="checkbox" name="remember" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                  <Link to="/forgetpass">Forget Password?</Link>
                </div>
              </div>
              <div className="form-group text-center">
                <button className="d-block lab-btn" onClick={handleLogin}>
                  <span>
                    {loading ? (
                      <Loader size={30} color1={"white"} color2={"white"} />
                    ) : (
                      btnText
                    )}
                  </span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Don’t Have any Account? <Link to="/signup">Sign Up</Link>
              </span>
              {/* <span className="or"><span>or</span></span>
                            <h5 className="subtitle">{socialTitle}</h5>
                            <ul className="lab-ul social-icons justify-content-center">
                                {socialList.map((val, i) => (
                                    <li key={i}>
                                        <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                    </li>
                                ))}
                            </ul> */}
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default LoginPage;
