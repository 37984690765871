import { BrowserRouter, Routes, Route } from "react-router-dom";
import "swiper/css";

import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import AboutPage from "./page/about";
import PrivacyPlocy from "./page/privacy-policy";
import TermsOfUse from "./page/terms-of-use";
import BlogPage from "./page/blog";
// import BlogPageTwo from "./page/blog-2";
// import BlogPageThree from "./page/blog-3";
// import BlogSingle from "./page/blog-single";
import TutorRegistration from "./page/tutorSignup";
// import CartPage from "./page/cart-page";
import ContactPage from "./page/contact";
import CoursePage from "./page/course";
import CourseSingle from "./page/course-single";
import CourseView from "./page/course-view";
import ForgetPass from "./page/forgetpass";
import ResetPass from "./page/resetPass";
import Home from "./page/home";
// import HomeTwo from "./page/home-2";
// import HomeThree from "./page/home-3";
// import HomeFour from "./page/home-4";
// import HomeFive from "./page/home-5";
// import HomeSix from "./page/home-6";
// import HomeSeven from "./page/home-7";
import InstructorPage from "./page/instructor";
import LoginPage from "./page/login";
// import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";
// import ShopPage from "./page/shop";
// import ShopDetails from "./page/shop-single";
import SignupPage from "./page/signup";
// import TeamPage from "./page/team";
import TeamSingle from "./page/team-single";
import MyAccount from "./page/myAccount";
import Mentorship from "./page/mentorship";
import Profile from "./page/profile";
import Certification from "./page/Certificate";
import InstructorTermsOfUse from "./page/instructor-terms-of-use";
// react toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="index-2" element={<HomeTwo />} /> */}
        {/* <Route path="index-3" element={<HomeThree />} /> */}
        {/* <Route path="index-4" element={<HomeFour />} /> */}
        {/* <Route path="index-5" element={<HomeFive />} /> */}
        {/* <Route path="index-6" element={<HomeSix />} /> */}
        {/* <Route path="index-7" element={<HomeSeven />} /> */}
        <Route path="course" element={<CoursePage />} />
        <Route path="course-single/:id" element={<CourseSingle />} />
        {/* <Route path="course-single/:id?referrer=:referrer" element={<CourseSingle />} /> */}
        <Route path="course-view/:id" element={<CourseView />} />
        <Route path="blog" element={<BlogPage />} />
        {/* <Route path="blog-2" element={<BlogPageTwo />} /> */}
        {/* <Route path="blog-3" element={<BlogPageThree />} /> */}
        {/* <Route path="blog-single" element={<BlogSingle />} /> */}
        <Route path="about" element={<AboutPage />} />
        <Route path="instructor-terms" element={<InstructorTermsOfUse />} />
        <Route path="privacy-policy" element={<PrivacyPlocy />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />

        {/* <Route path="team" element={<TeamPage />} /> */}
        <Route path="team-single/:id" element={<TeamSingle />} />
        <Route path="instructor" element={<InstructorPage />} />
        <Route path="become-a-tutor" element={<TutorRegistration />} />
        {/* <Route path="shop" element={<ShopPage />} /> */}
        {/* <Route path="shop-single" element={<ShopDetails />} /> */}
        {/* <Route path="cart-page" element={<CartPage />} /> */}
        <Route path="course/:params" element={<SearchPage />} />
        {/* <Route path="search-none" element={<SearchNone />} /> */}
        <Route path="contact" element={<ContactPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="forgetpass" element={<ForgetPass />} />
        <Route path="new-password/:token" element={<ResetPass />} />
        <Route path="dashboard" element={<MyAccount />} />
        <Route path="mentorship" element={<Mentorship />} />
        <Route path="profile" element={<Profile />} />
        <Route path="certification/:courseId" element={<Certification />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
