import axios from "axios";

const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/category`;
const userToken = localStorage.getItem("deonicode-user");

const categories = async () => {
  const response = await axios.get(`${API_URL}/categories`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return response.data;
};

const categoryServices = { categories };

export default categoryServices;
