import { useNavigate } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  makeCoursePurchase,
  newCourseTransaction,
  currencyConversion,
} from "../../helpers/transactions/transactions";
import {
  getSingleUser,
  getAffiliateLink,
} from "../../helpers/authentication/auth";
import { io } from "socket.io-client";
import Loader from "../Loader/BtnLoader";
import { CopyToClipboard } from "react-copy-to-clipboard";
// REACT SHARE
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  InstapaperShareButton,
  InstapaperIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

const excenge = "Limited time offer";
const paymentTitle = "Secure Payment:";
const shareTitle = "Share This Course:";
const btnText = "Purchase Now";

// const socialList = [
//   {
//     siteLink: "#",
//     iconName: "icofont-twitter",
//     className: "twitter",
//   },
//   {
//     siteLink: "#",
//     iconName: "icofont-vimeo",
//     className: "vimeo",
//   },
//   {
//     siteLink: "#",
//     iconName: "icofont-rss",
//     className: "rss",
//   },
// ];

const CourseSideDetail = ({ course, chapters, numberOfLessons }) => {
  const [loading, setLoading] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [ussd_code, setussd_code] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [conversionRate, setConversionRate] = useState("");
  //
  const [showLink, setShowLink] = useState(false);
  const [genLinkLoading, setGenLinkLoading] = useState(false);
  const [affiliateLink, setAffiliateLink] = useState("");
  //
  const referrerId = new URLSearchParams(window.location.search).get(
    "referrer"
  );

  const { username, useremail, userId, authenticated, discountAmount } =
    useContext(AuthContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const user = useSelector((state) => state.auth.userDetails);
  const courses = user?.purchasedCourses;

  const price = (course && course?.price) || "";
  const csdcList = [
    {
      iconName: "icofont-ui-alarm",
      leftText: "Course level",
      rightText: course?.courseLevel,
    },
    {
      iconName: "icofont-book-alt",
      leftText: "Course Duration",
      rightText: course?.duration + " Hour(s)",
    },
    // {
    //   iconName: "icofont-signal",
    //   leftText: "Online Class",
    //   rightText: "08",
    // },
    {
      iconName: "icofont-video-alt",
      leftText: "Chapters",
      rightText: chapters?.length,
    },
    {
      iconName: "icofont-video-alt",
      leftText: "Lessions",
      rightText: numberOfLessons,
    },
    // {
    //   iconName: "icofont-abacus-alt",
    //   leftText: "Quizzes",
    //   rightText: "0",
    // },
    // {
    //   iconName: "icofont-hour-glass",
    //   leftText: "Pass parcentages",
    //   rightText: "80",
    // },
    {
      iconName: "icofont-certificate",
      leftText: "Certificate",
      rightText: "Yes",
    },
    {
      iconName: "icofont-globe",
      leftText: "Language",
      rightText: course?.language,
    },
  ];

  const socket = io(process.env.REACT_APP_ENDPOINT, {
    autoConnect: false,
  });

  const getUser = async () => {
    const data = {
      id: userId,
    };
    const response = await getSingleUser(data, dispatch, setLoading);
    if (response.status === "error") {
      return toast.error(response.message);
    }
  };

  const getCurrencyConversion = async () => {
    const response = await currencyConversion(dispatch, setLoading);
    if (response.status === "error") {
      return toast.error(response.message);
    }
    if (response.status === "success") {
      const result = JSON.parse(response.message.data);
      const conversionRate = result.result;
      setConversionRate(conversionRate);
    }
  };

  const generateLink = async () => {
    if (authenticated) {
      const data = {
        userId,
        courseId: course._id,
      };
      const response = await getAffiliateLink(
        data,
        dispatch,
        setGenLinkLoading
      );
      if (response.status === "error") {
        return toast.error(response.message);
      }
      if (response.status === "success") {
        const link = response.res.referralLink;
        setAffiliateLink(link);
        setShowLink(true);
      }
    } else {
      toast.error("Login to generate link");
      return;
    }
  };

  useEffect(() => {
    if (userId !== "") {
      getUser();
    }
  }, [userId]);

  useEffect(() => {
    getCurrencyConversion();
  }, []);

  const purchaseCourseHandler = async () => {
    try {
      if (authenticated) {
        if (phoneNumber && phoneNumber.length > 8) {
          socket.connect();
          socket.emit("join", "room");

          const price = Math.round(course.price * conversionRate);

          const data = {
            userId,
            amount: price,
            phone: "237" + phoneNumber,
            name: username,
            email: useremail,
          };
          makeCoursePurchase(data, dispatch, setLoading).then((response) => {
            if (response.status === "success") {
              toast.success("Processsing. please wait...");
              setussd_code(response.ussd_code);
              setProcessing(true);
              socket.on("status", (status) => {
                const newData = {
                  courseId: course._id,
                  instructorId: course.instructorId,
                  userId,
                  amount: status.amount,
                  revenue: status.app_amount,
                  phone: phoneNumber,
                  name: username,
                  email: useremail,
                  medium: status.operator,
                  status: status.status,

                  code: status.code,
                  operator_reference: status.operator_reference,
                  reference: status.reference,
                  signature: status.signature,
                  isReferral: referrerId,
                };
                if (status.status === "SUCCESSFUL") {
                  newCourseTransaction(newData, dispatch, setLoading).then(
                    (newResponse) => {
                      if (newResponse.status === "success") {
                        setProcessing(false);
                        setussd_code("");
                        socket.disconnect();
                        navigate("/dashboard");
                        toast.success(newResponse.message);
                        return;
                      } else if (newResponse.status === "error") {
                        setProcessing(false);
                        setussd_code("");
                        socket.disconnect();
                        toast.error("An error occured");
                        return;
                      } else {
                        setProcessing(false);
                        socket.disconnect();
                        toast.error(newResponse.message);
                        return;
                      }
                    }
                  );
                }
                if (status.status === "FAILED") {
                  setProcessing(false);
                  toast.error("Transaction Failed");
                  return;
                }
                return;
              });
            } else {
              toast.error(response.message);
              return;
            }
            return;
          });
        } else {
          toast.error("Invalid phone number");
        }
      } else {
        toast.error("Please login to make purchase");
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="course-side-detail">
      <div className="csd-title">
        <div className="csdt-left">
          <h4 className="mb-0">
            <sup>$</sup>
            {discountAmount ? price - (discountAmount * price) / 100 : price}
          </h4>
        </div>
        <div className="csdt-right">
          <p className="mb-0">
            <i className="icofont-clock-time"></i>
            {excenge}
          </p>
        </div>
      </div>
      <div className="csd-content">
        <div className="csdc-lists">
          <ul className="lab-ul">
            {csdcList.map((val, i) => (
              <li key={i}>
                <div className="csdc-left">
                  <i className={val.iconName}></i>
                  {val.leftText}
                </div>
                <div className="csdc-right">{val.rightText}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="sidebar-social">
          <div className="ss-title">
            <h6>{shareTitle}</h6>
          </div>
          <div className="ss-content">
            <ul className="lab-ul">
              {/* {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink} className={val.className}>
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))} */}
              <li>
                <FacebookShareButton
                  url={currentUrl}
                  quote={course?.title}
                  hashtag={`#${course?.category}`}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton
                  url={currentUrl}
                  quote={course?.title}
                  hashtag={`#${course?.category}`}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </li>
              <li>
                <WhatsappShareButton
                  url={currentUrl}
                  quote={course?.title}
                  hashtag={`#${course?.category}`}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </li>
              <li>
                <TelegramShareButton
                  url={currentUrl}
                  quote={course?.title}
                  hashtag={`#${course?.category}`}
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
              </li>
              <li>
                <InstapaperShareButton
                  url={currentUrl}
                  quote={course?.title}
                  hashtag={`#${course?.category}`}
                >
                  <InstapaperIcon size={32} round />
                </InstapaperShareButton>
              </li>
            </ul>
          </div>
        </div>
        <div className="sidebar-payment">
          <div className="sp-title">
            <h6>{paymentTitle}</h6>
          </div>
          {/* <div className="sp-thumb">
            <img
              src={require("../../assets/images/pyment/01.jpg")}
              alt="Secure payment"
            />
          </div> */}
        </div>
        <div className="course-enroll">
          <div
            style={{ border: "1px solid black", cursor: "pointer" }}
            className="text-center p-2 fw-bold"
            onClick={() => setShowPayment(!showPayment)}
          >
            Purchase Course
          </div>
          {showPayment ? (
            courses?.includes(course?._id) ? (
              <div className="text-center m-4 fw-bold">
                Already bought the course
              </div>
            ) : (
              <div className="mt-4">
                <h5>Mobile Money</h5>
                <div className="sp-thumb mb-2">
                  <img
                    src={require("../../assets/images/pyment/mobile-money.jpg")}
                    alt="Secure payment"
                  />
                </div>
                <div className="">
                  <input
                    type="number"
                    placeholder="Mobile Money Number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <button
                  className="lab-btn text-white mt-1"
                  onClick={purchaseCourseHandler}
                >
                  {loading ? (
                    <Loader size={30} color1={"white"} color2={"white"} />
                  ) : processing ? (
                    <span className="d-flex">
                      <Loader size={20} color1={"white"} color2={"white"} />
                      <span className="m-1">
                        dail {ussd_code} to complete transaction...
                      </span>
                    </span>
                  ) : (
                    btnText
                  )}
                </button>
              </div>
            )
          ) : (
            ""
          )}
        </div>
        <div>
          <div className="sidebar-payment">
            <div className="sp-title">
              <h6>Course affiliate Link</h6>
            </div>
          </div>
          <div className="course-enroll">
            {showLink ? (
              affiliateLink ? (
                <div className="my-1">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Referral link"
                      value={affiliateLink}
                    />
                  </div>
                  <CopyToClipboard
                    className="my-2"
                    text={affiliateLink}
                    onCopy={() => toast.success("Link copied")}
                  >
                    <button
                      style={{
                        border: "1px solid black",
                        cursor: "pointer",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Click to copy
                    </button>
                  </CopyToClipboard>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {affiliateLink ? (
              ""
            ) : (
              <div
                style={{ border: "1px solid black", cursor: "pointer" }}
                className="text-center p-2 fw-bold my-2"
                onClick={generateLink}
              >
                {genLinkLoading ? (
                  <span className="d-flex">
                    <Loader size={20} color1={"black"} color2={"black"} />
                    <span className="m-1">Generating Link ...</span>
                  </span>
                ) : (
                  " Generate Link"
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSideDetail;
