import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import questionServices from "../services/questionServices";

const initialState = {
  questions: [],
};

export const createQuestion = createAsyncThunk(
  "question/createQuestion",
  async (data, thunkAPI) => {
    try {
      return await questionServices.createQuestion(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const questions = createAsyncThunk(
  "question/questions",
  async (data, thunkAPI) => {
    try {
      return await questionServices.questions(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(questions.fulfilled, (state, action) => {
      state.questions = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = questionSlice.actions;

export default questionSlice.reducer;
