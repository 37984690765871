import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allLessons } from "../../helpers/courses/courses";
import { toast } from "react-toastify";
import Loader from "../Loader/BtnLoader";

const Lesson = ({
  chapter,
  setVideourl,
  setIsLoom,
  numberOfLessons,
  setNumberOfLessons,
  selected,
  setSelected,
  progressLessons,
}) => {
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const dispatch = useDispatch();

  const getAllLessons = async () => {
    if (chapter) {
      const data = {
        id: chapter._id,
      };
      const response = await allLessons(data, dispatch, setLoading);
      if (response.status === "error") {
        toast.error(response.message);
        return;
      }
      if (response.status === "success") {
        setLessons([...response.lessons].reverse());
        return;
      }
    }
  };

  const setVideoLesson = (item) => {
    setSelected({ title: item.title, chapterId: item.chapterId, id: item._id });
    if (item?.loomType) {
      setIsLoom(item?.loomType);
      setVideourl(item?.loomLink);
    } else {
      setIsLoom(false);
      setVideourl(item?.content?.doc);
    }
  };

  useEffect(() => {
    getAllLessons();
  }, []);

  useEffect(() => {
    if (lessons.length > 0 && numberOfLessons) {
      let num = lessons.length;
      let refLessons = [...numberOfLessons, { chapter: chapter._id, num: num }];
      setNumberOfLessons(refLessons);
    }
  }, [lessons, numberOfLessons, chapter._id]);

  return (
    <>
      {loading ? (
        <Loader size={40} color1={"black"} color2={"black"} />
      ) : lessons.length > 0 ? (
        lessons.map((item, index) => (
          <div
            className={
              selected?.id === item._id && selected?.chapterId === chapter._id
                ? "card-body py-0 bg-light"
                : "card-body py-0"
            }
            key={index}
            id={index}
            onClick={() => setVideoLesson(item)}
          >
            <div className="course-lists d-flex flex-wrap justify-content-between">
              <div className="csa-left">
                {progressLessons?.length > 0 &&
                progressLessons.some((val) => val.lessonId === item._id) ? (
                  <i className="icofont-checked complite"></i>
                ) : (
                  <i className="icofont-square"></i>
                )}

                <h6>{item.title}</h6>
                <p>
                  <i className="icofont-play-alt-2"></i>
                  <span style={{ 
                    marginLeft: 10
                   }}> {item.duration} Min</span>
                </p>
              </div>
              {selected?.id === item._id &&
              selected?.chapterId === chapter._id ? (
                <i
                  className="icofont-sound-wave-alt"
                  style={{ fontSize: 50, color: "#E97F21" }}
                ></i>
              ) : (
                ""
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="card-body py-0">
          <div className="course-lists d-flex flex-wrap justify-content-between">
            <div className="csa-left">
              <p>No Lessons Found</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Lesson;
