/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
// import Footer from "../component/layout/footer";
// import Header from "../component/layout/header";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";
// import Progress from "../component/sidebar/progress";
// import Rating from "../component/sidebar/rating";
//
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTutor } from "../helpers/instructor/instructor";
import placeholderImage from "../assets/images/placeholderImage.png";
import Loader from "../component/Loader/BtnLoader";

// const skillList = [
//   {
//     percentage: "80",
//     text: "English",
//   },
//   {
//     percentage: "70",
//     text: "Hindi",
//   },
//   {
//     percentage: "90",
//     text: "Bangla",
//   },
//   {
//     percentage: "50",
//     text: "Arabic",
//   },
// ];

// const awardList = [
//   {
//     imgUrl: "assets/images/instructor/single/icon/01.png",
//     imgAlt: "instructor",
//     text: "Award 2018",
//   },
//   {
//     imgUrl: "assets/images/instructor/single/icon/02.png",
//     imgAlt: "instructor",
//     text: "Award 2019",
//   },
//   {
//     imgUrl: "assets/images/instructor/single/icon/03.png",
//     imgAlt: "instructor",
//     text: "Award 2020",
//   },
//   {
//     imgUrl: "assets/images/instructor/single/icon/04.png",
//     imgAlt: "instructor",
//     text: "Award 2022",
//   },
// ];

const TeamSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tutor, setTutor] = useState("");

  const getSingleTutor = async () => {
    const data = {
      id,
    };
    const response = await getTutor(data, dispatch, setLoading);
    if (response.status === "error") {
      toast.error(response.message);
      return;
    }
    if (response.status === "success") {
      setTutor(response.payload);
      return;
    }
  };

  useEffect(() => {
    getSingleTutor();
  }, [id]);

  const memInfoLisst = [
    {
      leftText: "Email",
      rightText: `${tutor?.email}`,
    },
    {
      leftText: "Occupation",
      rightText: `${tutor?.occupation}`,
    },
    {
      leftText: "Phone",
      rightText: `${tutor?.phoneNumber}`,
    },
    {
      leftText: "Country",
      rightText: `${tutor?.country || "No Country Found"}`,
    },
  ];

  const memSocialList = [
    {
      iconName: "icofont-twitter",
      className: "twitter",
      siteLink: tutor?.twitter,
    },
    // {
    //   iconName: "icofont-instagram",
    //   className: "instagram",
    //   siteLink: tutor?.instagram,
    // },
    // {
    //   iconName: "icofont-facebook",
    //   className: "twitter",
    //   siteLink: tutor?.facebook,
    // },
    {
      iconName: "icofont-linkedin",
      className: "linkedin",
      siteLink: tutor?.linkedIn,
    },
    // {
    //   iconName: "icofont-youtube",
    //   className: "instagram",
    //   siteLink: tutor?.youtube,
    // },
  ];

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Loader size={80} color1={"black"} color2={"black"} />
      </div>
    );
  }

  document.title = "Deonicode | Instructor details";
  return (
    <Fragment>
      <HeaderFour />
      <PageHeader title={`Sir ${tutor?.username}`} curPage={tutor?.username} />
      <section className="instructor-single-section padding-tb section-bg">
        <div className="container">
          <div className="instructor-wrapper">
            <div className="instructor-single-top">
              <div className="instructor-single-item d-flex flex-wrap justify-content-between">
                <div className="instructor-single-thumb">
                  <img
                    src={tutor?.avatar?.doc || placeholderImage}
                    alt="instructor"
                  />
                </div>
                <div className="instructor-single-content">
                  <h4 className="title">{tutor?.username}</h4>
                  <p className="ins-dege">{tutor?.educationLevel}</p>
                  {/* <Rating /> */}
                  {/* <p className="ins-desc">{desc}</p> */}
                  <h6 className="subtitle">Bio</h6>
                  <p className="ins-desc">{tutor?.bio || "No Bio Found"}</p>
                  <ul className="lab-ul">
                    {memInfoLisst.map((val, i) => (
                      <li
                        className="d-flex flex-wrap justify-content-start"
                        key={i}
                      >
                        <span className="list-name">{val.leftText}</span>
                        <span className="list-attr">{val.rightText}</span>
                      </li>
                    ))}
                    <li className="d-flex flex-wrap justify-content-start">
                      <span className="list-name">Social</span>
                      <ul className="lab-ul list-attr d-flex flex-wrap justify-content-start">
                        {memSocialList.map((val, i) => (
                          <li key={i}>
                            <a className={val.className} href={val.siteLink}>
                              <i className={val.iconName}></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="instructor-single-bottom d-flex flex-wrap mt-4">
              <div className="col-xl-6 pb-5 pb-xl-0 d-flex flex-wrap justify-content-lg-start justify-content-between">
                <h4 className="subtitle">{skillTitle}</h4>
                {skillList.map((val, i) => (
                  <div className="text-center skill-item" key={i}>
                    <div className="skill-thumb">
                      <Progress strokeWidth={8} percentage={val.percentage} />
                    </div>
                    <p>{val.text}</p>
                  </div>
                ))}
              </div>
              <div className="col-xl-6 d-flex flex-wrap justify-content-lg-start justify-content-between">
                <h4 className="subtitle">{awardTitle}</h4>
                {awardList.map((val, i) => (
                  <div className="skill-item text-center" key={i}>
                    <div className="skill-thumb">
                      <img src={`/${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <p>{val.text}</p>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <FooterTwo />
    </Fragment>
  );
};

export default TeamSingle;
