import { useSelector } from "react-redux";

const title = "Course Categories";

const CourseSideCetagory = () => {
  const categories = useSelector((state) => state.category.categories);
  const courses = useSelector((state) => state.course.courses);

  return (
    <div className="course-side-cetagory">
      <div className="csc-title">
        <h5 className="mb-0">{title}</h5>
      </div>
      <div className="csc-content">
        <div className="csdc-lists">
          <ul className="lab-ul">
            {categories && categories.length > 0 ? (
              categories.map((val, i) => {
                const count = courses.filter(
                  (cat) => cat.category[0] === val.title
                );
                return (
                  <li key={i}>
                    <div className="csdc-left">
                      <a
                        href={`${process.env.REACT_APP_FRONTEND_URL}/course/${val.title}`}
                      >
                        {val.title}
                      </a>
                    </div>
                    <div className="csdc-right">{count.length}</div>
                  </li>
                );
              })
            ) : (
              <li>
                <div className="csdc-left">Loading categories...</div>
                <div className="csdc-right">0</div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CourseSideCetagory;
