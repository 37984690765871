import { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";
import GroupSelect from "../component/sidebar/group-select";
// import Pagination from "../component/sidebar/pagination";
import Rating from "../component/sidebar/rating";
import SkillSelect from "../component/sidebar/skill-select";
//
import placeholderImage from "../assets/images/placeholderImage.png";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { allCourses } from "../helpers/courses/courses";
import { getTutors } from "../helpers/instructor/instructor";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";
// Loader component
import Loader from "../component/Loader/BtnLoader";

const CoursePage = () => {
  const dispatch = useDispatch();
  const { discountAmount } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [courseLoading, setCourseLoading] = useState(false);
  const [data, setData] = useState([]);
  // pagination start variables
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 15;
  // end of pagination state variables

  // search params state variables
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [language, setLanguage] = useState("");
  // const [startPrice, setStartPrice] = useState(0);
  const [endPrice, setEndPrice] = useState(10000);

  const courses = useSelector((state) => state.course.courses);
  // const categories = useSelector((state) => state.category.categories);
  const instructors = useSelector((state) => state.instructor.instructors);
  console.log(instructors);

  // get all courses from database
  const getAllCourses = async () => {
    await allCourses(dispatch, setCourseLoading);
  };

  const getAllTutors = async () => {
    await getTutors(dispatch, setLoading);
  };
  useEffect(() => {
    getAllCourses();
    getAllTutors();
  }, []);

  // pagination logic
  useEffect(() => {
    setTotalPages(Math.ceil(courses.length / itemsPerPage));
  }, [courses]);

  const listOfCourses = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = courses.slice(startIndex, endIndex);
    setData(subset);
  };

  useEffect(() => {
    listOfCourses();
  }, [courses]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  // end of pagination logic

  // course category filter
  const filterItemByCategory = () => {
    setLoading(true);
    const updateItems = courses.filter((curElem) => {
      return curElem.category[0] === category;
    });
    setData(updateItems);
    setLoading(false);
  };

  // course language filter
  const filterItemByLanguage = () => {
    setLoading(true);
    const updateItems = courses.filter((curElem) => {
      return curElem.language === language;
    });
    setData(updateItems);
    setLoading(false);
  };

  // course price filter
  const filterItemByPrice = () => {
    setLoading(true);
    const updateItems = courses.filter((curElem) => {
      return curElem.price <= endPrice;
    });
    setData(updateItems);
    setLoading(false);
  };

  // course language filter
  const filterItemBySkills = () => {
    setLoading(true);
    const updateItems = courses.filter((curElem) => {
      return curElem.tags.includes(tags);
    });
    setData(updateItems);
    setLoading(false);
  };

  // category
  useEffect(() => {
    if (category) {
      filterItemByCategory();
    }
  }, [category]);

  // tags
  useEffect(() => {
    if (tags) {
      filterItemBySkills();
    }
  }, [tags]);

  // language
  useEffect(() => {
    if (language) {
      filterItemByLanguage();
    }
  }, [language]);

  // endprice
  useEffect(() => {
    if (endPrice) {
      filterItemByPrice();
    }
  }, [endPrice]);

  document.title = "Deonicode | Courses";
  return (
    <Fragment>
      <HeaderFour />
      <PageHeader title={"Our courses"} curPage={"Courses"} />
      <GroupSelect
        setCategory={setCategory}
        setEndPrice={setEndPrice}
        setTags={setTags}
        setLanguage={setLanguage}
      />
      <div className="course-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="course-showing-part">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="course-showing-part-left">
                  <p>
                    Showing 1-{data?.length} of {courses?.length} results
                  </p>
                </div>
                <div className="course-showing-part-right d-flex flex-wrap align-items-center">
                  <span>Sort by :</span>
                  <div className="select-item">
                    <SkillSelect select={"all"} setTags={setTags} />
                    <div className="select-icon">
                      <i className="icofont-rounded-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
              {courseLoading ? (
                <Loader size={40} color1={"black"} color2={"black"} />
              ) : data && data.length > 0 ? (
                data.map((val, i) => {
                  const instructor = instructors.filter((curElem) => {
                      
                    return curElem._id === val.instructorId;
                  })[0];
                  return (
                    <div className="col" key={i}>
                      <div className="course-item">
                        <div className="course-inner">
                          <div className="course-thumb">
                            <img
                              src={`${val.coverImage?.doc}`}
                              alt={`${val.title}`}
                            />
                          </div>
                          <div className="course-content">
                            <div className="course-price">
                              <div className="">
                                <div className="course-cate">
                                  <a>
                                    ${discountAmount
                                      ? val.price -
                                        (discountAmount * val.price) / 100
                                      : val.price}
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="course-category">
                              <div className="course-cate">
                                <a href={`/course/${val.category}`}>
                                  {val.category}
                                </a>
                              </div>
                              <div className="course-reiew">
                                <Rating value={val.rating} />
                                <span className="ratting-count">
                                  {val.reviewCount}
                                </span>
                              </div>
                            </div>
                            <Link to={`/course-single/${val._id}`}>
                              <h4>{val.title}</h4>
                            </Link>
                            <div className="course-details">
                              <div className="couse-count">
                                <i className="icofont-video-alt"></i>{" "}
                                {val.totalLeson}
                              </div>
                              <div className="couse-topic">
                                <i className="icofont-signal"></i> {val.schdule}
                                Online Class
                              </div>
                            </div>
                            <div className="course-footer">
                              <div className="course-author">
                                <img
                                  src={`${
                                    instructor?.avatar?.doc || placeholderImage
                                  }`}
                                  alt={`${val.authorImgAlt}`}
                                  width={30}
                                  className="rounded-circle"
                                />
                                <Link
                                  to={`/team-single/${instructor?._id}`}
                                  className="ca-name"
                                  style={{ 
                                    marginLeft:5
                                   }}
                                >
                                  {instructor?.email.includes("deonachuo") ? "Deon Achuo" : instructor?.username}
                                </Link>
                              </div>
                              <div className="course-btn">
                                <Link
                                  to={`/course-single/${val._id}`}
                                  className="lab-btn-text"
                                >
                                  View Course
                                  <i className="icofont-external-link"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                "No Course Found"
              )}
            </div>
            <ReactPaginate
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage}
              //
              breakLabel={"..."}
              previousLabel={<i className="icofont-simple-left"></i>}
              nextLabel={<i className="icofont-simple-right"></i>}
              containerClassName={"default-pagination lab-ul"}
            />
            {/* <Pagination /> */}
          </div>
        </div>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default CoursePage;
