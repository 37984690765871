import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allCourses } from "../../helpers/courses/courses";
import { getTutors } from "../../helpers/instructor/instructor";
import { allCategories } from "../../helpers/category/category";
import { getDiscount } from "../../helpers/discount/discount";
import { AuthContext } from "../../context/AuthContext";
import placeholderImage from "../../assets/images/placeholderImage.png";
import logo from "../../assets/images/logo/02.png";
import Rating from "../sidebar/rating";
import Loader from "../Loader/BtnLoader";
import Dropdown from "react-bootstrap/Dropdown";

const title = "Our Courses";

const CourseFour = () => {
  const { discountAmount } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const courses = useSelector((state) => state.course.courses);
  const categories = useSelector((state) => state.category.categories);
  const instructors = useSelector((state) => state.instructor.instructors);

  const getAllCategories = async () => {
    await allCategories(dispatch, setLoading);
  };

  const getAllCourses = async () => {
    await allCourses(dispatch, setLoading);
  };

  const getAllTutors = async () => {
    await getTutors(dispatch, setLoading);
  };

  const getCoursesDiscount = async () => {
    await getDiscount(dispatch, setLoading);
  };

  useEffect(() => {
    getCoursesDiscount();
    getAllCategories();
    getAllCourses();
    getAllTutors();
  }, []);

  useEffect(() => {
    setItems(courses);
  }, [courses]);

  const filterItem = (categItem) => {
    const updateItems = courses.filter((curElem) => {
      return curElem.category[0] === categItem;
    });
    setItems(updateItems);
  };

  return (
    <div className="course-section style-3 padding-tb">
      <div className="course-shape one">
        <img src="assets/images/shape-img/icon/01.png" alt="education" />
      </div>
      <div className="course-shape two">
        <img src="assets/images/shape-img/icon/02.png" alt="education" />
      </div>
      <div className="container">
        <div className="section-header">
          <h2 className="title">{title}</h2>
          <div className="course-filter-group">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="btn fw-bold"
                style={{
                  backgroundColor: "#E97F21",
                  color: "#fff",
                  border: "none",
                  borderRadius: 0,
                }}
              >
                Filter By Category
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setItems(courses)}>
                  All
                </Dropdown.Item>
                {categories && categories.length > 0 ? (
                  categories.map((item) => (
                    <Dropdown.Item
                      onClick={() => filterItem(item.title)}
                      key={item._id}
                    >
                      {item.title}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item>No Category Found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 course-filter">
            {loading ? (
              <Loader size={40} color1={"black"} color2={"black"} />
            ) : items.length > 0 ? (
              items.map((elem) => {
                const {
                  _id,
                  instructorId,
                  coverImage,
                  category,
                  title,
                  price,
                  rating,
                } = elem;
                const instructor = instructors.filter((curElem) => {
                  return curElem._id === instructorId;
                })[0];
                return (
                  <div className="col" key={_id}>
                    <div className="course-item style-4">
                      <div className="course-inner">
                        <div className="course-thumb">
                          <img
                            src={coverImage?.doc}
                            alt={title}
                            style={{ width: "100%", height: 200 }}
                          />
                          <div className="course-category">
                            <div className="course-cate">
                              <a href={`/course/${category}`}>{category}</a>
                            </div>
                            <div className="course-reiew">
                              <Rating value={rating} color={"black"} />
                            </div>
                          </div>
                        </div>
                        <div className="course-content">
                          <Link to={`/course-single/${_id}`}>
                            <h5>{title}</h5>
                          </Link>
                          <div className="course-footer">
                            <div className="course-author">
                              <img
                                src={
                                  instructor
                                    ? instructor?.avatar?.doc ||
                                      placeholderImage
                                    : logo
                                }
                                alt={instructor?.username}
                                width={30}
                                className="rounded-circle"
                              />
                              {instructor?.username ? (
                                <span
                                  className="ca-name"
                                >
                                  {instructor?.username}
                                </span>
                              ) : (
                                <span className="ca-name">Deon Achuo</span>
                              )}
                            </div>
                            <div className="course-price">
                              ${" "}
                              {discountAmount
                                ? price - (discountAmount * price) / 100
                                : price}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              "No Course Found"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseFour;
