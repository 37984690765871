import React, { useState, useContext, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { getSingleUser } from "../../helpers/authentication/auth";
import { useSelector, useDispatch } from "react-redux";
import placeholderImage from "../../assets/images/placeholderImage.png";
import { postQuestion } from "../../helpers/question/question";
import Loader from "../Loader/BtnLoader";

const AskQuestion = ({ course }) => {
  const dispatch = useDispatch();
  const { userId } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [user, setUser] = useState(null);
  const userDetails = useSelector((state) => state.auth.userDetails);

  const handleAskQuestion = async () => {
    if (course && userId && question) {
      const data = {
        senderId: userId,
        receiverId: course.instructorId,
        courseId: course._id,
        message: JSON.stringify(question),
      };
      const response = await postQuestion(data, dispatch, setLoading);
      if (response.status === "error") {
        return toast.error(response.message);
      }
      if (response.status === "success") {
        return toast.success(response.message);
      }
    } else {
      toast.error("Question cannot be blank");
    }
  };

  const getUser = async () => {
    const data = {
      id: userId,
    };
    await getSingleUser(data, dispatch, setLoading);
  };

  useEffect(() => {
    if (userId !== "") {
      getUser();
    }
  }, [userId]);

  useEffect(() => {
    if (userDetails !== null) {
      setUser(userDetails);
    }
  }, [userDetails]);

  return (
    <div className="student-reviwe d-flex flex-wrap">
      <div className="student-thumb">
        <img src={user?.avatar?.doc || placeholderImage} alt="annaunce" />
      </div>
      <div className="student-content">
        <p className="text-dark mb-2">Ask a new question</p>
        <div className="text-dark border border-secondary rounded p-2">
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onChange={(e) => setQuestion(e)}
          />
        </div>
        <div>
          <button
            className="lab-btn mt-2 text-white"
            onClick={handleAskQuestion}
          >
            {loading ? (
              <Loader size={30} color1={"white"} color2={"white"} />
            ) : (
              "Send"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AskQuestion;
