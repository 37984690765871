import axios from "axios";

const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/settings`;

const discounts = async () => {
  const response = await axios.get(`${API_URL}/discount`);
  if (response) {
    localStorage.setItem(
      "deonicode-discount-token",
      JSON.stringify(response.data)
    );
  }
  return response.data;
};

const transactionService = {
  discounts,
};

export default transactionService;
