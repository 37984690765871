import { Fragment } from "react";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import CategoryTwo from "../component/section/category-2";
import CourseFour from "../component/section/course-4";
import AboutTwo from "../component/section/about-2";
import NewsLetter from "../component/section/newsletter";
import FeatureTwo from "../component/section/feature-2";
import BannerTwo from "../component/section/banner-2";

const HomeFour = () => {
  document.title = "Deonicode | Creative space to boost your IT career!";
  return (
    <Fragment>
      <HeaderFour />
      <BannerTwo />
      <AboutTwo />
      <CategoryTwo />
      <CourseFour />
      <FeatureTwo />
      <NewsLetter />
      <FooterTwo />
    </Fragment>
  );
};

export default HomeFour;
