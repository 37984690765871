import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import transactionService from "../services/transactionServices";

const initialState = {
  transactions: [],
  transaction: null,
};

export const purchaseCourse = createAsyncThunk(
  "transaction/purchaseCourse",
  async (data, thunkAPI) => {
    try {
      return await transactionService.purchaseCourse(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const newTransaction = createAsyncThunk(
  "transaction/newTransaction",
  async (data, thunkAPI) => {
    try {
      return await transactionService.newTransaction(data);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCurrencyConversion = createAsyncThunk(
  "transaction/getCurrencyConversion",
  async (thunkAPI) => {
    try {
      return await transactionService.getCurrencyConversion();
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    reset: (state) => { },
  },
  extraReducers: (builder) => {
    builder.addCase(purchaseCourse.fulfilled, (state, action) => {
      state.transaction = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = transactionSlice.actions;

export default transactionSlice.reducer;
