import { register, tutors, tutor } from "../../redux/reducers/tutorReducer";

export const registerTutor = (data, dispatch, setLoading) => {
  const response = dispatch(register(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error registering tutor", err);
    });
  return response;
};

export const getTutors = (dispatch, setLoading) => {
  const response = dispatch(tutors(), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload.message,
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error getting tutor", err);
    });
  return response;
};

export const getTutor = (data, dispatch, setLoading) => {
  const response = dispatch(tutor(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return {
          status: "success",
          message: res.payload.message,
          payload: res.payload,
        };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error getting tutor", err);
    });
  return response;
};
