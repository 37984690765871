import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";

// redux import
import { useDispatch } from "react-redux";
import { registerUser } from "../helpers/authentication/auth";
import { toast } from "react-toastify";
import Loader from "../component/Loader/BtnLoader";

const title = "Register Now";
const btnText = "Get Started Now";

const SignupPage = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    if (username && email && password && confirmPassword) {
      if (password !== confirmPassword) {
        toast.error("passwords do not match");
        return;
      }
      const data = {
        username,
        email,
        password,
      };
      const response = await registerUser(data, dispatch, setLoading);
      if (response.status === "success") {
        navigate("/");
        return toast.success(response.message);
      } else {
        return toast.error(response.message);
      }
    } else {
      toast.error("All fields are required");
      return;
    }
  };

  document.title = "Deonicode | Registration";
  return (
    <Fragment>
      <HeaderFour />
      <PageHeader title={"Register Now"} curPage={"Sign Up"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="User Name"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <button className="lab-btn" onClick={handleSignup}>
                  <span>
                    {loading ? (
                      <Loader size={30} color1={"white"} color2={"white"} />
                    ) : (
                      btnText
                    )}
                  </span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Are you a member? <Link to="/login">Login</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default SignupPage;
