/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";

// redux import
import { useDispatch, useSelector } from "react-redux";
import { registerTutor } from "../helpers/instructor/instructor";
import { allCategories } from "../helpers/category/category";
import { toast } from "react-toastify";
import Loader from "../component/Loader/BtnLoader";

const title = "Apply Now";
const btnText = "Submit Application";

const TutorSignupPage = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [occupation, setOccupation] = useState("");
  const [education, setEducation] = useState("");
  const [category, setCategory] = useState("");
  const [resume, setResume] = useState("");
  const [password, setPassword] = useState("");
  const categories = useSelector((state) => state.category.categories);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllCategories = async () => {
    await allCategories(dispatch, setLoading);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (
      username &&
      email &&
      number &&
      occupation &&
      education &&
      category &&
      resume &&
      password
    ) {
      let data = new FormData();
      data.append("username", username);
      data.append("email", email);
      data.append("phoneNumber", number);
      data.append("occupation", occupation);
      data.append("educationLevel", education);
      data.append("targetSubject", category);
      data.append("resume", resume);
      data.append("password", password);
      const response = await registerTutor(data, dispatch, setLoading);
      if (response.status === "success") {
        navigate("/");
        return toast.success(response.message);
      } else {
        return toast.error(response.message);
      }
    } else {
      toast.error("All fields are required");
      return;
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  document.title = "Deonicode | Become a Tutor";
  return (
    <Fragment>
      <HeaderFour />
      <PageHeader
        title={"Apply To Become a Tutor"}
        curPage={"Tutor Application Form"}
      />

      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Full name"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="number"
                  name="number"
                  placeholder="Phone number"
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="occupation"
                  placeholder="Occupation"
                  onChange={(e) => setOccupation(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="education"
                  placeholder="Level of Education"
                  onChange={(e) => setEducation(e.target.value)}
                />
              </div>
              <div className="form-group">
                <select
                  name="category"
                  id="catgory"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option>Select Category</option>
                  {categories && categories.length > 0 ? (
                    categories.map((item) => (
                      <option value={item.title} key={item._id}>
                        {item.title}
                      </option>
                    ))
                  ) : (
                    <option>Loading categories...</option>
                  )}
                </select>
              </div>

              <label className="d-flex flex-start">Resume</label>
              <div className="form-group">
                <input
                  type="file"
                  accept="application/pdf,application/vnd.ms-excel"
                  name="resume"
                  placeholder="resume"
                  onChange={(e) => setResume(e.target.files[0])}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Prefered password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <button className="lab-btn" onClick={handleSignup}>
                  <span>
                    {loading ? (
                      <Loader size={30} color1={"white"} color2={"white"} />
                    ) : (
                      btnText
                    )}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default TutorSignupPage;
