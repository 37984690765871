import {
  register,
  login,
  update,
  logout,
  getUser,
  getAllUsers,
  resetPassword,
  updatePassword,
  forgotPassword,
  uploadProfileImage,
  makeWithdrawRequest,
  generateAffiliateLink
} from "../../redux/reducers/authReducer";

export const registerUser = (data, dispatch, setLoading) => {
  const response = dispatch(register(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error registering user", err);
    });
  return response;
};

export const loginUser = (data, dispatch, setLoading) => {
  const response = dispatch(login(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error loging in user", err);
    });
  return response;
};

export const getSingleUser = (data, dispatch, setLoading) => {
  const response = dispatch(getUser(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error getting user", err);
    });
  return response;
};

export const allUsers = (dispatch, setLoading) => {
  const response = dispatch(getAllUsers(), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error getting user", err);
    });
  return response;
};

export const uploadImage = (data, dispatch, setLoading) => {
  const response = dispatch(uploadProfileImage(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error updating user", err);
    });
  return response;
};

export const updateUser = (data, dispatch, setLoading) => {
  const response = dispatch(update(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error updating user", err);
    });
  return response;
};

export const updateUserPassword = (data, dispatch, setLoading) => {
  const response = dispatch(updatePassword(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error updating user", err);
    });
  return response;
};

export const forgotUserPassword = (data, dispatch, setLoading) => {
  const response = dispatch(forgotPassword(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error updating user", err);
    });
  return response;
};

export const resetUserPassword = (data, dispatch, setLoading) => {
  const response = dispatch(resetPassword(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error updating user", err);
    });
  return response;
};

export const logoutUser = (dispatch, setLoading) => {
  const response = dispatch(logout(), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success" };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error" };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error loging out user", err);
    });
  return response;
};

export const getAffiliateLink = (data, dispatch, setLoading) => {
  const response = dispatch(generateAffiliateLink(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message, res: res.payload };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error registering user", err);
    });
  return response;
};

export const withdrawRequest = (data, dispatch, setLoading) => {
  const response = dispatch(makeWithdrawRequest(data), setLoading(true))
    .then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        return { status: "success", message: res.payload.message, res: res.payload };
      }
      if (res.meta.requestStatus === "rejected") {
        setLoading(false);
        return { status: "error", message: res.payload };
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error("error registering user", err);
    });
  return response;
};
