import axios from "axios";

const API_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/user`;
const WITHDRAW_URL = `${process.env.REACT_APP_ENDPOINT}/api/${process.env.REACT_APP_API_VERSION}/withdrawals`;
const userToken = localStorage.getItem("deonicode-user");

const register = async (data) => {
  const response = await axios.post(`${API_URL}/register`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  if (response.data) {
    localStorage.setItem("deonicode-user", JSON.stringify(response.data.token));
  }
  return response.data;
};

const login = async (data) => {
  const response = await axios.post(`${API_URL}/login`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  if (response.data) {
    localStorage.setItem("deonicode-user", JSON.stringify(response.data.token));
  }
  return response.data;
};

const getUser = async (data) => {
  const response = await axios.get(`${API_URL}/user/${data.id}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return response.data;
};

const getAllUsers = async () => {
  const response = await axios.get(`${API_URL}/users`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  return response.data;
};

const uploadProfileImage = async (data) => {
  const response = await axios.put(
    `${API_URL}/upload-profile-image/${data.id}`,
    data.formData,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const update = async (data) => {
  const response = await axios.put(`${API_URL}/update-user/${data.id}`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  if (response.data) {
    localStorage.setItem("deonicode-user", JSON.stringify(response.data.token));
  }
  return response.data;
};

const updatePassword = async (data) => {
  const response = await axios.put(
    `${API_URL}/update-password/${data.id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

const forgotPassword = async (data) => {
  const response = await axios.post(`${API_URL}/forgot-password`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const resetPassword = async (data) => {
  const response = await axios.put(`${API_URL}/new-password/${data.id}`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });

  if (response.data) {
    localStorage.setItem("deonicode-user", JSON.stringify(response.data.token));
  }
  return response.data;
};

const logout = async () => {
  return await localStorage.removeItem("deonicode-user");
};

const generateAffiliateLink = async (data) => {
  const response = await axios.post(`${API_URL}/referral-link`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const makeWithdrawRequest = async (data) => {
  const response = await axios.post(`${WITHDRAW_URL}/withdrawal-request`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const authServices = {
  generateAffiliateLink,
  makeWithdrawRequest,
  uploadProfileImage,
  updatePassword,
  forgotPassword,
  resetPassword,
  register,
  getAllUsers,
  getUser,
  login,
  update,
  logout,
};

export default authServices;
