import React, { Fragment, useState, useContext, useEffect } from "react";
import FooterTwo from "../component/layout/footer-2";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { AuthContext } from "../context/AuthContext";
import { logoutUser } from "../helpers/authentication/auth";
import {
  singleCourse,
  certRegistration,
  getSingleCert,
} from "../helpers/courses/courses";
import { toast } from "react-toastify";
import Loader from "../component/Loader/BtnLoader";
import GenericPdfDownloader from "../component/download/Certificate";
import { v4 as uuid } from "uuid";

function Certificate() {
  const { courseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState(null);
  const [certificateId, setCertificateId] = useState("");
  const [certIssuedDate, setCertIssuedDate] = useState("");
  const dispatch = useDispatch();
  const { userId, username } = useContext(AuthContext);
  const courseState = useSelector((state) => state.course.course);

  function formatDate(date) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    return formattedDate.replace(/(\d+)(st|nd|rd|th)/, "`1 <sup>`2");
  }

  const getCourse = async () => {
    const data = {
      id: courseId,
    };
    await singleCourse(data, dispatch, setLoading);
  };

  const registerCertificate = async () => {
    let certId = uuid();
    let issuedDate = formatDate(new Date());
    setCertificateId(certId);
    setCertIssuedDate(issuedDate);
    const data = {
      userId,
      courseId,
      certId,
      issued: issuedDate,
    };
    await certRegistration(data, dispatch, setLoading);
  };

  const getCertificate = async () => {
    const data = {
      userId,
      courseId,
    };
    const response = await getSingleCert(data, dispatch, setLoading);

    let payload = response.message.payload;
    if (payload.length > 0) {
      setCertificateId(payload[0].certId);
      setCertIssuedDate(payload[0].issued);
    }
  };

  useEffect(() => {
    getCourse();
  }, [courseId]);

  useEffect(() => {
    registerCertificate();
    getCertificate();
  }, [courseId, userId]);

  useEffect(() => {
    setCourse(courseState);
  }, [courseState]);

  return (
    <Fragment>
      <Header />
      {loading ? (
        <Loader size={40} color1={"black"} color2={"black"} />
      ) : (
        <>
          <div className="py-2 section-bg">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="course-view  px-4">
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-12">
                        <div
                          id="certificate"
                          style={{
                            width: "1200px",
                            margin: "auto",
                            background: "white",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                        >
                          <div
                            style={{
                              background: "#fd7e14",
                              padding: "5px",
                            }}
                          ></div>
                          <div
                            style={{ textAlign: "center", marginTop: "40px" }}
                          >
                            <img
                              style={{ width: "20%" }}
                              src="/assets/images/logo/01.png"
                              alt="logo"
                            />
                          </div>
                          <div>
                            <h1
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "55px",
                                marginTop: "30px",
                                color: "rgb(78,78,78)",
                              }}
                            >
                              CERTIFICATE OF COMPLETION
                            </h1>
                            <h4
                              style={{
                                textAlign: "center",
                                margin: "20px",
                                fontSize: "30px",
                                marginTop: "40px",
                                color: "rgb(78,78,78)",
                              }}
                            >
                              Congratulations, {username}
                            </h4>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "22px",
                                width: "65%",
                                margin: "auto",
                                marginTop: "40px",
                                marginBottom: "40px",
                                lineHeight: "35px",
                                color: "rgb(78,78,78)",
                              }}
                            >
                              By continuing to learn, you have expanded your
                              perspective, sharpened your skills, and made
                              yourself even more in demand.
                            </p>
                            <h3
                              style={{
                                background: "#fd7e14",
                                color: "white",
                                textAlign: "center",
                                padding: "30px",
                                fontSize: "35px",
                              }}
                            >
                              {course?.title}
                            </h3>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "40px",
                                padding: "40px",
                              }}
                            >
                              <div>
                                <img src="/assets/signature.png" width={150} alt="" />
                                <hr />
                                <h5>Founder & CEO</h5>
                                <p>Engr Deon Achuo</p>
                              </div>
                              {/* <div>
                                <hr />
                                <h5>Director, Training & Certification</h5>
                                <p>Engr Tabe Denis Deon</p>
                              </div> */}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "20px",
                              padding: "20px 40px",
                            }}
                          >
                            <div>
                              <p>Course completed on {certIssuedDate}</p>
                            </div>
                            <div>
                              <p>Certificate ID: {certificateId}</p>
                            </div>
                          </div>
                          <div
                            style={{
                              background: "#fd7e14",
                              padding: "5px",
                            }}
                          ></div>
                        </div>
                        <div className="text-center">
                          <GenericPdfDownloader
                            downloadFileName="Deonicode_Certification"
                            rootElementId="certificate"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <FooterTwo />
    </Fragment>
  );
}

const Header = () => {
  const [loading, setLoading] = useState(false);
  const { setAuthenticated } = useContext(AuthContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await logoutUser(dispatch, setLoading);
    if (response.status === "success") {
      setAuthenticated(false);
      return navigate("/");
    } else {
      return toast.error("error loging out");
    }
  };
  return (
    <div className="header">
      <div className="container">
        <div className="header-content">
          <div className="logo">
            <Link to="/">
              <img src="/assets/images/logo/01.png" alt="logo" />
            </Link>
          </div>
          <div className="menues">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="btn fw-bold"
                style={{
                  backgroundColor: "#E97F21",
                  color: "#fff",
                  border: "none",
                  borderRadius: 0,
                }}
              >
                <i className="icofont-justify-all"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item className="p-0">
                  <Link to="/dashboard" className="w-100 px-2 py-1">
                    Dashboard
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="p-0">
                  <Link to="/profile" className="w-100 px-2 py-1">
                    Profile
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="p-0" onClick={handleLogout}>
                  <Link to="#" className="w-100 px-2 py-1 text-danger">
                    Log out
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Certificate;
