import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { newRating } from "../../helpers/ratings/rating";
import Loader from "../Loader/BtnLoader";

const title = "Leave a Comment";
const btnText = "send comment";

const radioStyles = {
  padding: 10,
  border: "1px solid black",
  borderRadius: 5,
  cursor: "pointer",
  fontWeight: "bold",
  margin: 10,
};

const radioActiveStyles = {
  padding: 10,
  border: "1px solid #FF5733",
  borderRadius: 5,
  cursor: "pointer",
  fontWeight: "bold",
  backgroundColor: "#FF5733",
  color: "white",
};

const Respond = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userId, authenticated } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const handleNewComment = async (e) => {
    e.preventDefault();
    if (authenticated) {
      if (rating && comment) {
        const data = {
          courseId: id,
          userId,
          rating,
          comment,
        };
        const response = await newRating(data, dispatch, setLoading);
        if (response.status === "error") {
          toast.error(response.message);
          return;
        }
        if (response.status === "success") {
          toast.success(response.message);
          return;
        }
      } else {
        toast.error("All fields are required");
      }
    } else {
      toast.error("Please login to comment");
    }
  };

  return (
    <div id="respond" className="comment-respond mb-lg-0">
      <h4 className="title-border">{title}</h4>
      <div className="add-comment">
        <form
          action="#"
          method="post"
          id="commentform"
          className="comment-form"
          onSubmit={handleNewComment}
        >
          {/* <input type="text" name="name" placeholder="Your Name *" />
          <input type="text" name="email" placeholder="Your email *" />
          <input
            type="text"
            name="subject"
            className="w-100"
            placeholder="Write a Subject"
          /> */}
          <div className="mb-4">
            <span
              style={rating === 1 ? radioActiveStyles : radioStyles}
              onClick={() => setRating(1)}
            >
              01
            </span>
            <span
              style={rating === 2 ? radioActiveStyles : radioStyles}
              onClick={() => setRating(2)}
            >
              02
            </span>
            <span
              style={rating === 3 ? radioActiveStyles : radioStyles}
              onClick={() => setRating(3)}
            >
              03
            </span>
            <span
              style={rating === 4 ? radioActiveStyles : radioStyles}
              onClick={() => setRating(4)}
            >
              04
            </span>
            <span
              style={rating === 5 ? radioActiveStyles : radioStyles}
              onClick={() => setRating(5)}
            >
              05
            </span>
          </div>
          <textarea
            rows="7"
            type="text"
            name="message"
            placeholder="Your Comment"
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <button type="submit" className="lab-btn">
            <span>
              {loading ? (
                <Loader size={30} color1={"white"} color2={"white"} />
              ) : (
                btnText
              )}
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Respond;
