import { Fragment } from "react";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";
import FooterTwo from "../component/layout/footer-2";

const PrivacyPlocy = () => {
  return (
    <Fragment>
      <HeaderFour />
      <PageHeader title={"Privacy Policy"} curPage={"Privacy Policy"} />
      <div className="container padding-tb text-start px-2">
        <p>
          <i>This Privacy Policy was last updated on August 27, 2023.</i>
        </p>
        <p>
          Thank you for joining Deonicode. We at Deonicode (“Deonicode”, “we”,
          “us”) respect your privacy and want you to understand how we collect,
          use, and share data about you. This Privacy Policy covers our data
          collection practices and describes your rights regarding your personal
          data.
        </p>
        <p>
          Unless we link to a different policy or state otherwise, this Privacy
          Policy applies when you visit or use Deonicode websites, mobile
          applications, APIs, or related services (the “Services”). It also
          applies to prospective customers of our business and enterprise
          products.
        </p>
        <p>
          By using the Services, you agree to the terms of this Privacy Policy.
          You shouldn’t use the Services if you don’t agree with this Privacy
          Policy or any other agreement that governs your use of the Services.
        </p>
        <h5>Table of Contents</h5>
        <ul>
          <li>1. What Data We Get</li>
          <li>2. How We Get Data About You</li>
          <li>3. What We Use Your Data For</li>
          <li>4. Who We Share Your Data With</li>
          <li>5. Security</li>
          <li>6. Your Rights</li>
          <li>7. Jurisdiction-Specific Rules</li>
          <li>8. Updates & Contact Info</li>
        </ul>
        <h5>1. What Data We Get</h5>
        <p>
          We collect certain data from you directly, like information you enter
          yourself, data about your consumption of content, and data from
          third-party platforms you connect with Deonicode. We also collect some
          data automatically, like information about your device and what parts
          of our Services you interact with or spend time using. All data listed
          in this section is subject to the following processing activities:
          collecting, recording, structuring, storing, altering, retrieving,
          encrypting, pseudonymizing, erasing, combining, and transmitting.
        </p>
        <strong>1.1 Data You Provide to Us</strong>
        <p>
          We may collect different data from or about you depending on how you
          use the Services. Below are some examples to help you better
          understand the data we collect. When you create an account and use the
          Services, including through a third-party platform, we collect any
          data you provide directly, including:
        </p>
        <table
          className="table align-middle table-nowrap mb-0"
          style={{ width: "100%", overflowX: "scroll" }}
        >
          <thead>
            <tr>
              <th className="align-middle">Category of Personal Data</th>
              <th className="align-middle">Description</th>
              <th className="align-middle">Legal Basis for Processing</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Account Data</td>
              <td>
                In order to use certain features (like accessing content), you
                need to create a user account, which requires us to collect and
                store your email address, password, and account settings. To
                create an instructor account, we collect and store your name,
                email address, password, and account settings. As you use
                certain features on the site, you may be prompted to submit
                additional information including occupation, government ID
                information, verification photo, date of birth, race/ethnicity,
                skill interests, and phone number. Upon account creation, we
                assign you a unique identifying number.
              </td>
              <td>
                <ul>
                  <li>Performance of contract</li>
                  <li>
                    Legitimate interests (service provisioning, identity
                    verification, fraud prevention and security, communication)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Profile Data</td>
              <td>
                You can also choose to provide profile information like a photo,
                headline, biography, language, website link, social media
                profiles, country, or other data. Your Profile Data will be
                publicly viewable by others.
              </td>
              <td>
                <ul>
                  <li>Performance of contract</li>
                  <li>
                    Legitimate interests (enhanced platform functionality,
                    convey content source information)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Shared Content</td>
              <td>
                Parts of the Services let you interact with other users or share
                content publicly, including by uploading courses and other
                educational content, posting reviews about content, asking or
                answering questions, sending messages to students or
                instructors, or posting photos or other work you upload. Such
                shared content may be publicly viewable by others depending on
                where it is posted.
              </td>
              <td>
                <ul>
                  <li>Performance of contract</li>
                  <li>
                    Legitimate interests (service provisioning, enhanced
                    platform functionality)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Learning Data</td>
              <td>
                When you access content, we collect certain data including which
                courses, assignments, labs, workspaces, and quizzes you’ve
                started and completed; content and subscription purchases and
                credits; subscriptions; completion certificates; your exchanges
                with instructors, teaching assistants, and other students; and
                essays, answers to questions, and other items submitted to
                satisfy course and related content requirements.
              </td>
              <td>
                <ul>
                  <li>Performance of contract</li>
                  <li>
                    Legitimate interests (service provisioning, enhanced
                    platform functionality)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Student Payment Data</td>
              <td>
                If you make purchases, we collect certain data about your
                purchase (such as your name, billing address, and ZIP code) as
                necessary to process your order and which may optionally be
                saved to process future orders. You must provide certain payment
                and billing data directly to our payment service providers,
                including your name, credit card information, billing address,
                and ZIP code. We may also receive limited information, like the
                fact that you have a new card and the last four digits of that
                card, from payment service providers to facilitate payments. For
                security, Deonicode does not collect or store sensitive
                cardholder data, such as full credit card numbers or card
                authentication data.
              </td>
              <td>
                <ul>
                  <li>Performance of contract</li>
                  <li>Legal obligation</li>
                  <li>
                    Legitimate interests (payment facilitation, fraud prevention
                    and security, compliance)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Instructor Payment Data</td>
              <td>
                If you are an instructor, you can link your PayPal, Payoneer, or
                other payment account to the Services to receive payments. When
                you link a payment account, we collect and use certain
                information, including your payment account email address,
                account ID, physical address, or other data necessary for us to
                send payments to your account. In some instances, we may collect
                ACH or wire information to send payments to your account. In
                order to comply with applicable laws, we also work with trusted
                third parties who collect tax information as legally required.
                This tax information may include residency information, tax
                identification numbers, biographical information, and other
                personal information necessary for taxation purposes. For
                security, Deonicode does not collect or store sensitive bank
                account information. The collection, use, and disclosure of your
                payment, billing, and taxation data is subject to the privacy
                policy and other terms of your payment account provider.
              </td>
              <td>
                <ul>
                  <li>Performance of contract</li>
                  <li>Legal obligation</li>
                  <li>
                    Legitimate interests (payment facilitation, fraud prevention
                    and security, compliance)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Data About Your Accounts on Other Services</td>
              <td>
                <p>
                  We may obtain certain information through your social media or
                  other online accounts if they are connected to your Deonicode
                  account. If you log in to Deonicode via Facebook or another
                  third-party platform or service, we ask for your permission to
                  access certain information about that other account. For
                  example, depending on the platform or service we may collect
                  your name, profile picture, account ID number, login email
                  address, location, physical location of your access devices,
                  gender, birthday, and list of friends or contacts.
                </p>
                <p>
                  Those platforms and services make information available to us
                  through their APIs. The information we receive depends on what
                  information you (via your privacy settings) or the platform or
                  service decide to give us.
                </p>
                <p>
                  If you access or use our Services through a third-party
                  platform or service, or click on any third-party links, the
                  collection, use, and sharing of your data will also be subject
                  to the privacy policies and other agreements of that third
                  party.
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    Legitimate interests (identity verification, user experience
                    improvement)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Sweepstakes, Promotions, and Surveys</td>
              <td>
                <p>
                  We may invite you to complete a survey or participate in a
                  promotion (like a contest, sweepstakes, or challenge), either
                  through the Services or a third-party platform. If you
                  participate, we will collect and store the data you provide as
                  part of participating, such as your name, email address,
                  postal address, date of birth, or phone number. That data is
                  subject to this Privacy Policy unless otherwise stated in the
                  official rules of the promotion or in another privacy policy.
                  The data collected will be used to administer the promotion or
                  survey, including for notifying winners and distributing
                  rewards. To receive a reward, you may be required to allow us
                  to post some of your information publicly (like on a winner’s
                  page). Where we use a third-party platform to administer a
                  survey or promotion, the third party’s privacy policy will
                  apply.
                </p>
              </td>
              <td>
                <ul>
                  <li>Performance of contract</li>
                  <li>
                    Legitimate interests (promotions administration, prize
                    delivery, compliance)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Communications and Support</td>
              <td>
                <p>
                  If you contact us for support or to report a problem or
                  concern (regardless of whether you have created an account),
                  we collect and store your contact information, messages, and
                  other data about you like your name, email address, messages,
                  location, Deonicode user ID, refund transaction IDs, and any
                  other data you provide or that we collect through automated
                  means (which we cover below). We use this data to respond to
                  you and research your question or concern, in accordance with
                  this Privacy Policy.
                </p>
              </td>
              <td>
                <ul>
                  <li>Legitimate interests (customer and technical support)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          The data listed above is stored by us and associated with your
          account.
        </p>
        <strong>1.2 Data We Collect through Automated Means</strong>
        <p>
          When you access the Services (including browsing content), we collect
          certain data by automated means, including:
        </p>
        <table className="table align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th className="align-middle">Category of Personal Data</th>
              <th className="align-middle">Description</th>
              <th className="align-middle">Legal Basis for Processing</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>System Data</td>
              <td>
                Technical data about your computer or device, like your IP
                address, device type, operating system type and version, unique
                device identifiers, browser, browser language, domain and other
                systems data, and platform types.
              </td>
              <td>
                <ul>
                  <li>Performance of contract</li>
                  <li>
                    Legitimate interests (service provisioning, customer and
                    technical support, fraud prevention and security,
                    communication, product improvement)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Usage Data</td>
              <td>
                Usage statistics about your interactions with the Services,
                including content accessed, time spent on pages or the Service,
                pages visited, features used, your search queries, click data,
                date and time, referrer, and other data regarding your use of
                the Services.
              </td>
              <td>
                <ul>
                  <li>
                    Legitimate interests (service provisioning, user experience
                    improvement, product improvement)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Approximate Geographic Data</td>
              <td>
                An approximate geographic location, including information like
                country, city, and geographic coordinates, calculated based on
                your IP address.
              </td>
              <td>
                <ul>
                  <li>
                    Legitimate interests (user experience improvement, fraud
                    prevention and security, compliance)
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          The data listed above is collected through the use of server log files
          and tracking technologies, as detailed in the “Cookies and Data
          Collection Tools” section below. It is stored by us and associated
          with your account.
        </p>
        <strong>1.3 Data From Third Parties</strong>
        <p>
          If you are a Deonicode Business enterprise or corporate prospect, in
          addition to information you submit to us, we may collect certain
          business contact information from third-party commercial sources.
        </p>
        <h5>2. How We Get Data About You</h5>
        <p>
          We use tools like cookies, web beacons, and similar tracking
          technologies to gather the data listed above. Some of these tools
          offer you the ability to opt out of data collection.
        </p>
        <strong>2.1 Cookies and Data Collection Tools</strong>
        <p>
          We use cookies, which are small text files stored by your browser, to
          collect, store, and share data about your activities across websites,
          including on Deonicode. They allow us to remember things about your
          visits to Deonicode, like your preferred language, and to make the
          site easier to use. To learn more about cookies, visit
          https://cookiepedia.co.uk/all-about-cookies. We may also use clear
          pixels in emails to track deliverability and open rates.
        </p>
        <p>
          Deonicode and service providers acting on our behalf (like Google
          Analytics and third-party advertisers) use server log files and
          automated data collection tools like cookies, tags, scripts,
          customized links, device or browser fingerprints, and web beacons
          (together, “Data Collection Tools“) when you access and use the
          Services. These Data Collection Tools automatically track and collect
          certain System Data and Usage Data (as detailed in Section 1) when you
          use the Services. In some cases, we tie data gathered through those
          Data Collection Tools to other data that we collect as described in
          this Privacy Policy
        </p>
        <strong>2.2 Why We Use Data Collection Tools</strong>
        <p>
          Deonicode uses the following types of Data Collection Tools for the
          purposes described:
        </p>
        <ul>
          <li>
            <u>Strictly Necessary</u>: These Data Collection Tools enable you to
            access the site, provide basic functionality (like logging in or
            accessing content), secure the site, protect against fraudulent
            logins, and detect and prevent abuse or unauthorized use of your
            account. These are required for the Services to work properly, so if
            you disable them, parts of the site will break or be unavailable.
          </li>
          <li>
            <u>Functional</u>: These Data Collection Tools remember data about
            your browser and your preferences, provide additional site
            functionality, customize content to be more relevant to you, and
            remember settings affecting the appearance and behavior of the
            Services (like your preferred language or volume level for video
            playback).
          </li>
          <li>
            <u>Performance</u>: These Data Collection Tools help measure and
            improve the Services by providing usage and performance data, visit
            counts, traffic sources, or where an application was downloaded
            from. These tools can help us test different versions of Deonicode
            to see which features or content users prefer and determine which
            email messages are opened.
          </li>
          <li>
            <u>Advertising</u>: These Data Collection Tools are used to deliver
            relevant ads (on the site and/or other sites) based on things we
            know about you like your Usage and System Data (as detailed in
            Section 1), and things that the ad service providers know about you
            based on their tracking data. The ads can be based on your recent
            activity or activity over time and across other sites and services.
            To help deliver tailored advertising, we may provide these service
            providers with a hashed, anonymized version of your email address
            (in a non-human-readable form) and content that you share publicly
            on the Services.
          </li>
          <li>
            <u>Social Media</u>: These Data Collection Tools enable social media
            functionality, like sharing content with friends and networks. These
            cookies may track a user or device across other sites and build a
            profile of user interests for targeted advertising purposes.
          </li>
        </ul>
        <p>
          You can set your web browser to alert you about attempts to place
          cookies on your computer, limit the types of cookies you allow, or
          refuse cookies altogether. If you do, you may not be able to use some
          or all features of the Services, and your experience may be different
          or less functional. To learn more about managing Data Collection
          Tools, refer to Section 6.1 (Your Choices About the Use of Your Data)
          below.
        </p>
        <h5>3. What We Use Your Data For</h5>
        <p>
          We use your data to do things like provide our Services, communicate
          with you, troubleshoot issues, secure against fraud and abuse, improve
          and update our Services, analyze how people use our Services, serve
          personalized advertising, and as required by law or necessary for
          safety and integrity. We retain your data for as long as it is needed
          to serve the purposes for which it was collected.
        </p>
        <p>We use the data we collect through your use of the Services to:</p>
        <ul>
          <li>
            Provide and administer the Services, including to facilitate
            participation in educational content, issue completion certificates,
            display customized content, and facilitate communication with other
            users (Account Data; Shared Content; Learning Data; System Data;
            Usage Data; Approximate Geographic Data);
          </li>
          <li>
            Process payments to instructors and other third parties (Student
            Payment Data; Instructor Payment Data);
          </li>
          <li>
            Process your requests and orders for educational content, products,
            specific services, information, or features (Account Data; Learning
            Data; Student Payment Data; System Data; Communications and
            Support);
          </li>
          <li>
            Communicate with you about your account by (Account Data; Shared
            Content; Learning Data; Sweepstakes, Promotions, and Surveys; System
            Data; Communications and Support):
          </li>
          <ul>
            <li>Responding to your questions and concerns;</li>
            <li>
              Sending you administrative messages and information, including
              messages from instructors, students, and teaching assistants;
              notifications about changes to our Service; and updates to our
              agreements;
            </li>
            <li>
              Sending you information, such as by email or text messages, about
              your progress in courses and related content, rewards programs,
              new services, new features, promotions, newsletters, and other
              available instructor-created content (which you can opt out of at
              any time);
            </li>
            <li>
              Sending push notifications to your wireless device to provide
              updates and other relevant messages (which you can manage from the
              “options” or “settings” page of the mobile app);
            </li>
          </ul>
          <li>
            Manage your account and account preferences and personalize your
            experience (Account Data; Learning Data; Student Payment Data;
            Instructor Payment Data; System Data, Usage Data, Cookie Data);
          </li>
          <li>
            Facilitate the Services’ technical functioning, including
            troubleshooting and resolving issues, securing the Services, and
            preventing fraud and abuse (Account Data; Student Payment Data;
            Instructor Payment Data; Communications and Support; System Data;
            Approximate Geographic Location);
          </li>
          <li>
            Verify the identity of instructors (Account Data; Instructor Payment
            Data);
          </li>
          <li>
            Solicit feedback from users (Account Data; Communications and
            Support);
          </li>
          <li>
            Market products, services, surveys, and promotions (Account Data;
            Learning Data; Sweepstakes, Promotions, and Surveys; Usage Data;
            Cookie Data);
          </li>
          <li>
            Market Subscription Plans to prospective customers (Account Data;
            Learning Data; Cookie Data);
          </li>
          <li>
            Learn more about you by linking your data with additional data
            through third-party data providers and/or analyzing the data with
            the help of analytics service providers (Account Data; Data About
            Your Accounts on Other Services; Usage Data; Cookie Data);
          </li>
          <li>
            Identify unique users across devices (Account Data; System Data;
            Cookie Data);
          </li>
          <li>Tailor advertisements across devices (Cookie Data);</li>
          <li>
            Improve our Services and develop new products, services, and
            features (all data categories);
          </li>
          <li>
            Analyze trends and traffic, track purchases, and track usage data
            (Account Data; Learning Data; Student Payment Data; Communications
            and Support; System Data; Usage Data; Approximate Geographic Data;
            Cookie Data);
          </li>
          <li>
            Advertise the Services on third-party websites and applications
            (Account Data; Cookie Data);
          </li>
          <li>As required or permitted by law (all data categories); or</li>
          <li>
            As we, in our sole discretion, otherwise determine to be necessary
            to ensure the safety or integrity of our users, employees, third
            parties, the public, or our Services (all data categories).
          </li>
        </ul>
        <h5>4. Who We Share Your Data With</h5>
        <p>
          We share certain data about you with instructors, other students,
          companies performing services for us, Deonicode affiliates, our
          business partners, analytics and data enrichment providers, your
          social media providers, companies helping us run promotions and
          surveys, and advertising companies who help us promote our Services.
          We may also share your data as needed for security, legal compliance,
          or as part of a corporate restructuring. Lastly, we can share data in
          other ways if it is aggregated or de-identified or if we get your
          consent.
        </p>
        <p>
          We may share your data with third parties under the following
          circumstances or as otherwise described in this Privacy Policy:
        </p>
        <ul>
          <li>
            <u>With Your Instructors</u>: We share data that we have about you
            (except your email address) with instructors or teaching assistants
            for educational content you access or request information about, so
            they can improve their content for you and other students. This data
            may include things like your country, browser language, operating
            system, device settings, the site that brought you to Deonicode, and
            certain activities on Deonicode, like enrolled courses and course
            review. We will not share your email address with instructors or
            teaching assistants. (Account Data; System Data; Usage Data;
            Approximate Geographic Data)
          </li>
          <li>
            <u>With Other Students and Instructors</u>: Depending on your
            settings, your shared content and profile data may be publicly
            viewable, including to other students and instructors. If you ask a
            question to an instructor or teaching assistant, your information
            (including your name) may also be publicly viewable. (Account Data;
            Profile Data; Shared Content)
          </li>
          <li>
            <u>With Service Providers, Contractors, and Agents</u>: We share
            your data with third-party companies who perform services on our
            behalf, like payment processing, fraud and abuse prevention, data
            analysis, marketing and advertising services (including retargeted
            advertising), email and hosting services, and customer services and
            support. These service providers may access your personal data and
            are required to use it solely as we direct, to provide our requested
            service. (All data categories)
          </li>
          <li>
            <u>With Deonicode Affiliates</u>: We may share your data within our
            corporate family of companies that are related by common ownership
            or control to enable or support us in providing the Services. (All
            data categories)
          </li>
          <li>
            <u>With Business Partners</u>: We have agreements with other
            websites and platforms to distribute our Services and drive traffic
            to Deonicode. Depending on your location, we may share your data
            with these trusted partners. (Account Data; Learning Data;
            Communications and Support; System Data)
          </li>
          <li>
            <u>With Credit-Granting Organizations for Continuing Education</u>:
            If you take a course to fulfill a continuing professional education
            requirement, we may share that information upon request of the
            organization granting the continuing education credit. (Account
            Data; Learning Data)
          </li>
          <li>
            <u>With Analytics and Data Enrichment Services</u>: As part of our
            use of third-party analytics tools like Google Analytics and data
            enrichment services like ZoomInfo, we share certain contact
            information or de-identified data. De-identified data means data
            where we’ve removed things like your name and email address and
            replaced it with a token ID. This allows these providers to provide
            analytics services or match your data with publicly-available
            database information (including contact and social information from
            other sources). We do this to communicate with you in a more
            effective and customized manner. (Account Data; System Data; Usage
            Data; Cookie Data)
          </li>
          <li>
            <u>To Power Social Media Features</u>: The social media features in
            the Services (like the Facebook Like button) may allow the
            third-party social media provider to collect things like your IP
            address and which page of the Services you’re visiting, and to set a
            cookie to enable the feature. Your interactions with these features
            are governed by the third-party company’s privacy policy. (System
            Data; Usage Data; Cookie Data)
          </li>
          <li>
            <u>To Administer Promotions and Surveys</u>: We may share your data
            as necessary to administer, market, or sponsor promotions and
            surveys you choose to participate in, as required by applicable law
            (like to provide a winners list or make required filings), or in
            accordance with the rules of the promotion or survey. (Account Data;
            Sweepstakes, Promotions, and Surveys)
          </li>
          <li>
            <u>For Advertising:</u>: if we decide to use an
            advertising-supported revenue model in the future, we may use and
            share certain System Data and Usage Data with third-party
            advertisers and networks to show general demographic and preference
            information among our users. We may also allow advertisers to
            collect System Data through Data Collection Tools (as detailed in
            Section 2.1), to use this data to offer you targeted ad delivery to
            personalize your user experience (through behavioral advertising)
            and to undertake web analytics. Advertisers may also share with us
            the data they collect about you. To learn more or opt out from
            participating ad networks’ behavioral advertising, see Section 6.1
            (Your Choices About the Use of Your Data) below. Note that if you
            opt out, you’ll continue to be served generic ads. (System Data)
          </li>
          <li>
            <u>For Security and Legal Compliance:</u>: We may disclose your data
            (all data categories) to third parties if we (in our sole
            discretion) have a good faith belief that the disclosure is:
          </li>
          <ul>
            <li>
              Requested as part of a judicial, governmental, or legal inquiry,
              order, or proceeding;
            </li>
            <li>
              Reasonably necessary as part of a valid subpoena, warrant, or
              other legally-valid request;
            </li>
            <li>
              Reasonably necessary to enforce our Terms of Use, Privacy Policy,
              and other legal agreements;
            </li>
            <li>
              Required to detect, prevent, or address fraud, abuse, misuse,
              potential violations of law (or rule or regulation), or security
              or technical issues;
            </li>
            <li>
              Reasonably necessary in our discretion to protect against imminent
              harm to the rights, property, or safety of Deonicode, our users,
              employees, members of the public, or our Services;
            </li>
            <li>
              We may also disclose data about you to our auditors and legal
              advisors in order to assess our disclosure obligations and rights
              under this Privacy Policy; or
            </li>
            <li>Required or permitted by law.</li>
          </ul>
          <li>
            <u>During a Change in Control</u>: If Deonicode undergoes a business
            transaction like a merger, acquisition, corporate divestiture, or
            dissolution (including bankruptcy), or a sale of all or some of its
            assets, we may share, disclose, or transfer all of your data to the
            successor organization during such transition or in contemplation of
            a transition (including during due diligence). (All data categories)
          </li>
          <li>
            <u>After Aggregation/De-identification</u>: We may disclose or use
            aggregated or de-identified data for any purpose.
          </li>
          <li>
            <u>With Your Permission</u>: With your consent, we may share data to
            third parties outside the scope of this Privacy Policy. (All data
            categories)
          </li>
        </ul>
        <h5>5. Security</h5>
        <p>
          We use appropriate security based on the type and sensitivity of data
          being stored. As with any internet-enabled system, there is always a
          risk of unauthorized access, so it’s important to protect your
          password and to contact us if you suspect any unauthorized access to
          your account.
        </p>
        <p>
          Deonicode takes appropriate security measures to protect against
          unauthorized access, alteration, disclosure, or destruction of your
          personal data that we collect and store. These measures vary based on
          the type and sensitivity of the data. Unfortunately, however, no
          system can be 100% secured, so we cannot guarantee that communications
          between you and Deonicode, the Services, or any information provided
          to us in connection with the data we collect through the Services will
          be free from unauthorized access by third parties. Your password is an
          important part of our security system, and it is your responsibility
          to protect it. You should not share your password with any third
          party, and if you believe your password or account has been
          compromised, you should change it immediately and contact our Support
          Team with any concerns.
        </p>
        <h5>6. Your Rights</h5>
        <p>
          You have certain rights around the use of your data, including the
          ability to opt out of promotional emails, cookies, and collection of
          your data by certain third parties. You can update or terminate your
          account from within our Services, and can also contact us for
          individual rights requests about your personal data. Parents who
          believe we’ve unintentionally collected personal data about their
          underage child should contact us for help deleting that information.
        </p>
        <strong>6.1 Your Choices About the Use of Your Data</strong>
        <p>
          You can choose not to provide certain data to us, but you may not be
          able to use certain features of the Services.
        </p>
        <ul>
          <li>
            To stop receiving promotional communications from us, you can opt
            out by using the unsubscribe mechanism in the promotional
            communication you receive or by changing the email preferences in
            your account. Note that regardless of your email preference
            settings, we will send you transactional and relationship messages
            regarding the Services, including administrative confirmations,
            order confirmations, important updates about the Services, and
            notices about our policies.
          </li>
          <li>
            If you’re located in the European Economic Area, you may opt out of
            certain Data Collection Tools by clicking the “Cookie settings“ link
            at the bottom of any page.
          </li>
          <li>
            The browser or device you use may allow you to control cookies and
            other types of local data storage. To learn more about managing
            cookies, visit https://cookiepedia.co.uk/how-to-manage-cookies. Your
            wireless device may also allow you to control whether location or
            other data is collected and shared.
          </li>
          <li>
            To get information and control cookies used for tailored advertising
            from participating companies, see the consumer opt-out pages for the
            Network Advertising Initiative and Digital Advertising Alliance, or
            if you’re located in the European Economic Area, visit the Your
            Online Choices site. If you’re located in Japan, visit the Digital
            Advertising Consortium. To opt out of Google’s display advertising
            or customize Google Display Network ads, visit the Google Ads
            Settings page. To opt out of Taboola’s targeted ads, see the Opt-out
            Link in their Cookie Policy.
          </li>
          <li>
            To opt out of allowing Google Analytics, Mixpanel, ZoomInfo, or
            Clearbit to use your data for analytics or enrichment, see the
            Google Analytics Opt-out Browser Add-on, Mixpanel Opt-Out Cookie,
            ZoomInfo’s policy, and Clearbit data claiming mechanism.
          </li>
          <li>
            Apple iOS, Android OS, and Microsoft Windows each provide their own
            instructions on how to control in-app tailored advertising. For
            other devices and operating systems, you should review your privacy
            settings on that platform.
          </li>
        </ul>
        <p>
          If you have any questions about your data, our use of it, or your
          rights, contact us at info@deonicode.net.
        </p>
        <strong>
          6.2 Accessing, Updating, and Deleting Your Personal Data
        </strong>
        <p>
          You can access and update your personal data that Deonicode collects
          and maintains as follows:
        </p>
        <ul>
          <li>
            To update data you provide directly, log into your account and
            update your account at any time.
          </li>
          <li>To terminate your account:</li>
          <ul>
            <li>
              If you are a student, visit your profile settings page and follow
              the steps detailed here.
            </li>
            <li>If you are an instructor, follow the steps detailed here.</li>
            <li>
              If you have any issues terminating your account, please contact
              our Support Team.
            </li>
            <li>
              Please note: even after your account is terminated, some or all of
              your data may still be visible to others, including without
              limitation any data that has been (a) copied, stored, or
              disseminated by other users (including comments on content); (b)
              shared or disseminated by you or others (including in your shared
              content); or (c) posted to a third-party platform. Even after your
              account is terminated, we retain your data for as long as we have
              a legitimate purpose to do so (and in accordance with applicable
              law), including to assist with legal obligations, resolve
              disputes, and enforce our agreements. We may retain and disclose
              such data pursuant to this Privacy Policy after your account has
              been terminated.
            </li>
          </ul>
          <li>
            To request to access, correct, or delete your personal data, please
            use our online form here. You can also submit these requests by
            emailing info@deonicode.net or writing to us at Deonicode, Attn:
            Privacy/Legal Team, 600 Harrison Street, 3rd floor, San Francisco CA
            94107. Please allow up to 30 days for a response. For your
            protection, we may require that the request be sent through the
            email address associated with your account, and we may need to
            verify your identity before implementing your request. Please note
            that we retain certain data where we have a lawful basis to do so,
            including for mandatory record-keeping and to complete transactions.
          </li>
        </ul>
        <strong>6.3 Our Policy Concerning Children</strong>
        <p>
          We recognize the privacy interests of children and encourage parents
          and guardians to take an active role in their children’s online
          activities and interests. Individuals younger than 18 years of age,
          but of the required age for consent to use online services where they
          live (for example, 13 in the US or 16 in Ireland), may not set up an
          account, but may have a parent or guardian open an account and help
          them access appropriate content. Individuals younger than the required
          age for consent to use online services may not use the Services. If we
          learn that we’ve collected personal data from a child under those
          ages, we will take reasonable steps to delete it.
        </p>
        <p>
          Parents who believe that Deonicode may have collected personal data
          from a child under those ages can submit a request that it be removed
          to info@deonicode.net.
        </p>
        <h5>7. Jurisdiction-Specific Rules</h5>
        <p>
          If you live in California, you have certain rights related to
          accessing and deleting your data, as well as learning who we share
          your data with. If you live in Australia, you have the right to make a
          formal complaint with the appropriate government agency. Users outside
          of the United States should note that we transfer data to the US and
          other areas outside of the European Economic Area.
        </p>
        <strong>7.1 Users in California</strong>
        <p>
          Users who are California residents have certain rights under the
          California Consumer Privacy Act, (“CCPA”). If you are an eligible
          California user, included in these rights are:
        </p>
        <ul>
          <li>
            “<b>Right to Know</b>” — You have the right to request to know more
            about the categories and specific pieces of personal information
            that we have collected about you and access a copy of your personal
            information.
          </li>
          <li>
            <b>“Right to Correction</b>” — You have the right to have inaccurate
            personal information about you corrected.
          </li>
          <li>
            "<b>Right to Deletion</b>" — You have the right to request deletion
            of personal information that we have collected about you.
          </li>
          <li>
            “<b>Right to Non-Discrimination</b>” — If you choose to exercise any
            of your rights under CCPA, Deonicode will treat you like all other
            users. In other words, there is no penalty for exercising your
            rights under CCPA.
          </li>
          <li>
            “<b>Right to Opt-Out</b>” - You have the right to opt out of the
            sale of your personal information.
          </li>
        </ul>
        <p>
          CCPA has a specific definition of a “sale” and while Deonicode does
          not, in the traditional sense, sell your personal information or the
          personal information of any of our users, we do use cookies that make
          non-personally identifiable information available to select
          third-parties. To opt out of such a “sale,” click on the “Do Not Sell
          My Personal Information” link at the bottom of this page.
        </p>
        <p>
          To exercise any of these rights under CCPA, please email
          info@deonicode.net. CCPA allows you to designate an authorized agent
          to make these requests on your behalf. For your protection, we may
          require that the request be sent through the email address associated
          with your account, and we may need to verify you and/or your agent’s
          identity before fulfilling your request.
        </p>
        <p>
          Additionally, for more information about the personal information we
          collect and how we collect it, please see the sections above entitled
          “What Data We Get” and “How We Get Data About You.”
        </p>
        <p>
          To learn about the business and commercial purposes for which your
          personal information is collected and the categories of service
          providers who have access to your personal information, please see the
          sections above entitled “What We Use Your Data For” and “Who We Share
          Your Data With.”
        </p>
        <p>
          As a California resident, you also have the right to request certain
          details about what personal information we share with third parties
          for those third parties’ direct marketing purposes. To submit your
          request, send an email to info@deonicode.net with the phrase
          “California Shine the Light” and include your mailing address, state
          of residence, and email address.
        </p>
        <p>
          Since there is no widely accepted standard for the browser-initiated
          Do Not Track signal, we do not currently recognize or respond to Do
          Not Track signals.
        </p>
        <strong>7.2 Users in Nevada</strong>
        <p>
          <b>
            Deonicode does not sell its users’ personal information or personal
            data.
          </b>{" "}
          Nonetheless, Nevada residents have the right to submit a request that
          we do not sell your covered personal information, which you can do by
          emailing info@deonicode.net
        </p>
        <strong>7.3 Users in Australia</strong>
        <p>
          If you are an Australia resident and you have a complaint, you may
          refer it to the office of the Australian Information Commissioner
          (“OAIC”). You can contact OAIC by visiting www.oaic.gov.au; forwarding
          an email to enquiries@oaic.gov.au; telephoning 1300 363 992; or
          writing to OAIC at GPO Box 5218, Sydney NSW 2001. You may contact our
          privacy team at info@deonicode.net to make a complaint about a breach
          of the Australian Privacy Principles which will be responded to within
          30 days.
        </p>
        <strong>
          7.4 Users in the European Economic Area (“EEA”) and United Kingdom
          (“UK”)
        </strong>
        <p>
          If you are located in the EEA or UK, you have the right to request
          access to your data in a portable format and to request the
          rectification, erasure, restriction of processing, or objection to
          processing of your personal data. You also have the right to obtain a
          free copy of the Standard Contractual Clauses referenced in Section
          7.5. You may use the information in Section 6.2 to submit your
          request. Additionally, if you are located in the EEA, UK, or
          Switzerland, you also have the right to lodge a complaint with your
          supervisory authority.
        </p>
        <p>
          Personal data is also processed outside of the UK, Switzerland, and
          the EEA by our Deonicode group companies, or our service providers,
          including to process transactions, facilitate payments, and provide
          support services as described in Section 4. We use the
          Controller-to-Processor Standard Contractual Clauses adopted by the
          European Commission to facilitate transfers of personal data from the
          EEA to third countries and have entered into data processing
          agreements with our service providers and Deonicode group companies to
          restrict and regulate their processing of your data. By submitting
          your data or using our Services, you consent to this transfer,
          storage, and processing by Deonicode and its processors.
        </p>
        <strong>7.5 Users Outside of the U.S.</strong>
        <p>
          <b>
            In order to provide the Services to you, we must transfer your data
            to the United States and process it there.
          </b>{" "}
          If you are using the Services from outside the United States, you
          consent to the transfer, storage, and processing of your data in and
          to the United States or other countries.
        </p>
        <h5>8. Updates & Contact Info</h5>
        <p>
          When we make a material change to this policy, we’ll notify users via
          email, in-product notice, or another mechanism required by law.
          Changes become effective the day they’re posted. Please contact us via
          email or postal mail with any questions, concerns, or disputes.
        </p>
        <strong>8.1 Modifications to This Privacy Policy</strong>
        <p>
          From time to time, we may update this Privacy Policy. If we make any
          material change to it, we will notify you via email, through a
          notification posted on the Services, or as required by applicable law.
          We will also include a summary of the key changes. Unless stated
          otherwise, modifications will become effective on the day they are
          posted.
        </p>
        <p>
          As permitted by applicable law, if you continue to use the Services
          after the effective date of any change, then your access and/or use
          will be deemed an acceptance of (and agreement to follow and be bound
          by) the revised Privacy Policy. The revised Privacy Policy supersedes
          all previous Privacy Policies.
        </p>
        <strong>8.2 Interpretation</strong>
        <p>
          Any capitalized terms not defined in this policy are defined as
          specified in Deonicode's Terms of Use. Any version of this Privacy
          Policy in a language other than English is provided for convenience.
          If there is any conflict with a non-English version, you agree that
          the English language version will control.
        </p>
        <strong>8.3 Questions</strong>
        <p>
          If you have any questions, concerns, or disputes regarding our Privacy
          Policy, please feel free to contact us at info@deonicode.net.
        </p>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default PrivacyPlocy;
