/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import FooterTwo from "../component/layout/footer-2";
import Progress from "../component/sidebar/progress";
import Lesson from "../component/sidebar/course-lesson";
import AskQuestion from "../component/sidebar/ask-question";
import AllQuestions from "../component/sidebar/all-questions";
import Announcements from "../component/sidebar/announcements";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  singleCourse,
  allChapters,
  getCourseProgress,
  registerCourseProgress,
  allLessons,
} from "../helpers/courses/courses";
import { logoutUser } from "../helpers/authentication/auth";
import { useDispatch, useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import Loader from "../component/Loader/BtnLoader";
import Dropdown from "react-bootstrap/Dropdown";
import { AuthContext } from "../context/AuthContext";

const CourseView = () => {
  const { id } = useParams();
  const { userId } = useContext(AuthContext);
  const videoRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [chapLoading, setChapLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const [videoUrl, setVideourl] = useState("");
  const [isLoom, setIsLoom] = useState(false);
  const [course, setCourse] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [icon, setIcon] = useState(false);
  const [numberOfLessons, setNumberOfLessons] = useState([]);
  const [selected, setSelected] = useState(null);
  const [progress, setProgress] = useState(0);
  const courseState = useSelector((state) => state.course.course);
  const chapterState = useSelector((state) => state.course.chapters);
  const progressLessons = useSelector((state) => state.course.progressTracking);

  const getCourse = async () => {
    const data = {
      id,
    };
    await singleCourse(data, dispatch, setLoading);
  };

  const getChapters = async () => {
    const data = {
      id,
    };
    await allChapters(data, dispatch, setChapLoading);
  };

  const getWatchedLessons = async () => {
    if (userId && id) {
      const data = {
        userId,
        courseId: id,
      };
      await getCourseProgress(data, dispatch, setLoading);
    }
  };

  const markedAsWatched = async () => {
    if (userId && id && selected) {
      const data = {
        userId,
        courseId: id,
        lessonId: selected.id,
      };
      await registerCourseProgress(data, dispatch, setScreenLoading);
    }
  };

  const calculateProgress = () => {
    if (
      chapters.length > 0 &&
      numberOfLessons.length > 0 &&
      progressLessons.length > 0
    ) {
      let totalNum = 0;
      chapters.map((item) => {
        let num = numberOfLessons.filter((obj) => obj.chapter === item._id)[0]
          ?.num;
        totalNum += num;
      });

      const progresPercentage = Math.round(
        (progressLessons.length / totalNum) * 100
      );
      setProgress(progresPercentage);
    } else {
      setProgress(0);
    }
  };

  useEffect(() => {
    calculateProgress();
  }, [chapters, numberOfLessons, progressLessons]);

  useEffect(() => {
    markedAsWatched();
  }, [selected]);

  useEffect(() => {
    videoRef.current?.load();
  }, [videoUrl]);

  useEffect(() => {
    getCourse();
    getChapters();
    getWatchedLessons();
  }, [id]);

  useEffect(() => {
    setCourse(courseState);
    setChapters(chapterState);
  }, [courseState, chapterState]);

  const getAllLessons = async () => {
    if (chapters[0]) {
      const data = {
        id: chapters[0]._id,
      };
      const response = await allLessons(data, dispatch, setLoading);
      if (response.status === "error") {
        toast.error(response.message);
        return;
      }
      if (response.status === "success") {
        let item = response.lessons[response.lessons.length - 1];
        if (item?.loomType) {
          setIsLoom(item?.loomType);
          setVideourl(item?.loomLink);
        } else {
          setIsLoom(false);
          setVideourl(item?.content?.doc);
        }
        return;
      }
    }
  };
  useEffect(() => {
    getAllLessons();
  }, [chapters]);

  document.title = "Deonicode | Learning center";
  return (
    <Fragment>
      <Header progress={progress} />
      {loading ? (
        <Loader size={40} color1={"black"} color2={"black"} />
      ) : (
        <>
          <div className=" py-2 section-bg">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="course-view  px-4">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 col-12">
                        <div className="video-part mb-4 mb-lg-0">
                          <div className="vp-video mb-4">
                            {screenLoading ? (
                              <Loader
                                size={40}
                                color1={"black"}
                                color2={"black"}
                              />
                            ) : isLoom ? (
                              <iframe
                                src={videoUrl.replace("share", "embed")}
                                frameBorder="0"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen
                                style={{
                                  width: "100%",
                                  minHeight: "600px",
                                }}
                              ></iframe>
                            ) : (
                              <video
                                controls
                                controlsList="nodownload"
                                ref={videoRef}
                                style={{
                                  width: "100%",
                                  minHeight: "400px",
                                }}
                              >
                                <source src={videoUrl} type="video/mp4" />
                              </video>
                            )}
                          </div>
                          {screenLoading ? "" : <h4>{selected?.title}</h4>}
                          <div
                            className={`content-wrapper ${icon ? "open" : ""}`}
                          >
                            <div
                              className="content-icon d-lg-none"
                              onClick={() => setIcon(!icon)}
                            >
                              <i className="icofont-caret-down"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-8 col-12">
                        <div className="video-list-area">
                          <div className="video-head"></div>
                          <div className="video-body">
                            <div className="course-select-area border-radius-12">
                              <div className="csa-title mb-2">
                                <h4>Course Lessons</h4>
                              </div>
                              <div className="p-2">
                                <a
                                  href={courseState?.mentorship}
                                  target="_blank"
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#EA7F21",
                                      color: "white",
                                    }}
                                    className="ml-2 btn"
                                  >
                                    Book a mentorship session
                                  </button>
                                </a>
                              </div>
                              <div
                                className="csa-body p-2"
                                style={{
                                  minHeight: 700,
                                  maxHeight: 450,
                                  overflowY: "scroll",
                                  borderRadius: 5,
                                }}
                              >
                                <ul className="lab-ul">
                                  <li>
                                    <div id="accordion">
                                      {chapLoading ? (
                                        <Loader
                                          size={40}
                                          color1={"black"}
                                          color2={"black"}
                                        />
                                      ) : chapters.length > 0 ? (
                                        chapters.map((item, index) => {
                                          let res = numberOfLessons.filter(
                                            (obj) => obj.chapter === item._id
                                          )[0];

                                          return (
                                            <div
                                              className="card active bg-ash mb-1"
                                              key={index}
                                            >
                                              <div
                                                className="card-header bg-transparent border-bottom-0"
                                                id={`acc-list-${index}`}
                                              >
                                                <h5 className="mb-0">
                                                  <button
                                                    className="w-100 border-0 bg-transparent outline-none text-left"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#acc-${index}`}
                                                    aria-expanded="true"
                                                    aria-controls={`acc-${index}`}
                                                  >
                                                    {item.title}
                                                    <span className="d-block font-weight-normal">
                                                      Lessons: {res?.num}
                                                    </span>
                                                    <div className="icon">
                                                      <i className="icofont-thin-down"></i>
                                                    </div>
                                                  </button>
                                                </h5>
                                              </div>
                                              <div
                                                id={`acc-${index}`}
                                                className="collapse"
                                                aria-labelledby={`acc-list-${index}`}
                                                data-bs-parent="#accordion"
                                              >
                                                <Lesson
                                                  chapter={item}
                                                  setVideourl={setVideourl}
                                                  setIsLoom={setIsLoom}
                                                  numberOfLessons={
                                                    numberOfLessons
                                                  }
                                                  setNumberOfLessons={
                                                    setNumberOfLessons
                                                  }
                                                  setSelected={setSelected}
                                                  selected={selected}
                                                  progressLessons={
                                                    progressLessons
                                                  }
                                                />
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        "No Chapters Found"
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="overview-announce-section padding-tb bg-white mt-2"
            style={{ borderBottom: "1px solid black" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="course-view-bottom">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active text-dark"
                          id="overview-tab"
                          data-bs-toggle="tab"
                          href="#overview"
                          role="tab"
                          aria-controls="overview"
                          aria-selected="true"
                        >
                          Overview
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link text-dark"
                          id="announcement-tab"
                          data-bs-toggle="tab"
                          href="#announcement"
                          role="tab"
                          aria-controls="announcement"
                          aria-selected="false"
                        >
                          Announcement
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link text-dark"
                          id="question-tab"
                          data-bs-toggle="tab"
                          href="#question"
                          role="tab"
                          aria-controls="question"
                          aria-selected="false"
                        >
                          Q & A
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="overview"
                        role="tabpanel"
                        aria-labelledby="overview-tab"
                      >
                        <div className="overview-area">
                          <div className="overview-body">
                            <div
                              className="course-content"
                              dangerouslySetInnerHTML={{
                                __html: course && course?.desc,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="announcement"
                        role="tabpanel"
                        aria-labelledby="announcement-tab"
                      >
                        <div className="announcement-area">
                          <div className="announcement-lists">
                            <ul className="lab-ul">
                              <li>
                                <Announcements course={course} />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="question"
                        role="tabpanel"
                        aria-labelledby="question-tab"
                      >
                        <div className="announcement-area">
                          <div className="announcement-lists">
                            <ul className="lab-ul">
                              <AllQuestions course={course} />
                              <li>
                                <AskQuestion course={course} />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <FooterTwo />
    </Fragment>
  );
};

const Header = ({ progress }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { setAuthenticated } = useContext(AuthContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await logoutUser(dispatch, setLoading);
    if (response.status === "success") {
      setAuthenticated(false);
      return navigate("/");
    } else {
      return toast.error("error loging out");
    }
  };
  return (
    <div className="header">
      <div className="container">
        <div className="header-content">
          <div className="logo">
            <Link to="/">
              <img src="/assets/images/logo/01.png" alt="logo" />
            </Link>
          </div>
          <div className="menues d-flex">
            {/* <div style={{ fontWeight: "bold", marginTop: 35, fontSize: 20 }}>
              Progress
            </div>
            <div
              style={{
                width: 100,
                textAlign: "center",
                margin: "auto",
              }}
            >
              <Progress
                radiusLength={30}
                strokeWidth={6}
                percentage={progress ? progress : 0}
                textFontSize={15}
              />
            </div> */}
            <Dropdown style={{ marginTop: 30 }}>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="btn fw-bold"
                style={{
                  backgroundColor: "#E97F21",
                  color: "#fff",
                  border: "none",
                  borderRadius: 0,
                }}
              >
                <i className="icofont-justify-all"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item className="p-0">
                  <Link to={`/certification/${id}`} className="w-100 px-2 py-1">
                    Get Certificate
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item className="p-0">
                  <Link to="/dashboard" className="w-100 px-2 py-1">
                    Dashboard
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="p-0">
                  <Link to="/profile" className="w-100 px-2 py-1">
                    Profile
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="p-0" onClick={handleLogout}>
                  <Link to="#" className="w-100 px-2 py-1 text-danger">
                    Log out
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseView;
