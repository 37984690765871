import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isExpired, decodeToken } from "react-jwt";

export const AuthContext = createContext();

export default ({ children }) => {
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [userPurchasedCourses, setUserPurchasedCourses] = useState([]);
  // discount data
  const [discountId, setDiscountId] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [expiredToken, setExpiredToken] = useState(false);

  const { stateUser } = useSelector((state) => ({
    user: state.auth.user,
  }));
  const user = localStorage.getItem("deonicode-user");
  //
  const { discount } = useSelector((state) => ({
    discount: state.discount.discount,
  }));
  const localDiscount = JSON.parse(
    localStorage.getItem("deonicode-discount-token")
  );
  const discountToken = localDiscount?.token;

  useEffect(() => {
    if (stateUser || user) {
      const decodedToken = decodeToken(stateUser || user);
      if (decodedToken != null) {
        setUserId(decodedToken.id);
        setUsername(decodedToken.username);
        setUseremail(decodedToken.email);
        setAuthenticated(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (discount || discountToken) {
      const decodedToken = decodeToken(discountToken || discount?.token);
      const isMyTokenExpired = isExpired(discountToken || discount?.token);
      setExpiredToken(isMyTokenExpired);
      if (!isMyTokenExpired) {
        setDiscountId(localDiscount?._id);
        setDiscountAmount(decodedToken.amount);
        setExpiryDate(new Date(decodedToken.expiryDate));
      }
    }
  }, [discount, discountToken]);

  return (
    <AuthContext.Provider
      value={{
        userId,
        username,
        useremail,
        authenticated,
        setAuthenticated,
        //
        userPurchasedCourses,
        //
        discountId,
        discountAmount,
        setDiscountAmount,
        expiryDate,
        setExpiryDate,
        expiredToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
