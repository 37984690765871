import { Fragment, useEffect, useState } from "react";
import FooterTwo from "../component/layout/footer-2";
import HeaderFour from "../component/layout/header-4";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";
import Lesson from "../component/sidebar/course-lesson";
//
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { singleCourse, allChapters } from "../helpers/courses/courses";
import { allLessons } from "../helpers/courses/courses";
import draftToHtml from "draftjs-to-html";
// Loader
import Loader from "../component/Loader/BtnLoader";

const CourseSingle = () => {
  const [loading, setLoading] = useState(false);
  const [chapLoading, setChapLoading] = useState(false);
  const [course, setCourse] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [numberOfLessons, setNumberOfLessons] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const courseState = useSelector((state) => state.course.course);
  const chapterState = useSelector((state) => state.course.chapters);

  const getCourse = async () => {
    const data = {
      id,
    };
    const response = await singleCourse(data, dispatch, setLoading);
    if (response.status === "error") {
      toast.error(response.message);
      return;
    }
  };

  const getChapters = async () => {
    const data = {
      id,
    };
    await allChapters(data, dispatch, setChapLoading);
  };

  const getNumberofLessons = async () => {
    setNumberOfLessons(0);
    let totalNum = 0;
    chapters.map(async (chapter) => {
      let data = {
        id: chapter._id,
      };
      let res = await allLessons(data, dispatch, setChapLoading);
      totalNum = totalNum + res.lessons.length;
      setNumberOfLessons(totalNum);
      return totalNum;
    });
  };

  useEffect(() => {
    getNumberofLessons();
  }, [chapters]);

  useEffect(() => {
    getCourse();
    getChapters();
  }, [id]);

  useEffect(() => {
    setCourse(courseState);
    setChapters(chapterState);
  }, [courseState, chapterState]);

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Loader size={80} color1={"black"} color2={"black"} />
      </div>
    );
  }

  document.title = "Deonicode | Course details";
  return (
    <Fragment>
      <HeaderFour />

      <PageHeaderTwo course={course} />
      <div className="course-single-section padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div
                      className="course-content bg-white p-4"
                      dangerouslySetInnerHTML={{
                        __html: course && course?.desc,
                      }}
                    />
                  </div>
                </div>

                <div className="course-video">
                  <div className="course-video-title">
                    <h4>Course Content</h4>
                  </div>
                  <div className="course-video-content">
                    <div className="accordion" id="accordionExample">
                      {chapLoading ? (
                        <Loader size={40} color1={"black"} color2={"black"} />
                      ) : chapters?.length > 0 ? (
                        chapters?.map((item, index) => (
                          <div className="accordion-item" key={index}>
                            <div
                              className="accordion-header"
                              id={`accordion` + index}
                            >
                              <button
                                className="d-flex flex-wrap justify-content-between"
                                data-bs-toggle="collapse"
                                data-bs-target={`#videolist` + index}
                                aria-expanded="true"
                                aria-controls={`videolist` + index}
                              >
                                <span>
                                  {index + 1}.{item.title}
                                </span>{" "}
                                {/* <span>{numberOfLessons} lessons</span>{" "} */}
                              </button>
                            </div>
                            <div
                              id={`videolist` + index}
                              className="accordion-collapse collapse"
                              aria-labelledby={`accordion` + index}
                              data-bs-parent="#accordionExample"
                            >
                              <Lesson chapter={item} />
                            </div>
                          </div>
                        ))
                      ) : (
                        "No Chapters Found"
                      )}
                    </div>
                  </div>
                </div>
                {/* <Author course={course} /> */}
                <Comment />
                <Respond />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-part">
                <CourseSideDetail
                  course={course}
                  chapters={chapters}
                  numberOfLessons={numberOfLessons}
                />
                <CourseSideCetagory />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default CourseSingle;
