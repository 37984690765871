import { Fragment } from "react";
import HeaderFour from "../component/layout/header-4";
import PageHeader from "../component/layout/pageheader";
import FooterTwo from "../component/layout/footer-2";

const PrivacyPlocy = () => {
  return (
    <Fragment>
      <HeaderFour />
      <PageHeader title={"Terms Of Use"} curPage={"Terms of use"} />
      <div className="container padding-tb text-start px-2">
        <p>
          <i>
            These Terms of Use ("Terms") were last updated on August 27, 2023.
          </i>
        </p>
        <p>
          <strong>
            <i>
              Please review these Terms carefully as they serve as an
              enforceable contract between us and contain important information
              about your legal rights, remedies, and obligations.
            </i>
          </strong>
        </p>
        <p>
          <strong>
            IF YOU LIVE IN THE UNITED STATES OR CANADA, BY AGREEING TO THESE
            TERMS, YOU AGREE TO RESOLVE ALL DISPUTES WITH DEONICODE IN SMALL
            CLAIMS COURT OR THROUGH BINDING INDIVIDUAL ARBITRATION ONLY, AND YOU
            WAIVE THE RIGHT TO PARTICIPATE IN ANY CLASS ACTIONS AND TO HAVE
            CLAIMS DECIDED BY A JURY, AS EXPLAINED IN THE DISPUTE RESOLUTION
            SECTION.
          </strong>
        </p>
        <p>
          Deonicode’s mission is to improve lives through learning. We enable
          anyone anywhere to create and share educational content (instructors)
          and to access that educational content to learn (students). We
          consider our marketplace model the best way to offer valuable
          educational content to our users. We need rules to keep our platform
          and services safe for you, us, and our student and instructor
          community. These Terms apply to all your activities on the Deonicode
          website, the Deonicode mobile applications, our TV applications, our
          APIs, and other related services (“Services”).
        </p>
        <p>
          If you publish a course on the Deonicode platform, you must also agree
          to the Instructor Terms. We also provide details regarding our
          processing of personal data of our students and instructors in our
          Privacy Policy. If you are using Deonicode as part of your employer’s
          Deonicode Business learning and development program, you can consult
          our Deonicode Business Privacy Statement. Our Instructor Terms,
          Privacy Policy, and other Deonicode policies applicable to your use of
          our Services are incorporated by reference into these Terms.
        </p>
        <p>
          Our website and apps cause communications about your browsing and app
          activities and app usage to be sent from you to third parties who
          provide services to Deonicode. By using our Services, you consent to
          these communications.
        </p>
        <h5>Table of Contents</h5>
        <ul>
          <li>1. Accounts</li>
          <li>2. Content Enrollment and Lifetime Access</li>
          <li>3. Payments, Credits, and Refunds</li>
          <li>4. Content and Behavior Rules</li>
          <li>5. Deonicode’s Rights to Content You Post</li>
          <li>6. Using Deonicode at Your Own Risk</li>
          <li>7. Deonicode’s Rights</li>
          <li>8. Subscription Terms</li>
          <li>9. Miscellaneous Legal Terms</li>
          <li>10. Dispute Resolution</li>
          <li>11. Updating These Terms</li>
          <li>12. How to Contact Us</li>
        </ul>
        <h5>1. Accounts</h5>
        <p>
          You need an account for most activities on our platform. Keep your
          password somewhere safe, because you’re responsible for all activity
          associated with your account. If you suspect someone else is using
          your account, let us know by contacting our Support Team. You must
          have reached the age of consent for online services in your country to
          use Deonicode.
        </p>
        <p>
          You need an account for most activities on our platform, including to
          purchase and access content or to submit content for publication. When
          setting up and maintaining your account, you must provide and continue
          to provide accurate and complete information, including a valid email
          address. You have complete responsibility for your account and
          everything that happens on your account, including for any harm or
          damage (to us or anyone else) caused by someone using your account
          without your permission. This means you need to be careful with your
          password. You may not transfer your account to someone else or use
          someone else’s account. If you contact us to request access to an
          account, we will not grant you such access unless you can provide us
          with the information that we need to prove you are the owner of that
          account. In the event of the death of a user, the account of that user
          will be closed.
        </p>
        <p>
          You may not share your account login credentials with anyone else. You
          are responsible for what happens with your account and Deonicode will
          not intervene in disputes between students or instructors who have
          shared account login credentials. You must notify us immediately upon
          learning that someone else may be using your account without your
          permission (or if you suspect any other breach of security) by
          contacting our Support Team. We may request some information from you
          to confirm that you are indeed the owner of your account.
        </p>
        <p>
          Students and instructors must be at least 18 years of age to create an
          account on Deonicode and use the Services. If you are younger than 18
          but above the required age for consent to use online services where
          you live (for example, 13 in the US or 16 in Ireland), you may not set
          up an account, but we encourage you to invite a parent or guardian to
          open an account and help you access content that is appropriate for
          you. If you are below this age of consent to use online services, you
          may not create a Deonicode account. If we discover that you have
          created an account that violates these rules, we will terminate your
          account. Under our Instructor Terms, you may be requested to verify
          your identity before you are authorized to submit content for
          publication on Deonicode.
        </p>
        <p>
          You can terminate your account at any time by following the steps
          here. Check our Privacy Policy to see what happens when you terminate
          your account.
        </p>
        <h5>2. Content Enrollment and Lifetime Access</h5>
        <p>
          When you enroll in a course or other content, you get a license from
          us to view it via the Deonicode Services and no other use. Don’t try
          to transfer or resell content in any way. We generally grant you a
          lifetime access license, except when we must disable the content
          because of legal or policy reasons or for enrollments via Subscription
          Plans. Under our Instructor Terms, when instructors publish content on
          Deonicode, they grant Deonicode a license to offer a license to the
          content to students. This means that we have the right to sublicense
          the content to enrolled students. As a student, when you enroll in a
          course or other content, whether it’s free or paid content, you are
          getting a license from Deonicode to view the content via the Deonicode
          platform and Services, and Deonicode is the licensor of record.
          Content is licensed, and not sold, to you. This license does not give
          you any right to resell the content in any manner (including by
          sharing account information with a purchaser or illegally downloading
          the content and sharing it on torrent sites).
        </p>
        <p>
          In legal, more complete terms, Deonicode grants you (as a student) a
          limited, non-exclusive, non-transferable license to access and view
          the content for which you have paid all required fees, solely for your
          personal, non-commercial, educational purposes through the Services,
          in accordance with these Terms and any conditions or restrictions
          associated with the particular content or feature of our Services. All
          other uses are expressly prohibited. You may not reproduce,
          redistribute, transmit, assign, sell, broadcast, rent, share, lend,
          modify, adapt, edit, create derivative works of, sublicense, or
          otherwise transfer or use any content unless we give you explicit
          permission to do so in a written agreement signed by a Deonicode
          authorized representative. This also applies to content you can access
          via any of our APIs.
        </p>
        <p>
          We generally give a lifetime access license to our students when they
          enroll in a course or other content. However, we reserve the right to
          revoke any license to access and use any content at any point in time
          in the event where we decide or are obligated to disable access to the
          content due to legal or policy reasons, for example, if the course or
          other content you enrolled in is the object of a copyright complaint,
          or if we determine it violates our Trust & Safety Guidelines. This
          lifetime access license does not apply to enrollments via Subscription
          Plans or to add-on features and services associated with the course or
          other content you enroll in. For example, instructors may decide at
          any time to no longer provide teaching assistance or Q&A services in
          association with the content. To be clear, the lifetime access is to
          the course content but not to the instructor.
        </p>
        <p>
          Instructors may not grant licenses to their content to students
          directly, and any such direct license shall be null and void and a
          violation of these Terms.
        </p>
        <h5>3. Payments, Credits, and Refunds</h5>
        <p>
          When you make a payment, you agree to use a valid payment method. If
          you aren’t happy with your content, Deonicode offers a 30-day refund
          or credit for most content purchases.
        </p>
        <strong>3.1 Pricing</strong>
        <p>
          The prices of content on Deonicode are determined based on the terms
          of the Instructor Terms and our Promotions Policy. In some instances,
          the price of content offered on the Deonicode website may not be
          exactly the same as the price offered on our mobile or TV
          applications, due to mobile platform providers’ pricing systems and
          their policies around implementing sales and promotions.
        </p>
        <p>
          We occasionally run promotions and sales for our content, during which
          certain content is available at discounted prices for a set period of
          time. The price applicable to the content will be the price at the
          time you complete your purchase of the content (at checkout). Any
          price offered for particular content may also be different when you
          are logged into your account from the price available to users who
          aren’t registered or logged in, because some of our promotions are
          available only to new users.
        </p>
        <p>
          If you are logged into your account, the listed currency you see is
          based on your location when you created your account. If you are not
          logged into your account, the price currency is based on the country
          where you are located. We do not enable users to see pricing in other
          currencies.
        </p>
        <p>
          If you are a student located in a country where use and sales tax,
          goods and services tax, or value added tax is applicable to consumer
          sales, we are responsible for collecting and remitting that tax to the
          proper tax authorities. Depending on your location, the price you see
          may include such taxes, or tax may be added at checkout.
        </p>
        <strong>3.2 Payments</strong>
        <p>
          You agree to pay the fees for content that you purchase, and you
          authorize us to charge your debit or credit card or process other
          means of payment (such as Boleto, SEPA, direct debit, or mobile
          wallet) for those fees. Deonicode works with payment service providers
          to offer you the most convenient payment methods in your country and
          to keep your payment information secure. We may update your payment
          methods using information provided by our payment service providers.
          Check out our Privacy Policy for more details.
        </p>
        <p>
          When you make a purchase, you agree not to use an invalid or
          unauthorized payment method. If your payment method fails and you
          still get access to the content you are enrolling in, you agree to pay
          us the corresponding fees within 30 days of notification from us. We
          reserve the right to disable access to any content for which we have
          not received adequate payment.
        </p>
        <strong>3.3 Refunds and Refund Credits</strong>
        <p>
          If the content you purchased is not what you were expecting, you can
          request, within 30 days of your purchase of the content, that
          Deonicode apply a refund to your account. This refund option does not
          apply to Subscription Plan purchases, which are covered in Section 8.4
          (Payments and Billing) below. We reserve the right to apply your
          refund as a refund credit or a refund to your original payment method,
          at our discretion, depending on capabilities of our payment service
          providers, the platform from which you purchased your content
          (website, mobile or TV app), and other factors. No refund is due to
          you if you request it after the 30-day guarantee time limit has
          passed. However, if the content you previously purchased is disabled
          for legal or policy reasons, you are entitled to a refund beyond this
          30-day limit. Deonicode also reserves the right to refund students
          beyond the 30-day limit in cases of suspected or confirmed account
          fraud.
        </p>
        <p>
          To request a refund, follow the steps here. As detailed in the
          Instructor Terms, instructors agree that students have the right to
          receive these refunds.
        </p>
        <p>
          If we decide to issue refund credits to your account, they will be
          automatically applied towards your next content purchase on our
          website, but can’t be used for purchases in our mobile or TV
          applications. Refund credits may expire if not used within the
          specified period and have no cash value, in each case unless otherwise
          required by applicable law.
        </p>
        <p>
          At our discretion, if we believe you are abusing our refund policy,
          such as if you’ve consumed a significant portion of the content that
          you want to refund or if you’ve previously refunded the content, we
          reserve the right to deny your refund, restrict you from other future
          refunds, ban your account, and/or restrict all future use of the
          Services. If we ban your account or disable your access to the content
          due to your violation of these Terms or our Trust & Safety Guidelines,
          you will not be eligible to receive a refund. Additional information
          on our refund policy is available here.
        </p>
        <strong>3.4 Gift and Promotional Codes</strong>
        <p>
          Deonicode or our partners may offer gift and promotional codes to
          students. Certain codes may be redeemed for gift or promotional
          credits applied to your Deonicode account, which then may be used to
          purchase eligible content on our platform, subject to the terms
          included with your codes. Other codes may be directly redeemable for
          specific content. Gift and promotional credits can’t be used for
          purchases in our mobile or TV applications.
        </p>
        <p>
          These codes and credits, as well as any promotional value linked to
          them, may expire if not used within the period specified in your
          Deonicode account. Gift and promotional codes offered by Deonicode may
          not be refunded for cash, unless otherwise specified in the terms
          included with your codes or as required by applicable law. Gift and
          promotional codes offered by a partner are subject to that partner’s
          refund policies. If you have multiple saved credit amounts, Deonicode
          may determine which of your credits to apply to your purchase. Check
          out our Support Page and any terms included with your codes for more
          details.
        </p>
        <h5>4. Content and Behavior Rules</h5>
        <p>
          You can only use Deonicode for lawful purposes. You’re responsible for
          all the content that you post on our platform. You should keep the
          reviews, questions, posts, courses and other content you upload in
          line with our Trust & Safety Guidelines and the law, and respect the
          intellectual property rights of others. We can ban your account for
          repeated or major offenses. If you think someone is infringing your
          copyright on our platform, let us know.
        </p>
        <p>
          You may not access or use the Services or create an account for
          unlawful purposes. Your use of the Services and behavior on our
          platform must comply with applicable local or national laws or
          regulations of your country. You are solely responsible for the
          knowledge of and compliance with such laws and regulations that are
          applicable to you.
        </p>
        <p>
          If you are a student, the Services enable you to ask questions to the
          instructors of courses or other content you are enrolled in, and to
          post reviews of content. For certain content, the instructor may
          invite you to submit content as “homework” or tests. Don’t post or
          submit anything that is not yours.
        </p>
        <p>
          If you are an instructor, you can submit content for publication on
          the platform and you can also communicate with the students who have
          enrolled in your courses or other content. In both cases, you must
          abide by the law and respect the rights of others: you cannot post any
          course, question, answer, review or other content that violates
          applicable local or national laws or regulations of your country. You
          are solely responsible for any courses, content, and actions you post
          or take via the platform and Services and their consequences. Make
          sure you understand all the copyright restrictions set forth in the
          Instructor Terms before you submit any content for publication on
          Deonicode.
        </p>
        <p>
          If we are put on notice that your course or content violates the law
          or the rights of others (for example, if it is established that it
          violates intellectual property or image rights of others, or is about
          an illegal activity), if we discover that your content or behavior
          violates our Trust & Safety Guidelines, or if we believe your content
          or behavior is unlawful, inappropriate, or objectionable (for example
          if you impersonate someone else), we may remove your content from our
          platform. Deonicode complies with copyright laws. Check out our
          Intellectual Property Policy for more details.
        </p>
        <p>
          Deonicode has discretion in enforcing these Terms and our Trust &
          Safety Guidelines. We may restrict or terminate your permission to use
          our platform and Services or ban your account at any time, with or
          without notice, for any or no reason, including for any violation of
          these Terms, if you fail to pay any fees when due, for fraudulent
          chargeback requests, upon the request of law enforcement or government
          agencies, for extended periods of inactivity, for unexpected technical
          issues or problems, if we suspect that you engage in fraudulent or
          illegal activities, or for any other reason in our sole discretion.
          Upon any such termination we may delete your account and content, and
          we may prevent you from further access to the platforms and use of our
          Services. Your content may still be available on the platforms even if
          your account is terminated or suspended. You agree that we will have
          no liability to you or any third party for termination of your
          account, removal of your content, or blocking of your access to our
          platforms and services.
        </p>
        <p>
          If a user has published content that infringes your copyright or
          trademark rights, please let us know. Our Instructor Terms require our
          instructors to follow the law and respect the intellectual property
          rights of others. For more details on how to file a copyright or
          trademark infringement claim with us, see our Intellectual Property
          Policy.
        </p>
        <h5>5. Deonicode’s Rights to Content You Post</h5>
        <p>
          You retain ownership of content you post to our platform, including
          your courses. We’re allowed to share your content to anyone through
          any media, including promoting it via advertising on other websites.
        </p>
        <p>
          The content you post as a student or instructor (including courses)
          remains yours. By posting courses and other content, you allow
          Deonicode to reuse and share it but you do not lose any ownership
          rights you may have over your content. If you are an instructor, be
          sure to understand the content licensing terms that are detailed in
          the Instructor Terms.
        </p>
        <p>
          When you post content, comments, questions, reviews, and when you
          submit to us ideas and suggestions for new features or improvements,
          you authorize Deonicode to use and share this content with anyone,
          distribute it and promote it on any platform and in any media, and to
          make modifications or edits to it as we see fit.
        </p>
        <p>
          In legal language, by submitting or posting content on or through the
          platforms, you grant us a worldwide, non-exclusive, royalty-free
          license (with the right to sublicense) to use, copy, reproduce,
          process, adapt, modify, publish, transmit, display, and distribute
          your content (including your name and image) in any and all media or
          distribution methods (existing now or later developed). This includes
          making your content available to other companies, organizations, or
          individuals who partner with Deonicode for the syndication, broadcast,
          distribution, or publication of content on other media, as well as
          using your content for marketing purposes. You also waive any rights
          of privacy, publicity, or other rights of a similar nature applicable
          to all these uses, to the extent permissible under applicable law. You
          represent and warrant that you have all the rights, power, and
          authority necessary to authorize us to use any content that you
          submit. You also agree to all such uses of your content with no
          compensation paid to you.
        </p>
        <h5>6. Using Deonicode at Your Own Risk</h5>
        <p>
          Anyone can use Deonicode to create and publish content and instructors
          and we enable instructors and students to interact for teaching and
          learning. Like other platforms where people can post content and
          interact, some things can go wrong, and you use Deonicode at your own
          risk.
        </p>
        <p>
          Our platform model means we do not review or edit the content for
          legal issues, and we are not in a position to determine the legality
          of content. We do not exercise any editorial control over the content
          that is available on the platform and, as such, do not guarantee in
          any manner the reliability, validity, accuracy, or truthfulness of the
          content. If you access content, you rely on any information provided
          by an instructor at your own risk.
        </p>
        <p>
          By using the Services, you may be exposed to content that you consider
          offensive, indecent, or objectionable. Deonicode has no responsibility
          to keep such content from you and no liability for your access or
          enrollment in any course or other content, to the extent permissible
          under applicable law. This also applies to any content relating to
          health, wellness, and physical exercise. You acknowledge the inherent
          risks and dangers in the strenuous nature of these types of content,
          and by accessing such content you choose to assume those risks
          voluntarily, including risk of illness, bodily injury, disability, or
          death. You assume full responsibility for the choices you make before,
          during, and after your access to the content.
        </p>
        <p>
          When you interact directly with a student or an instructor, you must
          be careful about the types of personal information that you share.
          While we restrict the types of information instructors may request
          from students, we do not control what students and instructors do with
          the information they obtain from other users on the platform. You
          should not share your email or other personal information about you
          for your safety.
        </p>
        <p>
          We do not hire or employ instructors nor are we responsible or liable
          for any interactions involved between instructors and students. We are
          not liable for disputes, claims, losses, injuries, or damage of any
          kind that might arise out of or relate to the conduct of instructors
          or students.
        </p>
        <p>
          When you use our Services, you will find links to other websites that
          we don’t own or control. We are not responsible for the content or any
          other aspect of these third-party sites, including their collection of
          information about you. You should also read their terms and conditions
          and privacy policies.
        </p>
        <h5>7. Deonicode’s Rights</h5>
        <p>
          We own the Deonicode platform and Services, including the website,
          present or future apps and services, and things like our logos, API,
          code, and content created by our employees. You can’t tamper with
          those or use them without authorization. All right, title, and
          interest in and to the Deonicode platform and Services, including our
          website, our existing or future applications, our APIs, databases, and
          the content our employees or partners submit or provide through our
          Services (but excluding content provided by instructors and students)
          are and will remain the exclusive property of Deonicode and its
          licensors. Our platforms and services are protected by copyright,
          trademark, and other laws of both the United States and foreign
          countries. Nothing gives you a right to use the Deonicode name or any
          of the Deonicode trademarks, logos, domain names, and other
          distinctive brand features. Any feedback, comments, or suggestions you
          may provide regarding Deonicode or the Services is entirely voluntary
          and we will be free to use such feedback, comments, or suggestions as
          we see fit and without any obligation to you.
        </p>
        <p>
          You may not do any of the following while accessing or using the
          Deonicode platform and Services:
        </p>
        <ul>
          <li>
            Access, tamper with, or use non-public areas of the platform
            (including content storage), Deonicode’s computer systems, or the
            technical delivery systems of Deonicode’s service providers.
          </li>
          <li>
            Aisable, interfere with, or try to circumvent any of the features of
            the platforms related to security or probe, scan, or test the
            vulnerability of any of our systems.
          </li>
          <li>
            Copy, modify, create a derivative work of, reverse engineer, reverse
            assemble, or otherwise attempt to discover any source code of or
            content on the Deonicode platform or Services.
          </li>
          <li>
            Access or search or attempt to access or search our platform by any
            means (automated or otherwise) other than through our currently
            available search functionalities that are provided via our website,
            mobile apps, or API (and only pursuant to those API terms and
            conditions). You may not scrape, spider, use a robot, or use other
            automated means of any kind to access the Services.
          </li>
          <li>
            In any way use the Services to send altered, deceptive, or false
            source-identifying information (such as sending email communications
            falsely appearing as Deonicode); or interfere with, or disrupt, (or
            attempt to do so), the access of any user, host, or network,
            including, without limitation, sending a virus, overloading,
            flooding, spamming, or mail-bombing the platforms or services, or in
            any other manner interfering with or creating an undue burden on the
            Services.
          </li>
        </ul>
        <h5>8. Subscription Terms</h5>
        <p>
          This section covers additional terms that apply to your use of our
          subscription-based collections as a student (“Subscription Plans”). By
          using a Subscription Plan, you agree to the additional terms in this
          section. Note that use of Deonicode Business is not subject to these
          Terms, but is instead governed by the agreement between Deonicode and
          the subscribing organization.
        </p>
        <strong>8.1 Subscription Plans</strong>
        <p>
          During your subscription to a Subscription Plan, you get a limited,
          non-exclusive, non-transferable license from us to access and view the
          content included in that Subscription Plan via the Services. With the
          exception of the lifetime access license grant, the terms included in
          the “Content Enrollment and Lifetime Access” section above apply to
          enrollments via Subscription Plans.
        </p>
        <p>
          Your Subscription Plan may also include access to interactive
          environments, such as workspaces (“Interactive Sessions”). Interactive
          Sessions may be provided by a third party, subject to its own
          agreement or terms and conditions, and subject to usage limitations
          outlined on our Support Page. You’re responsible for complying with
          the terms and conditions of any third party provider.
        </p>
        <p>
          The subscription that you purchase or renew determines the scope,
          features, and price of your access to a Subscription Plan. You may not
          transfer, assign, or share your subscription with anyone else.
        </p>
        <p>
          We reserve the right to revoke any license to use the content in our
          Subscription Plans for legal or policy reasons at any time and at our
          sole discretion, such as if we no longer have the right to offer the
          content through a Subscription Plan. Additional information on our
          right to revoke is included in the “Content Enrollment and Lifetime
          Access” section.
        </p>
        <strong>8.2 Account Management</strong>
        <p>
          You may cancel your subscription by following the steps outlined on
          our Support Page. If you cancel your subscription to a Subscription
          Plan, your access to that Subscription Plan will automatically end on
          the last day of your billing period. On cancellation, you will not be
          entitled to receive a refund or credit of any fees already paid for
          your subscription, unless otherwise required by applicable law. For
          clarity, cancellation of a subscription does not terminate your
          Deonicode account.
        </p>
        <strong>8.3 Free Trials & Renewals</strong>
        <p>
          Your subscription may start with a free trial. The duration of the
          free trial period of your subscription will be specified during
          sign-up. Deonicode determines free trial eligibility at our sole
          discretion and may limit access, eligibility, or duration. We reserve
          the right to terminate the free trial and suspend your subscription if
          we determine that you aren’t eligible.
        </p>
        <p>
          We will charge the subscription fee for your next billing cycle at the
          end of the free trial period. Your subscription will automatically
          renew according to your subscription settings (e.g., monthly or
          annually) unless you cancel your subscription prior to the end of the
          free trial period. For more information on how to view applicable fees
          and dates of your free trial period, visit our Support Page.
        </p>
        <strong>8.4 Payments and Billing</strong>
        <p>
          The subscription fee will be listed at the time of your purchase. You
          can visit our Support Page to learn more about where to find the fees
          and dates applicable to your subscription. We may also be required to
          add taxes to your subscription fee as described in the “Payments,
          Credits, and Refunds” section above. Payments are non-refundable and
          there are no refunds or credits for partially used periods, unless
          otherwise required by applicable law. Depending on where you are
          located, you may qualify for a refund. See our Refund Policy for
          Subscription Plans for additional information.
        </p>
        <p>
          To subscribe to a Subscription Plan, you must provide a payment
          method. By subscribing to a Subscription Plan and providing your
          billing information during checkout, you grant us and our payment
          service providers the right to process payment for the then-applicable
          fees via the payment method we have on record for you. At the end of
          each subscription term, we will automatically renew your subscription
          for the same length of term and process your payment method for
          payment of the then-applicable fees.
        </p>
        <p>
          In the event that we update your payment method using information
          provided by our payment service providers (as described in the
          “Payments, Credits, and Refunds” section above), you authorize us to
          continue to charge the then-applicable fees to your updated payment
          method.
        </p>
        <p>
          If we are unable to process payment through the payment method we have
          on file for you, or if you file a chargeback disputing charges made to
          your payment method and the chargeback is granted, we may suspend or
          terminate your subscription.
        </p>
        <p>
          We reserve the right to change our Subscription Plans or adjust
          pricing for our Services at our sole discretion. Any price changes or
          changes to your subscription will take effect following notice to you,
          except as otherwise required by applicable law.
        </p>
        <strong>8.5 Interactive Session Restrictions</strong>
        <p>
          You may not do any of the following while accessing or using the
          Interactive Sessions:
        </p>
        <ul>
          <li>
            Use the Interactive Sessions for any purpose other than to perform
            the activities as instructed by Deonicode’s labs;
          </li>
          <li>
            Provide web, database, or forum access, or engage in cryptocurrency
            mining, on or through the Interactive Sessions;
          </li>
          <li>Exceed the usage limitations outlined on our Support Page;</li>
          <li>
            Access or use the Interactive Sessions in any commercial production
            environment;
          </li>
          <li>
            Take any action in the Interactive Sessions that results in a
            disruption or interference of our Services or the stability of our
            infrastructure; or
          </li>
          <li>
            Use any data or information other than simulated, anonymous,
            non-personal, non-live data or information when accessing or using
            the Interactive Sessions.
          </li>
        </ul>
        <p>
          These restrictions are in addition to those listed elsewhere in these
          Terms, including in the “Content and Behavior Rules” and “Deonicode’s
          Rights” sections above.
        </p>
        <strong>8.6 Subscription Disclaimers</strong>
        <p>
          We make no guarantees as to the availability of any specific content
          in any Subscription Plan or as to any minimum amount of content in any
          Subscription Plan. At any point in the future, we reserve the right to
          offer or cease to offer additional features to any Subscription Plan,
          or to otherwise modify or terminate a Subscription Plan at our sole
          discretion. We have no responsibility to preserve or store the content
          inputted by you in connection with your use of any Subscription Plan.
          These disclaimers are in addition to those listed in the “Disclaimers”
          section below.
        </p>
        <h5>9. Miscellaneous Legal Terms</h5>
        <p>
          These Terms are like any other contract, and they have boring but
          important legal terms that protect us from the countless things that
          could happen and that clarify the legal relationship between us and
          you.
        </p>
        <strong>9.1 Binding Agreement</strong>
        <p>
          You agree that by registering, accessing, or using our Services, you
          are agreeing to enter into a legally binding contract with Deonicode.
          If you do not agree to these Terms, do not register, access, or
          otherwise use any of our Services.
        </p>
        <p>
          If you are an instructor accepting these Terms and using our Services
          on behalf of a company, organization, government, or other legal
          entity, you represent and warrant that you are authorized to do so.
        </p>
        <p>
          Any version of these Terms in a language other than English is
          provided for convenience and you understand and agree that the English
          language will control if there is any conflict.
        </p>
        <p>
          These Terms (including any agreements and policies linked from these
          Terms) constitute the entire agreement between you and us (which
          include, if you are an instructor, the Instructor Terms and the
          Promotions Policy).
        </p>
        <p>
          If any part of these Terms is found to be invalid or unenforceable by
          applicable law, then that provision will be deemed superseded by a
          valid, enforceable provision that most closely matches the intent of
          the original provision and the remainder of these Terms will continue
          in effect.
        </p>
        <p>
          Even if we are delayed in exercising our rights or fail to exercise a
          right in one case, it doesn’t mean we waive our rights under these
          Terms, and we may decide to enforce them in the future. If we decide
          to waive any of our rights in a particular instance, it doesn’t mean
          we waive our rights generally or in the future.
        </p>
        <p>
          The following sections shall survive the expiration or termination of
          these Terms: Sections 2 (Content Enrollment and Lifetime Access), 5
          (Deonicode’s Rights to Content You Post), 6 (Using Deonicode at Your
          Own Risk), 7 (Deonicode’s Rights), 8.5 (Subscription Disclaimers), 9
          (Miscellaneous Legal Terms), and 10 (Dispute Resolution).
        </p>
        <strong>9.2 Disclaimers</strong>
        <p>
          It may happen that our platform is down, either for planned
          maintenance or because something goes down with the site. It may
          happen that one of our instructors is making misleading statements in
          their content. It may also happen that we encounter security issues.
          These are just examples. You accept that you will not have any
          recourse against us in any of these types of cases where things don’t
          work out right. In legal, more complete language, the Services and
          their content are provided on an “as is” and “as available” basis. We
          (and our affiliates, suppliers, partners, and agents) make no
          representations or warranties about the suitability, reliability,
          availability, timeliness, security, lack of errors, or accuracy of the
          Services or their content, and expressly disclaim any warranties or
          conditions (express or implied), including implied warranties of
          merchantability, fitness for a particular purpose, title, and
          non-infringement. We (and our affiliates, suppliers, partners, and
          agents) make no warranty that you will obtain specific results from
          use of the Services. Your use of the Services (including any content)
          is entirely at your own risk. Some jurisdictions don’t allow the
          exclusion of implied warranties, so some of the above exclusions may
          not apply to you.
        </p>
        <p>
          We may decide to cease making available certain features of the
          Services at any time and for any reason. Under no circumstances will
          Deonicode or its affiliates, suppliers, partners or agents be held
          liable for any damages due to such interruptions or lack of
          availability of such features.
        </p>
        <p>
          We are not responsible for delay or failure of our performance of any
          of the Services caused by events beyond our reasonable control, like
          an act of war, hostility, or sabotage; natural disaster; electrical,
          internet, or telecommunication outage; or government restrictions.
        </p>
        <strong>9.3 Limitation of Liability</strong>
        <p>
          There are risks inherent to using our Services, for example, if you
          access health and wellness content like yoga, and you injure yourself.
          You fully accept these risks and you agree that you will have no
          recourse to seek damages against even if you suffer loss or damage
          from using our platform and Services. In legal, more complete
          language, to the extent permitted by law, we (and our group companies,
          suppliers, partners, and agents) will not be liable for any indirect,
          incidental, punitive, or consequential damages (including loss of
          data, revenue, profits, or business opportunities, or personal injury
          or death), whether arising in contract, warranty, tort, product
          liability, or otherwise, and even if we’ve been advised of the
          possibility of damages in advance. Our liability (and the liability of
          each of our group companies, suppliers, partners, and agents) to you
          or any third parties under any circumstance is limited to the greater
          of $100 USD or the amount you have paid us in the 12 months before the
          event giving rise to your claims. Some jurisdictions don’t allow the
          exclusion or limitation of liability for consequential or incidental
          damages, so some of the above may not apply to you.
        </p>
        <strong>9.4 Indemnification</strong>
        <p>
          If you behave in a way that gets us in legal trouble, we may exercise
          legal recourse against you. You agree to indemnify, defend (if we so
          request), and hold harmless Deonicode, our group companies, and their
          officers, directors, suppliers, partners, and agents from an against
          any third-party claims, demands, losses, damages, or expenses
          (including reasonable attorney fees) arising from: (a) the content you
          post or submit; (b) your use of the Services; (c) your violation of
          these Terms; or (d) your violation of any rights of a third party.
          Your indemnification obligation will survive the termination of these
          Terms and your use of the Services.
        </p>
        <strong>9.5 Governing Law and Jurisdiction</strong>
        <p>
          When these Terms mention “Deonicode,” they’re referring to the
          Deonicode entity that you’re contracting with. If you’re a student,
          your contracting entity and governing law will generally be determined
          based on your location.
        </p>
        <p>
          Except in some cases as listed below, if you’re a student located in
          India, you’re contracting with Deonicode India LLP and these Terms are
          governed by the laws of India, without reference to its choice or
          conflicts of law principles, and you consent to the exclusive
          jurisdiction and venue of the courts in Delhi, India.
        </p>
        <p>
          If you’re a student located in a geographical region other than India,
          if you’re accessing our Services through one of our mobile
          applications (such as Deonicode’s Android or iOS application) and
          payment is processed through a mobile platform provider’s payment
          system, or if you’re accessing our Services as an instructor, you’re
          contracting with Deonicode, Inc. and these Terms are governed by the
          laws of the State of California, USA without reference to its choice
          or conflicts of law principles. In cases where the “Dispute
          Resolution” section below doesn’t apply to you, you consent to the
          exclusive jurisdiction and venue of federal and state courts in San
          Francisco, California, USA.
        </p>
        <strong>9.6 Legal Actions and Notices</strong>
        <p>
          No action, regardless of form, arising out of or relating to this
          Agreement may be brought by either party more than one year after the
          cause of action has accrued, except where this limitation cannot be
          imposed by law.
        </p>
        <p>
          Any notice or other communication to be given hereunder will be in
          writing and given by registered or certified mail return receipt
          requested, or email (by us to the email associated with your account
          or by you to notices@deonicode.com).
        </p>
        <strong>9.7 Relationship Between Us</strong>
        <p>
          You and we agree that no joint venture, partnership, employment,
          contractor, or agency relationship exists between us.
        </p>
        <strong>9.8 No Assignment</strong>
        <p>
          You may not assign or transfer these Terms (or the rights and licenses
          granted under them). For example, if you registered an account as an
          employee of a company, your account cannot be transferred to another
          employee. We may assign these Terms (or the rights and licenses
          granted under them) to another company or person without restriction.
          Nothing in these Terms confers any right, benefit, or remedy on any
          third-party person or entity. You agree that your account is
          non-transferable and that all rights to your account and other rights
          under these Terms terminate upon your death.
        </p>
        <strong>9.9 Sanctions and Export Laws</strong>
        <p>
          You warrant that you (as an individual or as a representative of any
          entity on whose behalf you use the Services) aren’t located in, or a
          resident of, any country that is subject to applicable U.S. trade
          sanctions or embargoes (such as Cuba, Iran, North Korea, Sudan, Syria,
          or the Crimea, Donetsk, or Luhansk regions). You also warrant that you
          aren’t a person or entity who is named on any U.S. government
          specially designated national or denied-party list.
        </p>
        <p>
          If you become subject to such a restriction during the term of any
          agreement with Deonicode, you will notify us within 24 hours, and we
          will have the right to terminate any further obligations to you,
          effective immediately and with no further liability to you (but
          without prejudice to your outstanding obligations to Deonicode).
        </p>
        <p>
          You may not access, use, export, re-export, divert, transfer or
          disclose any portion of the Services or any related technical
          information or materials, directly or indirectly, in violation of any
          United States and other applicable country export control and trade
          sanctions laws, rules and regulations. You agree not to upload any
          content or technology (including information on encryption) whose
          export is specifically controlled under such laws.
        </p>
        <h5>10. Dispute Resolution</h5>
        <p>
          If there’s a dispute, our Support Team is happy to help resolve the
          issue. If that doesn’t work and you live in the United States or
          Canada, your options are to go to small claims court or bring a claim
          in binding individual arbitration; you may not bring that claim in
          another court or participate in a non-individual class action claim
          against us. This Dispute Resolution section ("Dispute Resolution
          Agreement") applies only if you live in the United States or Canada.
          Most disputes can be resolved, so before bringing a formal legal case,
          please first try contacting our Support Team.
        </p>
        <strong>10.1 Dispute Resolution Overview</strong>
        <p>
          Deonicode is committed to using its best efforts to resolve disputes
          with its users, without the need for a formal legal claim to be filed.
          If an issue arises between us, you and Deonicode agree to first work
          diligently and in good faith to reach a resolution that’s fair and
          equitable to both sides using the mandatory informal dispute
          resolution process described below. On occasion, a third party may be
          necessary to help resolve our dispute. This Dispute Resolution
          Agreement limits how these disputes can be resolved.
        </p>
        <p>
          <strong>
            YOU AND DEONICODE AGREE THAT ANY AND ALL DISPUTES, CLAIMS, OR
            CONTROVERSIES ARISING OUT OF OR RELATING TO THESE TERMS OR THE
            APPLICABILITY, BREACH, TERMINATION, VALIDITY, ENFORCEMENT, OR
            INTERPRETATION OF THEM, OR TO THE USE OF THE SERVICES OR
            COMMUNICATIONS WITH DEONICODE (COLLECTIVELY, “DISPUTES”) THAT ARE
            NOT RESOLVED INFORMALLY MUST BE ADDRESSED SOLELY IN SMALL CLAIMS
            COURT OR BY BINDING INDIVIDUAL ARBITRATION AND AGREE TO WAIVE THE
            RIGHT TO A JURY TRIAL AND TO FILE A CASE IN ANY OTHER COURT.
          </strong>
        </p>
        <p>
          <strong>
            YOU AND DEONICODE FURTHER AGREE TO BRING CLAIMS AGAINST EACH OTHER
            IN AN INDIVIDUAL CAPACITY ONLY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY CLASS OR REPRESENTATIVE PROCEEDING WHETHER IN COURT OR
            IN ARBITRATION.
          </strong>
        </p>
        <p>
          You and Deonicode agree that this Dispute Resolution Agreement applies
          to each of us as well as all of our respective agents, attorneys,
          contractors, subcontractors, service providers, employees, and all
          others acting for, or on behalf of, you and Deonicode. This Dispute
          Resolution Agreement is binding on your and Deonicode’s respective
          heirs, successors, and assigns, and is governed by the Federal
          Arbitration Act.
        </p>
        <strong>10.2 Mandatory Informal Dispute Resolution Process</strong>
        <p>
          Before filing a claim against each other, you and Deonicode must first
          participate in the informal dispute resolution process described in
          this section.
        </p>
        <ul>
          <li>
            The claiming party shall send to the other a short, written
            statement (“Claim Statement”) with their full name, mailing address,
            and email address explaining: (a) the nature and details of the
            Dispute; and (b) a proposal for resolving it (including any money
            being claimed and how that amount was calculated). Sending a Claim
            Statement tolls the running of any applicable statute of limitations
            for a 60-day period beginning on the date the Claim Statement is
            received. You should send your Claim Statement to Deonicode by email
            to notices@deonicode.com, and you can also send it by certified mail
            to Deonicode Attn: Legal, 600 Harrison Street, 3rd Floor, San
            Francisco, CA 94107. Deonicode will send Claim Statements and
            respond to you at the email address associated with your Deonicode
            account, unless you request otherwise.
          </li>
          <li>
            When either of us receives a Claim Statement, the parties will
            attempt in good faith to resolve it informally. If we’re unable to
            resolve it within 60 days from receipt, then we each have the right
            to initiate a formal claim against the other in small claims court
            or individual arbitration, subject to the terms of this Dispute
            Resolution Agreement.
          </li>
        </ul>
        <p>
          Failure to complete this process is a material breach of the Terms,
          and no court or arbitrator shall have jurisdiction to hear or resolve
          any Disputes between you and Deonicode.
        </p>
        <strong>10.3 Small Claims</strong>
        <p>
          Disputes raised but not resolved through the mandatory informal
          dispute resolution process can be brought in small claims court in:
          (a) San Francisco, California; (b) the county where you live; or (c)
          another place we both agree on. We each waive the right to bring any
          Disputes between us, in courts other than small claims court,
          including courts of general or special jurisdiction.
        </p>
        <strong>10.4 Arbitration</strong>
        <p>
          As the sole alternative to small claims court, you and Deonicode have
          the right to resolve Disputes through individual arbitration. While
          there’s no judge or jury in an arbitration, the arbitrator has the
          power to award the same individual relief and must follow our
          agreement in the same way as a court. If one of us brings a Dispute to
          a court other than a small claims court, the other party can ask a
          court to require us both to go to arbitration. Either of us can also
          ask a court to halt a court proceeding while an arbitration proceeding
          is ongoing. To the extent that any cause of action or claim for relief
          can’t be addressed in arbitration, you and Deonicode agree that all
          court proceedings shall be paused pending the resolution in
          arbitration of all arbitrable causes of action and claims for relief.
          Nothing in this Dispute Resolution Agreement is intended to limit the
          individual relief available to either of us in arbitration or small
          claims court.
        </p>
        <p>
          If you and Deonicode disagree on whether a Dispute must be arbitrated,
          the scope of the arbitrator’s powers, or the enforceability of any
          aspect of this Dispute Resolution Agreement, the arbitrator alone
          shall have, to the fullest extent permitted by law, the sole authority
          to address all such disagreements, including but not limited to those
          concerning or related to the formation, legality, interpretation, and
          enforceability of this Dispute Resolution Agreement. This provision
          doesn’t limit the procedure for challenging an improperly commenced
          arbitration.
        </p>
        <p>
          Any court of competent jurisdiction will have the authority to enforce
          the requirements of this Dispute Resolution Agreement and, if
          necessary, enjoin the filing or prosecution of any arbitrations and
          the assessment of fees for any arbitration or mediation not conducted
          under this Dispute Resolution Agreement.
        </p>
        <p>
          If the American Arbitration Association (“AAA”) or any other
          arbitration organization or arbitrator is, for any reason, unable to
          administer any arbitration required under this Dispute Resolution
          Agreement, you and Deonicode shall negotiate in good faith on the
          substitution of another organization or individual to handle the
          arbitration. If we can’t agree on an alternative, you or Deonicode may
          petition a court of competent jurisdiction to appoint an organization
          or individual to conduct the arbitration in a manner consistent with
          this Dispute Resolution Agreement for a cost comparable to that of the
          designated arbitration organization.
        </p>
        <strong>10.5 General Arbitration Rules</strong>
        <p>
          The arbitration process will differ depending on whether your claim is
          pursued individually or as part of a Mass Arbitration (defined below).
          The general arbitration rules outlined in this section (“General
          Arbitration Rules”) shall control, except in the case of a Mass
          Arbitration.
        </p>
        <p>
          All arbitrations shall be before a single arbitrator. Except as
          otherwise provided in this Dispute Resolution Agreement, a party
          electing arbitration must initiate proceedings by filing an
          arbitration demand with the AAA. Arbitrations involving consumers will
          be governed by these Terms and the AAA Consumer Arbitration Rules and
          the AAA Consumer Due Process Protocol. Arbitrations involving all
          others, including instructors, will be governed by these Terms and the
          AAA Commercial Arbitration Rules and the AAA Optional Appellate Rules.
          If there’s a conflict between these Terms and any applicable AAA rules
          and protocols, these Terms will control.
        </p>
        <p>
          Disputes that involve a claim of less than $15,000 USD in actual or
          statutory damages (but not including attorneys’ fees and incidental,
          consequential, punitive, and exemplary damages and any damage
          multipliers) must be resolved exclusively through binding,
          non-appearance-based individual arbitration based solely on the
          written submissions of the parties. All other arbitrations shall be
          conducted by phone, video conference, or based only on written
          submissions. Judgment on an arbitrator’s award may be entered in any
          court that has jurisdiction to do so.
        </p>
        <p>
          To begin an arbitration proceeding with the AAA, the claiming party
          must send a letter describing the Dispute and requesting arbitration
          to the American Arbitration Association Case Filing Services, 1101
          Laurel Oak Road, Suite 100, Voorhees, NJ 08043 or by filing a request
          online through the AAA website.
        </p>
        <strong>10.6 Mass Arbitration Rules</strong>
        <p>
          If 25 or more claimants (each a “Mass Arbitration claimant”) or their
          lawyers file or disclose an intention to file demands for arbitration
          against Deonicode raising substantially identical Disputes, and
          counsel for the claimants are the same or coordinated across the
          Disputes (a “Mass Arbitration”), these special rules shall apply.
        </p>
        <p>
          Each Mass Arbitration claimant must complete the informal dispute
          resolution process described in this Dispute Resolution Agreement.
          Counsel for claimants shall file a single Claim Statement for all Mass
          Arbitration claimants that identifies all Mass Arbitration claimants
          by full name, mailing address, and email address. The Mass Arbitration
          claimants must then follow a “bellwether procedure” described below in
          which a group of up to 10 claimants proceed to arbitration (each a
          “bellwether arbitration”), followed by a mandatory mediation process
          through which the Disputes of Mass Arbitration claimants may be
          resolved. Any statutes of limitation applicable to Disputes of Mass
          Arbitration claimants shall be tolled from the submission of their
          Claim Statement until the mandatory mediation process has been
          completed.
        </p>
        <p>
          Counsel for the Mass Arbitration claimants and Deonicode’s counsel
          shall each select up to five claimants for bellwether arbitrations
          (not more than 10 in total) to be each promptly decided individually
          as a bellwether arbitration conducted under the General Arbitration
          Rules, with each case assigned to a separate arbitrator. If any other
          Mass Arbitration claimants have filed claims in arbitration, they
          shall promptly be dismissed without prejudice before the bellwether
          arbitrations may proceed. Each bellwether arbitration shall be
          completed within 120 days. No other demands for arbitration by Mass
          Arbitration claimants may be initiated during the pendency of the
          bellwether arbitrations and the mandatory mediation process which
          follows.
        </p>
        <p>
          On the resolution of the 10 bellwether cases, Deonicode’s counsel and
          counsel for the Mass Arbitration claimants shall participate promptly
          and in good faith in non-binding confidential mediation for a period
          of at least 60 days in a good faith effort to resolve all Disputes of
          the Mass Arbitration claimants. This mediation shall be conducted by
          the AAA under its then-current Mediation Procedures of the AAA, unless
          Deonicode and the Mass Arbitration claimants mutually agree to another
          mediator and/or mediation procedure.
        </p>
        <p>
          If the bellwether arbitrations and subsequent mediation are
          unsuccessful in resolving the Disputes of all Mass Arbitration
          claimants, then those Mass Arbitration claimants whose Disputes
          haven’t been resolved may only pursue those Disputes on an individual
          basis in small claims court or with FairClaims, Inc. (“FairClaims”),
          and not the AAA or any other arbitral organization or arbitrator,
          under FairClaims’ Small Claims Rules & Procedures. To the extent that
          any cause of action or claim for relief can’t be addressed by
          FairClaims under its Small Claims Rules & Procedures, you and
          Deonicode agree that any court proceedings involving Mass Arbitration
          claimants and Deonicode shall be paused pending the final resolution
          in arbitration with FairClaims of all arbitrable causes of action and
          claims for relief.
        </p>
        <p>
          If the Mass Arbitration Rules are determined to be unenforceable for
          any reason in a decision of any arbitrator or court as to which
          further review is foreclosed and all motions, appeals, and petitions
          for review have been resolved fully (a “Final Determination”), then
          you and Deonicode agree that all unresolved Disputes between Mass
          Arbitration claimants and Deonicode must be filed in and resolved by a
          court of competent jurisdiction only (including on a class action
          basis if the Dispute qualifies), and shall not be filed in, pursued
          further, or resolved through arbitration or otherwise be subject to
          any contractual obligation to arbitrate. To the extent that any
          arbitrations filed by or on behalf of Mass Arbitration claimants are
          still pending after a Final Determination, those claimants shall
          immediately dismiss such arbitrations without prejudice. A finding
          that these Mass Arbitration Rules are unenforceable for any reason,
          including any Final Determination, shall have no effect on the
          validity or enforceability of any other provisions of these Terms,
          including those set forth in this Dispute Resolution Agreement.
        </p>
        <strong>10.7 Fees and Costs</strong>
        <p>
          You and Deonicode agree that each party will bear its own costs and
          attorneys’ fees in the event of a dispute, provided, however, that
          either party may recover fees and costs to the extent permitted by
          applicable law. If a court or an arbitrator determines that an
          arbitration has been brought or threatened in bad faith, or that the
          demand was frivolous or asserted for an improper purpose, the court or
          arbitrator may, to the fullest extent permitted by law, award
          attorneys’ fees to the party defending against the claim just as a
          court could.
        </p>
        <strong>10.8 No Class Actions</strong>
        <p>
          Except as expressly provided for in connection with the Mass
          Arbitration Rules, we both agree that we can each only bring claims
          against the other on an individual basis. This means: (a) neither of
          us can bring a claim as a plaintiff or class member in a class action,
          consolidated action, or representative action; (b) an arbitrator can’t
          combine multiple people’s claims into a single case (or preside over
          any consolidated, class, or representative action); and (c) an
          arbitrator’s decision or award in one claimant’s case can only decide
          the disputes of that user, not other users. Nothing in this Dispute
          Resolution Agreement limits the parties’ rights to resolve a Dispute
          by mutual agreement through a class-wide settlement of claims.
        </p>
        <strong>10.9 Changes</strong>
        <p>
          Notwithstanding the “Updating these Terms” section below, if Deonicode
          changes this "Dispute Resolution" section after the date you last
          indicated acceptance to these Terms, you may reject any such change by
          providing Deonicode written notice of such rejection by mail or hand
          delivery to Deonicode Attn: Legal, 600 Harrison Street, 3rd Floor, San
          Francisco, CA 94107, or by email from the email address associated
          with your Deonicode account to notices@deonicode.com, within 30 days
          of the date such change became effective, as indicated by the "last
          updated on" language above. To be effective, the notice must include
          your full name and clearly indicate your intent to reject changes to
          this "Dispute Resolution" section. By rejecting changes, you are
          agreeing that you will arbitrate any dispute between you and Deonicode
          in accordance with the provisions of this "Dispute Resolution" section
          as of the date you last indicated acceptance to these Terms.
        </p>
        <strong>10.10 Improperly Commenced Arbitration</strong>
        <p>
          If either party believes that the other has initiated an arbitration
          in violation of this Dispute Resolution Agreement, if such an
          arbitration is threatened, or if either party has reason to believe an
          improperly commenced arbitration is imminent, the party against whom
          the arbitration has been or will be initiated may seek an order from a
          court of competent jurisdiction enjoining the arbitration from being
          filed or continued, and awarding its fees and costs, including
          reasonable attorneys’ fees, incurred in connection with seeking the
          order.
        </p>
        <h5>11. Updating These Terms</h5>
        <p>
          From time to time, we may update these Terms to clarify our practices
          or to reflect new or different practices (such as when we add new
          features), and Deonicode reserves the right in its sole discretion to
          modify and/or make changes to these Terms at any time. If we make any
          material change, we will notify you using prominent means, such as by
          email notice sent to the email address specified in your account or by
          posting a notice through our Services. Modifications will become
          effective on the day they are posted unless stated otherwise.
        </p>
        <p>
          Your continued use of our Services after changes become effective
          shall mean that you accept those changes. Any revised Terms shall
          supersede all previous Terms.
        </p>
        <h5>12. How to Contact Us</h5>
        <p>
          The best way to get in touch with us is to contact our Support Team.
          We’d love to hear your questions, concerns, and feedback about our
          Services.
        </p>
        <p>Thanks for teaching and learning with us!</p>
      </div>
      <FooterTwo />
    </Fragment>
  );
};

export default PrivacyPlocy;
